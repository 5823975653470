import { useState } from "react";
import {useAdminSettingsStore, usePersistedDataStore} from './store';
import MuniAnalysis from "./MuniAnalysis";

function CountyAnalysis({isAdmin, selectedCounty, nyDataObj, analysisObj, microLoad, setSelectedCounty, }){
    const [showZipCodes, setShowZipCodes] = useState(false);
    const [detailedView, setDetailedView] = useState(false)
    const getTaxYear = useAdminSettingsStore((state) => state.taxYear)
    const getMunicipality = usePersistedDataStore((state) => state.municipality)

    if (!analysisObj) {
      return (microLoad?
        <div className="p-4 items-center content-center m-auto">
          <div>
          <div className="flex items-center content-center justify-center z-[9999]">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
          </div>
        </div>
        </div>
        :
        null)
      // return null; // Return null if analysisObj is null
    }
    return(
        <div className=" ">
      <div className="w-full shadow-lg items-center content-center bg-white dark:bg-boxdark">
  {selectedCounty !== null ? (
      <div className="flex border-b items-center justify-between">

        {/* Set width of div to center other element. */}
        <div className="w-[150px]">
        <button onClick={() => setSelectedCounty(null)} className=" sticky ml-1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-[#FF4141]">
            <path strokeLinecap="round" strokeLinejoin="round" fill="currentColor" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
          </svg>
        </button>
        </div>

        <div><span className="font-bold">{selectedCounty}</span> Municipal Summary</div>
        <div className="flex items-center rounded-md p-1.5 dark:bg-meta-4">
          {/* ADD ONCLICKS TO TOGGLE BETWEEN RESI / COMMERCIAL */}
          <button onClick={()=>setDetailedView(false)} 
          className={`px-3 ${detailedView ? 'rounded py-1 text-xs font-medium text-black hover:bg-[#3b82f6] hover:text-white hover:shadow-card dark:text-white dark:hover:bg-boxdark' : 'rounded bg-primary py-1 text-xs font-medium text-white shadow-card hover:bg-[#3b82f6] hover:shadow-card dark:bg-boxdark dark:text-white dark:hover:bg-boxdark-bold'}`}
          >
            Simple
          </button>
          <button onClick={()=>setDetailedView(true)} 
          className={` px-3 ${detailedView ? 'rounded bg-primary py-1 text-xs font-medium text-white shadow-card hover:bg-[#3b82f6] hover:shadow-card dark:bg-boxdark dark:text-white dark:hover:bg-boxdark-bold' : 'rounded py-1 text-xs font-medium text-black hover:bg-[#3b82f6] hover:text-white hover:shadow-card dark:text-white dark:hover:bg-boxdark'}`}
          >
            Detailed
          </button>
        </div>
      </div>

    ) 
    : // Below this is if you are NOT in specific county view.
    <div className="flex m-auto border-b items-center content-center justify-center text-center">County Summary</div>
  }

    {/* Loading widget if fetch not done. */}
    {/* {microLoad?
      <div className="p-4 items-center content-center m-auto">
        <div>
        <div className="flex items-center content-center justify-center z-[9999]">
          <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        </div>
      </div>
      </div>
      :
      null} */}

{analysisObj && !selectedCounty && !microLoad ? (
  // COUNTY DATA
    <div className="justify-center items-center">
        {/* Display TOTAL values at the top */}
        <div className="flex p-2 border mb-4 gap-2 justify-center items-center">
            {isAdmin ? (
                <div className="flex gap-0.5 items-center">
                    <div className="font-bold">Total Amount:</div>
                    <div className="text-center">
                        <div>{analysisObj.county.data.total.total_amount_invoicing.toLocaleString()}</div>
                        {getTaxYear !== '2021' && (
                            <div className={analysisObj.county.yoy_changes.total.total_amount_invoicing >= 0 ? 'text-success text-xs' : 'text-danger text-xs'}>
                                {analysisObj.county.yoy_changes.total.total_amount_invoicing >= 0 ? '+' : '('}{(analysisObj.county.yoy_changes.total.total_amount_invoicing).toLocaleString()}{analysisObj.county.yoy_changes.total.total_amount_invoicing >= 0 ? '' : ')'}
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
              
              <div className="flex gap-0.5 items-center">
              <div className="font-bold">Client Count:</div>
                <div className=" text-center">
                  <div>{analysisObj.county.data.total.retainers_count_total.toLocaleString()}</div>
                {getTaxYear !== '2021' && (
                  <div className={analysisObj.county.yoy_changes.total.retainers_count_total >= 0 ? 'text-xs text-success' : 'text-xs text-danger'}>
                    {analysisObj.county.yoy_changes.total.retainers_count_total >= 0 ? '+' : '('}{(analysisObj.county.yoy_changes.total.retainers_count_total).toLocaleString()}{analysisObj.county.yoy_changes.total.retainers_count_total >= 0 ? '' : ')'}
                  </div>
                )}
                </div>
              </div>

              <div className="flex gap-0.5 items-center ">
              <div className="font-bold">Invoice Count:</div>
                <div className=" text-center">
                  <div>{analysisObj.county.data.total.invoice_count_invoicing.toLocaleString()}</div>
                  {getTaxYear !== '2021' && (
                    <div className={analysisObj.county.yoy_changes.total.invoice_count_invoicing >= 0 ? 'text-xs text-success' : 'text-xs text-danger'}>
                      {analysisObj.county.yoy_changes.total.invoice_count_invoicing >= 0 ? '+' : '('}{(analysisObj.county.yoy_changes.total.invoice_count_invoicing).toLocaleString()}{analysisObj.county.yoy_changes.total.invoice_count_invoicing >= 0 ? '' : ')'}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-0.5 items-center"> {/* Added 'items-center' class for vertical centering */}
              <div className="font-bold">Win Rate:</div>
              <div className=" text-center">
              <div>{(analysisObj.county.data.total.invoice_count_invoicing/analysisObj.county.data.total.retainers_count_total).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</div>
              {getTaxYear !== '2021' && (                
                <div className={(analysisObj.county.data.total.invoice_count_invoicing - analysisObj.county.yoy_changes.total.invoice_count_invoicing) / (analysisObj.county.data.total.retainers_count_total - analysisObj.county.yoy_changes.total.retainers_count_total) < (analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total) ? 'text-xs text-success' : 'text-xs text-danger'}>
                  {/* calculate prior year's values (subtracting the yoy change), then get prior year win rate. */}
                  {((analysisObj.county.data.total.invoice_count_invoicing - analysisObj.county.yoy_changes.total.invoice_count_invoicing) / (analysisObj.county.data.total.retainers_count_total - analysisObj.county.yoy_changes.total.retainers_count_total) < (analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total)) ? '+' : '('}
                  {((analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total) - ((analysisObj.county.data.total.invoice_count_invoicing - analysisObj.county.yoy_changes.total.invoice_count_invoicing) / (analysisObj.county.data.total.retainers_count_total - analysisObj.county.yoy_changes.total.retainers_count_total))).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
                  {((analysisObj.county.data.total.invoice_count_invoicing - analysisObj.county.yoy_changes.total.invoice_count_invoicing) / (analysisObj.county.data.total.retainers_count_total - analysisObj.county.yoy_changes.total.retainers_count_total) < (analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total)) ? '' : ')'}
                </div>
                )}
              </div>
              </div> 

              {isAdmin? // hide if non-admin view
              <div className="flex gap-2 items-center"> {/* Added 'items-center' class for vertical centering */}
              <div className="font-bold">Avg Fee:</div>
              <div className=" text-center">
                <div>{Math.round(analysisObj.county.data.total.avg_fee_total_invoicing, 0).toLocaleString()}</div>
                {getTaxYear !== '2021' && (
                    <div className={analysisObj.county.yoy_changes.total.avg_fee_total_invoicing >= 0 ? 'text-xs text-success' : 'text-xs text-danger'}>
                      {analysisObj.county.yoy_changes.total.avg_fee_total_invoicing >= 0 ? '+' : '('}{Math.round(analysisObj.county.yoy_changes.total.avg_fee_total_invoicing,0).toLocaleString()}{analysisObj.county.yoy_changes.total.avg_fee_total_invoicing >= 0 ? '' : ')'}
                    </div>
                )}
                </div>
              </div>
              :null}

            {isAdmin? // hide if non-admin view
              <div className="flex gap-2 items-center"> {/* Added 'items-center' class for vertical centering */}
              <div className="font-bold">REV/CLIENT:</div>
              <div className="text-center">
                  {/* if last year is less than this year (ie: diff is less than zero, green text (increase)) */}
                <div>{Math.round(analysisObj.county.data.total.avg_fee_total_invoicing * (analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total)).toLocaleString()}</div>
                {getTaxYear !== '2021' && (
                <div
                  className={(( // conditional styling for text color:
                  // LAST YEAR's AVG REV PER CLIENT:
                  analysisObj.county.data.total.avg_fee_total_invoicing - analysisObj.county.yoy_changes.total.avg_fee_total_invoicing) * ((analysisObj.county.data.total.invoice_count_invoicing-analysisObj.county.yoy_changes.total.invoice_count_invoicing) / (analysisObj.county.data.total.retainers_count_total-analysisObj.county.yoy_changes.total.retainers_count_total)) 
                  - 
                  // THIS YEAR's avg rev / client:
                  analysisObj.county.data.total.avg_fee_total_invoicing * (analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total))
                  < 0 ? 'text-xs text-success flex' : 'text-xs text-danger flex'}>

                    {((analysisObj.county.data.total.avg_fee_total_invoicing - analysisObj.county.yoy_changes.total.avg_fee_total_invoicing) * ((analysisObj.county.data.total.invoice_count_invoicing-analysisObj.county.yoy_changes.total.invoice_count_invoicing) / (analysisObj.county.data.total.retainers_count_total-analysisObj.county.yoy_changes.total.retainers_count_total)) < analysisObj.county.data.total.avg_fee_total_invoicing * (analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total)) ? '+' : '('}
                  <div>{Math.round(analysisObj.county.data.total.avg_fee_total_invoicing * (analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total) - (analysisObj.county.data.total.avg_fee_total_invoicing - analysisObj.county.yoy_changes.total.avg_fee_total_invoicing) * ((analysisObj.county.data.total.invoice_count_invoicing-analysisObj.county.yoy_changes.total.invoice_count_invoicing) / (analysisObj.county.data.total.retainers_count_total-analysisObj.county.yoy_changes.total.retainers_count_total))).toLocaleString()}
                  {((analysisObj.county.data.total.avg_fee_total_invoicing - analysisObj.county.yoy_changes.total.avg_fee_total_invoicing) * ((analysisObj.county.data.total.invoice_count_invoicing-analysisObj.county.yoy_changes.total.invoice_count_invoicing) / (analysisObj.county.data.total.retainers_count_total-analysisObj.county.yoy_changes.total.retainers_count_total)) < analysisObj.county.data.total.avg_fee_total_invoicing * (analysisObj.county.data.total.invoice_count_invoicing / analysisObj.county.data.total.retainers_count_total)) ? '' : ')'}
                  </div>
                </div>
                )}
              </div>
              </div>
              :null}

            </div>

            {/* County level data in its own grid */}
            {/* NEED TO DO THE MATH IN ORDER TO  */}
            <div className="grid grid-cols-2 lg:grid-cols-3 pb-4 gap-4">
              {Object.entries(analysisObj.county.data).map(([key, val]) => {
                // console.log(analysisObj)
                if (key === 'county_data') { // render null if its not county data (the else at the enf o thi block)
                  const sortedCountyData = val.slice().sort((a, b) => (b.avg_fee*(b.invoice_count/b.retainers_count) - a.avg_fee*(a.invoice_count/a.retainers_count)));
                  return (
                  sortedCountyData.map((countyData,index) => {
                    let countyName;
                    const countyVal = countyData.county
                    for (const key of Object.keys(nyDataObj)) {
                      const objCountyVal = nyDataObj[key].selectVal;
                      if(objCountyVal === countyVal){
                        countyName=key
                      }
                    }
                    return(
                    <button onClick={() => {console.log(countyData);setSelectedCounty(countyName)}} key={countyData.county} className="border m-auto text-center">
                      <div className="flex items-center justify-center min-w-[325px]">
                        <div className="flex">
                          {/* <div className="font-bold text-lg">County: </div> */}
                          <div onClick={() => {console.log(countyData);setSelectedCounty(countyName)}} className="text-left font-bold text-lg">{countyName}</div>
                        </div>
                      </div>
                      <div key={countyName} className="mb-1 mx-4 grid grid-cols-1 m-auto">
                        {/* Make the top level clickable so that it brings you INTO that county with munis */}
                        
                       

                      {isAdmin ?
                        <div className="grid grid-cols-2 flex">
                          <div className="text-left">Total Amount:</div>
                          <div className="flex grid grid-cols-2 text-left ml-2">
                            {countyData.total_amount.toLocaleString()}
                            <div className={`text-xs flex items-center ${analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.total_amount >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                            {analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.total_amount >= 0 ? '+'+analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.total_amount.toLocaleString():
                            '('+analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.total_amount.toLocaleString()+')'}
                            </div>
                          </div>
                        </div>
                        :
                        null}

                        <div className="flex grid grid-cols-2">
                        <div className="text-left">Client Count:</div>
                        <div className="flex grid grid-cols-2 text-left ml-2">
                        {countyData.retainers_count.toLocaleString()}
                        <div className={`text-xs flex items-center ${analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.retainers_count >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                            {analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.retainers_count >= 0 ? '+'+analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.retainers_count.toLocaleString():
                            '('+analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.retainers_count.toLocaleString()+')'}
                          </div>
                        </div>
                        </div>

                        <div className="flex grid grid-cols-2">
                          <div className="text-left">Invoiced Count:</div>
                          <div className="flex grid grid-cols-2 text-left ml-2">
                              {countyData.invoice_count.toLocaleString()}
                              <div className={`text-xs flex items-center ${analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.invoice_count >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                              {analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.invoice_count >= 0 ? '+'+analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.invoice_count.toLocaleString():
                              '('+analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.invoice_count.toLocaleString()+')'}
                              </div>
                          </div>
                        </div>

                        {isAdmin?
                        <div className="flex grid grid-cols-2">
                        <div className="text-left">Avg Invoice:</div>
                        <div className="flex grid grid-cols-2 text-left ml-2">
                          {Math.round(countyData.avg_fee).toLocaleString()}
                              <div className={`text-xs flex items-center ${analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.avg_fee >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                              {analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.avg_fee >= 0 ? '+'+Math.round(analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.avg_fee.toLocaleString()):
                              '('+Math.round(analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.avg_fee.toLocaleString())+')'}
                              </div>
                        </div>
                        </div>
                        :
                        null}

                        <div className="flex grid grid-cols-2">
                        <div className="text-left">Win Rate:</div>

                        <div className="flex grid grid-cols-2 text-left ml-2">
                          {(countyData.win_rate).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
                              <div className={`text-xs flex items-center ${analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.win_rate >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                              {analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.win_rate >= 0 ? '+'+analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.win_rate.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 }):
                              '('+analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.win_rate.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })+')'}
                              </div>
                        </div>
                        </div>

                        {isAdmin?
                        <div className="flex grid grid-cols-2">
                        <div className="font-bold text-left">REV/CLIENT:</div>
                        <div className="flex grid grid-cols-2 text-left ml-2">
                          {Math.round(countyData.revenue_per_client).toLocaleString()}
                              <div className={`text-xs flex items-center ${analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.revenue_per_client >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                              {analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.revenue_per_client >= 0 ? '+'+Math.round(analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.revenue_per_client.toLocaleString()):
                              '('+Math.round(analysisObj.county.yoy_changes.county_data.find(item => item.county === countyData.county)?.revenue_per_client.toLocaleString())+')'}
                              </div>
                        </div>
                        </div>
                        :
                        null}
                      </div>
                    </button>
                    )
                })
                  );
                } else {
                  return null; // Skip rendering total values again
                }
              })}
            </div>
          </div>
        ) : 
        analysisObj && !microLoad ? // Municipal data
        // MUNICIPAL DATA:
        <MuniAnalysis analysisObj={analysisObj}
        nyDataObj={nyDataObj}
        selectedCounty={selectedCounty}
        detailedView={detailedView}
        isAdmin={isAdmin}
        showZipCodes={showZipCodes}
        setShowZipCodes={setShowZipCodes}
        ></MuniAnalysis>
        :
        null
        }
      </div>
    </div>
    )
}export default CountyAnalysis