import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect } from 'react';
import { useCompStore, useLocalDataStore, usePersistedDataStore } from './store';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
  
function Map({}) {
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  // const comp = usePersistedDataStore((state) => state.caseNumber);
  const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
  const getCourtComps = usePersistedDataStore((state) => state.courtComps);
  const getOptimizedComps = usePersistedDataStore((state)=>state.optimizedComps)
  const location = useLocation();
  
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1 
  const view = queryParams.get('view') || 'regular';
  let numberOfPins = 0;
  
  // console.log(getCourtComps)

  if(view === 'regular'){ // set your pins to be the min so no errors if less than 10 comps.
    numberOfPins = Math.min(getCompSheet[comp].parcel_id.length,10);
  }else if (view === 'optimized'){
    numberOfPins = Math.min(getOptimizedComps[comp].parcel_id.length,10);
  }else if (view === 'court'){
    numberOfPins = Math.min(getCourtComps[comp].parcel_id.length,10);
  }


  useEffect(() => {
    if (!getCompSheet || !getCompSheet[comp] ) {
      // Ensure that the data is available
      return;
    }

    if(view==='optimized'){
    if (!getOptimizedComps || !getOptimizedComps[comp] || getOptimizedComps[comp].length === 0) {
      // Handle the case where optimized comps are empty
      // You can display a grey box or handle it in your preferred way
      // For now, I'm just logging an error to the console
      console.error('Optimized comps list is empty.');
      return;
    }
  }
  // if (view === 'court') {
  //   // console.log('ur in court view')
  //   if (!getCourtComps || !getCourtComps[comp] || getCourtComps.length === 0) {
  //     console.error('Additional comps list is empty.');
  //     return;
  //   }else{ // set # of pins equal to # of comps you have if in this view.
  //   numberOfPins=getCourtComps[comp]['Latitude'].length
  //   }
  // }

    if (map) {
      map.remove();
    }
    // Create the map
    var map = L.map('map', {
      attributionControl: false,
    });

    // Create an array to store marker coordinates
    const markerCoordinates = [];

    // Add a tile layer to the map
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 19,
    }).addTo(map);

    for (let i = 0; i < numberOfPins; i++) {
        let lat
        let lng

        // update map for new views
        if (view==='optimized' && Object.keys(getOptimizedComps[comp]).length !== 0) {
          // Handle the case where optimized comps are empty
          
          if (!getOptimizedComps[comp] || Object.keys(getOptimizedComps[comp]).length === 0 || !getOptimizedComps[comp].Latitude[i] || !getOptimizedComps[comp].Longitude[i]) {
            // Handle the case where Latitude[i] or Longitude[i] is undefined or getOptimizedComps[comp] is an empty object
            // You can display a grey box or handle it in your preferred way
            // For now, I'm just logging an error to the console
            console.error(`Missing latitude and/or longitude data for optimized comp ${i + 1}.`);
            continue; // Skip to the next iteration
          }
          lat = getOptimizedComps[comp].Latitude[i];
          // console.log(getOptimizedComps[comp])
          lng = getOptimizedComps[comp].Longitude[i];
          // console.log(`Rendering optimized view lat/long for index ${i}: ${lat}, ${lng}`);
        } else if(view==='regular'){
          // Handle the case for regular view
          if (!getCompSheet[comp].Latitude[i] || !getCompSheet[comp].Longitude[i]) {
            // You can display a grey box or handle it in your preferred way
            // For now, I'm just logging an error to the console
            console.error(`Missing latitude and/or longitude data for regular comp ${i + 1}.`);
            continue; // Skip to the next iteration
          }
          lat = getCompSheet[comp].Latitude[i];
          lng = getCompSheet[comp].Longitude[i];
          // console.log(`Rendering regular view lat/long for index ${i}: ${lat}, ${lng}`);
        } else if (view === 'court') {
          if (getCourtComps.length === 0 || !getCourtComps[comp].Latitude[i] || !getCourtComps[comp].Longitude[i]) {
            console.error(`Missing latitude and/or longitude data for additional comp ${i + 1}.`);
            continue;
          }
          lat = getCourtComps[comp].Latitude[i];
          lng = getCourtComps[comp].Longitude[i];
        }
        // Create a container div for the marker
        const markerContainer = document.createElement('div');
        markerContainer.className = 'custom-marker-container';


        // Create the SVG icon element
        const svgIcon = document.createElement('div');
        svgIcon.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="#269AE6" viewBox="0 0 20 20" width="18" height="18" style="margin-left: -3px; margin-top: -4px;">
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
          </svg>
        `;
        svgIcon.className = 'custom-marker-icon';
        markerContainer.appendChild(svgIcon);

        // Create a div for the circle and number
        const circleNumberContainer = document.createElement('div');
        circleNumberContainer.className = 'circle-number-container';

        const numberElement = document.createElement('div');
        numberElement.className = 'custom-marker-number';
        if(i>0){
          numberElement.textContent = i; // Display 1-based index
        }
        markerContainer.appendChild(numberElement);

        const circleColor = i === 0 ? '#FFBA00' : '#F1F5F9';
        // Create the circle element -- DO YOU LIKEE IT WITH OUTLINE, or solid fill more here?
        const circle = L.circleMarker([lat, lng], {
          color: circleColor,
          fillColor: circleColor,
          fillOpacity: 0.8,
          radius: 12,
          zIndexOffset: i === 0 ? 999 : 0,
        })

            // Add the marker to the map
            L.marker([lat, lng], {
              icon: L.divIcon({
                className: 'custom-marker',
                html: markerContainer,
              }),
              // Set zIndexOffset based on the condition
              zIndexOffset: i === 0 ? 1000 : 0,
            }).addTo(map);

            circle.addTo(map);
        markerCoordinates.push([lat, lng]);
      }
    // Auto-zoom to display all markers
    if (markerCoordinates.length > 0) {
      const bounds = L.latLngBounds(markerCoordinates);
      map.fitBounds(bounds);
    }
    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [getCompSheet[comp], comp, getReviewPage, view, getOptimizedComps[comp]]);

  return (
    <div className='dark:bg-boxdark bg-white mt-3 mb-1'>
      <div id='map'></div>
    </div>
  );
}

export default Map;
