import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NewDropdown from './NewDropdown'
import { useLocalDataStore, usePersistedDataStore, } from './store';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import { updateMuniObj, getMunicipalityOptions, getVillageOptions, getCountyOptions } from '../common/helper_functions';
import axiosInstance from '../axiosConfig.js'

function GraphParams({nyDataObj, setLag, lag, microLoad, setMicroLoad, loadGraph}){
    // put yearDropdown
    const yearDropdownOptions = [ // hardcoding for rn w/ carmel. in the future this should probably be dynamic, and drivven by what data is available.
        {value: 2017, label: 2017},
        {value: 2018, label: 2018},
        {value: 2019, label: 2019},
        {value: 2020, label: 2020},
        {value: 2021, label: 2021},
        {value: 2022, label: 2022},
        {value: 2023, label: 2023},
        {value: 2024, label: 2024},
    ]
    // const [microLoad, setMicroLoad] = useState(false)
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const lagOptions = [1,2,3,4,5]
    const [villageOptions, setVillageOptions] = useState([{value:'All', label:'All'}])
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlString = location.pathname+location.search
    const [loading, setLoading] = useState(false);
    // const MuniCode = queryParams.get('MuniCode') || 'All';
    const [error, setError] = useState(false)
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const [muniOptions, setMuniOptions] = useState([{value:'All', label:'All'}]) // allow "all to be a default state."
    const [countyOptions, setCountyOptions] = useState([{ value: "All", label: "All", selectVal: 'All'},
        ...Object.keys(nyDataObj).map(key => ({
            label: key,
            value: nyDataObj[key].selectVal
        }))])
  
      useEffect(()=>{ // update the available counties when courtdate is updated.
        if (!courtDate || !county) {
          console.log('its setting default, could just return nothing here.')
          return
        }
      }, [courtDate]);
  
      const [courtMunis, setCourtMunis] = useState([]);
  
      useEffect(() => {
        // Update the available municipalities when the county is updated.
        if (county !== '' && county !== 'All' && county !== 'select') {
          getMunicipalityOptions({
            setMuniOptions,
            nyDataObj,
            county,
            courtMuni: [],
            taxYear,
            navigate,
            countyOptions,
          });
        }
        console.log(county);
      }, [county]);

      useEffect(()=>{ // update the avvailable village options when municipality is updated.
        console.log('running the get village useeffect (do this in the function for muniupdate')
        getVillageOptions(setVillageOptions, nyDataObj, municipality, county)
      },[municipality])
    
    
    
      // Memoized function to update municipality options
      const updateMuni = useCallback(({ updateField, updateValue, updateString = '', urlString }) => {
        updateMuniObj({
          updateField,
          updateValue,
          updateString,
          navigate,
          urlString,
          nyDataObj,
          county,
          taxYear,
          setCountyOptions,
          courtMuni: courtMunis,  // Pass courtMunis from state
        });
      }, [navigate, nyDataObj, county, taxYear, courtMunis]);
  
    // Combine the useeffect function of updatecourtmunioptions into a usememo that sets the munioptions.


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const StyledMenu = styled((props) => ( // for button dropdown.
        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...props}
        />
      ))(({ theme }) => ({
        '& .MuiPaper-root': {
          borderRadius: 6,
          marginTop: theme.spacing(1),
          minWidth: 180,
          color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
          boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          '& .MuiMenu-list': {
            padding: '4px 0',
          },
          '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
              fontSize: 18,
              color: theme.palette.text.secondary,
              marginRight: theme.spacing(1.5),
            },
            '&:active': {
              backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity,
              ),
            },
          },
        },
      }));

    return(
        <div className={`mx-auto grid content-center gap-1.5 items-center justify-center text-center py-1.5`}>
            
                {/* Top Grid row: County, Muni, Taxyear, and Lag dropdown */}
                        <div className='flex m-auto justify-center items-end min-w-[190px]'>
            <div className='flex m-auto gap-4 justify-center items-end min-w-[190px]'>
              {/* County dropdown, always visible. */}
                <div className='countyDropdown'>
                <NewDropdown
                      value={county}
                      label="County"
                      dropdownOptions={countyOptions.map(option => ({
                          value: option.value,
                          label: option.label
                      }))}
                      updateFunction={updateMuni}
                      updateFunctionArgs={{
                          updateField: 'county',
                          updateString: '', // assuming `option.label` is not needed
                          navigate,
                          urlString,
                          nyDataObj,
                          county,
                          TaxYear: taxYear,
                          setCountyOptions,
                      }}
                      disabled={countyOptions.length > 1 ? false : true}
                  />
                  {courtDate !== '' && county ==='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a County</span>
                  : null}
            </div>

            {/* Municipality dropdown, conditionally rendered. */}
            <div className='relative flex-col items-center'>
                <NewDropdown
                    value={municipality}
                    label="Municipality"
                    dropdownOptions={muniOptions.map(option => ({
                        value: option.value,
                        label: option.selectVal,
                        county: option.county,   // Passing additional fields for updateFunction
                        TaxYear: option.TaxYear  // Passing additional fields for updateFunction
                    }))}
                    updateFunction={(...args) => {
                    // console.log('Arguments passed to updateMuni:', args);
                    updateMuni(...args);
                }}
                    updateFunctionArgs={{
                        updateField: 'municipality',
                        updateString: '', // assuming `option.label` is not needed
                        urlString,
                        nyDataObj,
                        county,
                        navigate,
                        setCountyOptions,
                    }}
                    disabled={county === 'All' && courtDate=== '' || county==='select'}
                />
                {error ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>{error}</span>
                : courtDate !== '' && municipality === 'select' && county !=='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a Municipality</span>
                    : null}
            </div>


              {/* Village */}
            {villageOptions.length > 1 ?
            <NewDropdown
                value={village}
                label="Village"
                dropdownOptions={villageOptions.map(option => ({
                    value: option.value,
                    label: option.selectVal
                }))}
                updateFunction={updateMuni}
                updateFunctionArgs={{
                    updateField: 'village',
                    updateString: '', // assuming `option.label` is not needed
                    urlString,
                    nyDataObj,
                    navigate,
                    county: null,  // You can pass actual county if available
                    TaxYear: null,  // You can pass actual TaxYear if available,
                    setCountyOptions,
                }}
                disabled={(municipality === 'All' && villageOptions[0] === 'All') || villageOptions.length > 1 ? false : true}
            />
            :null  
            }

              {/* Taxyear drop */}
              <NewDropdown
                  value={taxYear}
                    label="Tax Year"
                    dropdownOptions={yearDropdownOptions}
                    width = {105}
                    updateFunction={updateMuni}
                    updateFunctionArgs={{
                      updateField: 'TaxYear',
                      updateString: '',
                      navigate: useNavigate(),
                      urlString,
                      nyDataObj,
                      county: null,
                      TaxYear: taxYear,
                      setCountyOptions,
                  }}
                />

                        {/* Lag dropdown */}
                        <div className=' flex justify-center m-auto content-center'>
                            <FormControl className='flex w-fit min-w-[85px] justify-end' size='small' fullWidth>
                                <InputLabel id="demo-simple-select-label">Lag:</InputLabel>
                                <Select
                                    value={lag} // set a default value for manualReview.
                                    className='w-fit]'
                                >
                                    {lagOptions.map((value, index) => (
                                        <MenuItem
                                            key={index}
                                            value={value.toString()} // Convert boolean to string to match the state type
                                            onClick={()=>setLag(value)}
                                            style={{ minHeight: '30px' }} // Set your desired minimum height here
                                        >
                                            {value.toString()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                {/* Graph Generation button */}
                        {microLoad?
                        <LoadingButton
                        id="demo-customized-button"
                        loading
                        loadingPosition='end'
                        className='max-w-[110px]'
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        >
                        Graph
                    </LoadingButton>
                        :

                        <>
                        <Button
                        id="demo-customized-button"
                        className='max-w-[110px]'
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        >
                        Graph
                        </Button>

                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={()=> {
                            const value = 'Distribution';
                            loadGraph(value,true); handleClose(); setMicroLoad(true)}} value='Distribution' disableRipple>
                        <CandlestickChartIcon/>
                        Distribution
                        </MenuItem>

                        <MenuItem onClick={()=> {
                            const value = 'X,Y Scatter';
                            loadGraph(value,true); handleClose(); setMicroLoad(true)}} disableRipple>
                        <ScatterPlotIcon />
                        X,Y Scatter
                        </MenuItem>
                        {/* <Divider sx={{ my: 0.5 }} /> */}

                        <MenuItem onClick={(e)=> {
                            const value = '3-D Scatter';
                            loadGraph(value,true); handleClose(); setMicroLoad(true)}} disableRipple>
                        <AutoGraphIcon />
                        3-D Scatter
                        </MenuItem>

                        <MenuItem onClick={handleClose} disableRipple>
                        <MoreHorizIcon />
                        More
                        </MenuItem>
                    </StyledMenu>
                    </>
                        }
                </div>
                </div>
                </div>
    )
}export default GraphParams