import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import { getRemoteConfig } from "firebase-admin/remote-config";
import { useState, useEffect, useCallback } from 'react';

// Other imports if you want to use these at some point.
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  getIdToken,
  sendPasswordResetEmail
} from "firebase/auth";
// Import other Firebase services as needed

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();
export const googleProvider = new GoogleAuthProvider();
// const remoteConfig = firebase.remoteConfig();
// remoteConfig.settings = {
//   minimumFetchIntervalMillis: 3600000, // 1 hour
// };

// Export functions as needed
const signup = async (email, password) => {
  try {
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

function logout() {
  return signOut(auth);
}



function useAuth() {
  const [currentUser, setCurrentUser] = useState();

  const handleAuthStateChanged = useCallback(
    (user) => {
      console.log(user)
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    },
    [setCurrentUser]
  );

  useEffect(() => {
    let isMounted = true;
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (isMounted) {
        handleAuthStateChanged(user);
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [handleAuthStateChanged]);

  return currentUser;
}
//   const [currentUser, setCurrentUser] = useState();
//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((authUser) => {
//       if (authUser) {
//         // User is signed in
//         console.log(authUser)
//         setCurrentUser(authUser);
//       } else {
//         // User is signed out
//         setCurrentUser(null);
//       }
//     });
//     return () => {
//       // Cleanup the listener when the component is unmounted
//       unsubscribe();
//     };
//   }, []);
// }
  // return currentUser;

  // const [currentUser, setCurrentUser] = useState();
  // useEffect(() => {
  //   const unsub = onAuthStateChanged(auth, (user) => {
  //     setCurrentUser(user);
  //   });
  //   return unsub;
  // return currentUser;  // return currentUser;

function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => getIdToken(userCredential.user)); // This gets you the JWT Token
}

function passwordReset(email) {
  return auth.sendPasswordResetEmail(email)
  // return sendPasswordResetEmail(auth, email)
}


export { auth, firestore, signup, passwordReset, useAuth, logout, login };