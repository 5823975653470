import React, { useEffect, useState, useRef } from 'react';
import {useCompStore, useAdminSettingsStore, useLocalDataStore, usePersistedDataStore} from './store';
import SortableTable from './SortableTable.js'
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Button,  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import axiosInstance from '../axiosConfig.js'
import DownloadXLSButton from './DownloadXLSButton';
import { toast } from 'react-toastify';
import {getMunicipalityOptions} from '../common/helper_functions.js'
import _ from 'lodash';

function CaseAnalysis({nyDataObj}){
const getYearDropdownOptions = usePersistedDataStore((state) => state.yearDropdownOptions)
const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
const getUpcomingCourtDates = usePersistedDataStore((state) => state.upcomingCourtDates)

// On the intiial load it gives MUI warnings because the county / muni lists are empty (same w/ taxyear I think)

const countyOptions = [
    { value: "All", label: "All", selectVal: 'All'},
    ...Object.keys(nyDataObj).map(key => ({
        label: key,
        // label: nyDataObj[key].nameWithSelectVal,
        value: nyDataObj[key].selectVal
    }))
];
const [muniOptions, setMunioptions] = useState([])
const keys = ['id', 'Address', 'Assessment', 'IFMV', 'RegVal', 'WorkupVal', 'SalePrice', 'SaleDate', 'TimeAdjSalePrice', 'BARRequestedValue', 'BARDeterminationAction', 'Reduction', 'Reduction$', 'RepID']

useEffect(()=>{
  getMunicipalityOptions(setMunioptions, getNegotiationObj)
},[getNegotiationObj.county])


const [repID, setRepID] = useState('')
const [loading, setLoading] = useState(null)
const [courtDate, setCourtDate] = useState('All')
const [headers, setHeaders] = useState(
  [
    {
      id: 'PID',
      numeric: false,
      disablePadding: true,
      alignment: 'left',
      style: 'text-xs',
      label: 'PID',
    },
    {
      id: 'Address',
      numeric: false,
      disablePadding: true,
      alignment: 'left',
      label: 'Address',
    },
    // {
    //     id: 'Assessment',
    //     numeric: true,
    //     disablePadding: true,
    //     alignment: 'right',
    //     label: 'Assess',
    // },
    {
      id: 'IFMV',
      numeric: true,
      disablePadding: true,
      alignment: 'right',
      label: 'IFMV',
    },
    {
      id: 'WorkupVal',
      numeric: true,
      disablePadding: true,
      alignment: 'right',
      label: 'WorkupVal',
    },
    {
      id: 'RegVal',
      numeric: true,
      disablePadding: true,
      alignment: 'right',
      label: 'RegVal',
    },
    {
      id: 'SalePrice',
      numeric: true,
      disablePadding: true,
      alignment: 'right',
      label: 'SalePx',
    },
    {
      id: 'SaleDate',
      numeric: false,
      disablePadding: true,
      alignment: 'right',
      label: 'SaleDate',
    },
    {
      id: 'TimeAdjSalePrice',
      numeric: true,
      disablePadding: true,
      alignment: 'right',
      label: 'Time Adj',
    },
    {
      id: 'LastMLSListingPrice',
      numeric: true,
      disablePadding: true,
      alignment: 'right',
      label: 'Listing $',
    },
    {
      id: 'LastMLSListingDate',
      numeric: true,
      disablePadding: true,
      alignment: 'right',
      label: 'List Dt',
    },
    // {
    //   id: 'BARDeterminationAction',
    //   numeric: false,
    //   disablePadding: true,
    //   alignment: 'right',
    //   label: 'Det',
    // },
    {
      id: 'BARRequestedValue',
      numeric: true,
      disablePadding: true,
      alignment: 'right',
      label: 'Request',
    },
    // {
    //     id: 'Reduction',
    //     numeric: true,
    //     disablePadding: true,
    //     alignment: 'right',
    //     label: 'Reduct',
    //   },
      {
        id: 'Reduction$',
        numeric: true,
        disablePadding: true,
        alignment: 'right',
        label: 'Reduction ($)',
      },
      {
        id: 'RepID',
        numeric: true,
        disablePadding: true,
        alignment: 'right',
        label: 'RepID',
      },
])
const [portfolioReduction, setPortfolioReduction] = useState(null)
const [portfolioVal, setPortfolioVal] = useState(null)
const [averageReduction, setAvgReduction] = useState(0)
const [proportionReductions, setProportionReductions] = useState(0)


async function handleFlagSCAR(cases, filingStatus){
    setLoading(true)
    try{
    // Pass in an array (or single) case to flag that it should NOT be filed at SCAR
    const optimizeResponse = await axiosInstance.post(`/flag_case_scar_status`, { // passing in the indexes for pagination
      Subject: cases,
      TaxYear: getNegotiationObj.TaxYear,
      filingStatus: filingStatus // set this to be 0, or 1 by param passed in.
    });

    const updateRows = _.cloneDeep(rows);
        // Lookup from rows for the match on cases value, to update case.SCARFiled === 0
        const newRows = updateRows.map((row) =>
          cases.includes(row.id) ? { ...row, SCARFiled: filingStatus } : row
        );
    setRows(newRows)

    const caseWord = cases.length > 1 ? 'cases' : 'case';
    let message = ''

    if (filingStatus === 0) {
      message = `Flagged ${caseWord} to not file at Scar.`;
    } else if (filingStatus === null) {
      message = `Flagged ${caseWord} to be filed at Scar.`;
    }

    toast.success(message, {
      position: 'top-left',
      className: 'mt-10',
      autoClose: 3000,
    });
  }catch{
    toast.error('some Error occured', {
      position: 'top-left',
      className: 'mt-10',
      autoClose: 3000,
    })
  }
  setLoading(false)
}
// need to update create data function (object.keys of an index within create data)
function createData(id, pid, Address, Town, IFMV, PriorAssess, WorkupVal, InitialValue, SalePrice, SaleDate, TimeAdjSalePrice, LastMLSListingPrice, LastMLSListingDate, BARRequestedValue, BARDeterminationAction, BARDeterminationValue, BAR$val, SCARFiled, repID,) { // define data you build in table
    return {
      id,
      PID: pid,
      Address: `${Address}, ${Town}`,
      IFMV,
      Assessment: PriorAssess, // idk if this is right. May need more nuance here.
      WorkupVal,
      RegVal: InitialValue,
      SalePrice: SalePrice,
      SaleDate: SaleDate,
      TimeAdjSalePrice,
      LastMLSListingPrice,
      LastMLSListingDate,
      BARRequestedValue,
      BARDeterminationAction,
      Reduction: BARDeterminationValue,
      Reduction$: BAR$val, 
      SCARFiled,
      RepID: repID,
    };
  }
const [rows, setRows] = useState([ // your data to render
  ])
// state for the table data.
useEffect(() => {
  if (rows && rows.length > 0) {
    // Initialize sum variables
    let sumIFMV = 0;
    let sumAssessment = 0;
    let sumReduction = 0;
    let totalReductions = 0;
    let countReductions = [];

    // Loop through each row and accumulate sums
    rows.forEach(row => {
        sumIFMV += row.IFMV || 0;  // Ensure IFMV is numeric, default to 0 if undefined
        sumAssessment += row.Assessment || 0;  // Ensure Assessment is numeric, default to 0 if undefined
        if (typeof row.Reduction === 'number' && !isNaN(row.Reduction) && row.BARDeterminationAction !== 'BD') {
          sumReduction += row.Reduction;
          let percentRed = (row.Assessment-row.Reduction)/row.Assessment;
          countReductions.push(percentRed);
        }else{
          sumReduction += row.Assessment || 0;
        }
    });
    // Calculate the average reduction
    const sumRed = countReductions.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const divisor = countReductions.length === 0 ? 1 : countReductions.length;
    const displayReductions = countReductions.length === 0 ? 0 : countReductions.length;
    const averageReduction = sumRed / divisor;

    // Calculate the proportion of reductions granted
    const proportionReductions = rows.length > 0 ? countReductions.length / rows.length : 0;

    // Set the sums
    setPortfolioVal(sumIFMV);
    setPortfolioReduction(((sumAssessment - sumReduction) / sumAssessment));

    // Log the average reduction and the proportion of reductions granted
    console.log("Average Reduction:", averageReduction);
    setAvgReduction(averageReduction)
    console.log("Proportion of Reductions Granted:", proportionReductions);
    const updateReductionCount = {proportionReductions: proportionReductions, countReductions: displayReductions}
    setProportionReductions(updateReductionCount)
  }
}, [rows]); // useEffect will run whenever 'rows' changes


const formatDate = (dateString) => {
  if (!dateString) return ''; // Handle case where dateString is undefined or null
  
  const date = new Date(dateString);
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)}`;
  
  return formattedDate;
};

const calculateMktVal = (BarValue, RAR) => {
  let reduction = "";

  if (typeof BarValue === 'number' && !isNaN(BarValue) && typeof RAR === 'number' && !isNaN(RAR) && RAR !== 0) {
    reduction = Number((BarValue / RAR).toFixed(0));
  }

  return reduction;
}

const repIDList = ['',1,2] // manual for now --- if you have anyone else, add them in.

async function handleTableUpdate(e){
    setLoading(true);
    setRows([])

    const repNegotiationObj = {...getNegotiationObj, RepID: repID, }
    const oldDateObj = repNegotiationObj.courtDate
    repNegotiationObj.MuniCode = getNegotiationObj['county'] // Set municode for county

    if(getNegotiationObj.municipality!=='All'){ // update the municode to incorporate municipality
        repNegotiationObj.MuniCode = getNegotiationObj['county']+getNegotiationObj['municipality']
    }else {  // this does the lookup to get the list of municipalities

    }
    if(getNegotiationObj.village==="All"){
      repNegotiationObj.villageRun = 0
    }
    if(getNegotiationObj.courtDate!=='All'){
      repNegotiationObj.courtDateSelectedMuni = repNegotiationObj.MuniCode
      const [month, day, year] = getNegotiationObj.courtDate.split('/');
      const courtDateFormatted = [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
      repNegotiationObj.courtDate = courtDateFormatted
    }
    

    // Here start the iteration
    // else{ repNegotiationObj.MuniCode = null}

    // add a call to a "get rar" route, and add that into the negotiation Obj (then also set RAR in the main fetch.)
    // here, append a new object for the new municode (in a loop from the top of this, and havev it flow down.)
    // const getRAR = await axiosInstance.post(`/get_rar`,repNegotiationObj)
    // repNegotiationObj.RAR = getRAR.data.RAR

// Need to pass in an ARRAY of the municodes we want to run.
    const courtYearResponse = await axiosInstance.post(`/case_summary`,repNegotiationObj)
    
          console.log(courtYearResponse.data.ParcelData)
          const updateRows = courtYearResponse.data.ParcelData.map((item, index) => (
            // These fields weren't filling in from the parcel Data
            createData(
            //   index + 1,  // Assuming you want to create a unique ID based on index
              item.PID,  // pid first (for ID)
              item.PID,
              item.Address,
              item.Town,
              // item.Zip5,
              calculateMktVal(item.TentativeAssessmentTown,item.RAR), // calculate the IFMV based off of the RAR / prior AV.
                // item.IFMVTown, // IFMV
              item.TentativeAssessmentTown, // 
              item.SubjectMarketValue, // mkt vavl
              item.InitialMarketValue,
              item.SalePrice,
              formatDate(item.SaleDate),
              item.TimeAdjSalePrice,
              item.LastMLSListingPrice,
              formatDate(item.LastMLSListingDate),
              item.BARRequestedValue,
              item.BARDeterminationAction,
              item.BARDeterminationValue,
              calculateMktVal(item.BARDeterminationValue,item.RAR),
              item.SCARFiled,
              item.RepID,
            )
          ));
          // console.log(updateRows)
          repNegotiationObj.courtDate = oldDateObj // return your date to the formatted // version not yyyy-mm-dd
          setRows(updateRows)
          setNegotiationObj(repNegotiationObj)
          console.log(repNegotiationObj)

          setLoading(false)
        //   Rows is what you want to pass in and download XLS.
}

    function updateMuniObj(updateField, updateValue, updateString=''){ // dynamic muniObj update function (this could prolly be abstracted into common exports)
        // need to update village
        // need to update courtdate
        const updatedNegotiationObj = {...getNegotiationObj}
        updatedNegotiationObj[updateField] = updateValue

        if(updateField!=='TaxYear' && updateField !=='CourtDate' && updateField !=='RepID'){
        updatedNegotiationObj[updateField+'String'] = updateString
            if (updateField==='county'){ // if you change county, set muni back to all
                updatedNegotiationObj['municipality'] = 'All'
            }
        }

        setNegotiationObj(updatedNegotiationObj)
    }

    // HERE, you're going to construct the object to render table (create data, list headers, etc.)

    return(
        <div>

            <div className='bg-white rounded md p-4 m-2 flex justify-between px-12'>
                {/* Selection criteria here */}
                
                {/* Dropdowns for County, Muni, Year, Repid */}
                <div className='flex'>
                    
                    {/* County drop */}
                    <FormControl className='flex w-fit min-w-[115px] justify-end' size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">County:</InputLabel>
                            <Select
                                value={getNegotiationObj.county} // set a default value for axis1.
                                className='w-fit min-w-[115px]'
                                label="County:"
                                // onChange={(e) => updateMuniObj('county',e.target.value, e.target)}
                            >
                                {countyOptions.map((option,index) => 
                                (
                                    <MenuItem 
                                        key={index}
                                        value={option.value}
                                        name={option.label}
                                        onClick={() => updateMuniObj('county',option.value,option.label)}
                                        style={{ minHeight: '30px' }}  // Set your desired minimum height here
                                    >
                                        {option.label}
                                    </MenuItem>
                                )
                            )}
                            </Select>
                    </FormControl>

                    {/* Municipality drop */}
                    {getNegotiationObj.county !== 'All'?
                    <FormControl className='flex w-fit min-w-[135px] justify-end ml-4' size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">Municipality:</InputLabel>
                            <Select
                                value={getNegotiationObj.municipality} // set a default value for axis1.
                                className='w-fit min-w-[135px]'
                                label="Group By:"
                                // onChange={(e) => setRepID(e.target.value)}
                            >
                                {muniOptions.map((option, index) => 
                                (
                                    <MenuItem
                                        key={index}
                                        value={option.value}
                                        onClick={() => updateMuniObj('municipality',option.value,option.label)}
                                        style={{ minHeight: '30px' }}  // Set your desired minimum height here
                                    >
                                        {option.selectVal}
                                    </MenuItem>
                                )
                            )}
                            </Select>
                    </FormControl>
                    :
                    null}

                    {/* Can add a village drop if it exists within a muni selection w/ same format above. */}

                    {/* Taxyear drop */}
                    <FormControl className='flex w-fit min-w-[115px] justify-end ml-4' size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">Tax Year:</InputLabel>
                            <Select
                                value={getNegotiationObj.TaxYear} // set a default value for axis1.
                                className='w-fit min-w-[115px]'
                                label="Group By:"
                            >
                                {getYearDropdownOptions.map((value, index) => 
                                (
                                    <MenuItem
                                        key={index}
                                        value={value}
                                        onClick={() => updateMuniObj('TaxYear',value)}
                                        style={{ minHeight: '30px' }}  // Set your desired minimum height here
                                    >
                                        {value}
                                    </MenuItem>
                                )
                            )}
                            </Select>
                    </FormControl>

                    {/* Rep ID */}
                    <FormControl className='flex ml-4 w-fit min-w-[105px] justify-end' size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">RepID:</InputLabel>
                            <Select
                                value={repID} // set a default value for axis1.
                                className='w-fit min-w-[105px]'
                                label="Rep Id:"
                                onChange={(e) => setRepID(e.target.value)}
                            >
                                {repIDList.map((id, index) => 
                                (
                                    <MenuItem 
                                        key={index} 
                                        value={id} 
                                        style={{ minHeight: '30px' }}  // Set your desired minimum height here
                                    >
                                        {id === null ? 'None' : id}
                                    </MenuItem>
                                )
                            )}
                            </Select>
                    </FormControl>

                    {/* Court dates */}
                    <FormControl className='flex ml-4 w-fit min-w-[105px] justify-end' size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">CourtDate:</InputLabel>
                            <Select
                                value={getNegotiationObj.courtDate} // set a default value for axis1.
                                className='w-fit min-w-[105px]'
                                label="Rep Id:"
                            >
                                {getUpcomingCourtDates.map((date, index) => 
                                (
                                    <MenuItem 
                                        key={index} 
                                        value={date} 
                                        onClick={() => updateMuniObj('courtDate',date)}
                                        style={{ minHeight: '30px' }}  // Set your desired minimum height here
                                    >
                                        {date === null ? 'None' : date}
                                    </MenuItem>
                                )
                            )}
                            </Select>
                    </FormControl>
                </div>

                <div className='bg-white flex mx-auto justify-center content-center'>
                {rows.length > 0 ? (
                  <div className="flex items-center mx-4 space-y-2">
                    <div className="content-center">
                      Portfolio Value: {portfolioVal !== null ? portfolioVal.toLocaleString() : 'Loading...'}
                    </div>
                    <div className="content-center font-bold">
                      Portfolio Red: {(portfolioReduction * 100).toFixed(1)}%
                    </div>
                    <div className="content-center font-bold">
                      Avg reduction: {(averageReduction * 100).toFixed(1)}%
                    </div>
                    <div className="content-center font-bold">
                      # reductions: {proportionReductions?.countReductions ?? 0} ({((proportionReductions?.proportionReductions ?? 0) * 100).toFixed(1)}%)
                    </div>
                  </div>
                ) : null}


                </div>
                {/* Download xls button -  */}
                <div className='mx-auto'>
                    <DownloadXLSButton jsonData={rows} fileName="analysis" keys={keys} />
                </div>

                    {loading?
                    <LoadingButton
                        id="demo-customized-button"
                        loading
                        loadingPosition='end'
                        className='max-w-[160px]'
                        aria-controls={'demo-customized-menu'}
                        aria-haspopup="true"
                        aria-expanded={'true'}
                        variant="contained"
                        disableElevation
                        // onClick={handleTableUpdate}
                        >
                        Analyze Cases
                    </LoadingButton>
                        :
                        <Button
                        id="demo-customized-button"
                        className='max-w-[160px]'
                        variant="contained"
                        disableElevation
                        onClick={handleTableUpdate}
                        >
                        Analyze Cases
                        </Button>
                        }
                    {/* repeat the selection to also offer it by Muni? */}
            </div>
            <>
            

            <SortableTable
            headCells={headers}
            rows={rows}
            selectionFunction={handleFlagSCAR}
            functionName={'Flag to not file SCAR'}
            undoFunctionName={'Undo'}
            // Perhaps add an "x" to clear selection
            />
            </>
        </div>
    )
} export default CaseAnalysis;