import React, { useEffect, useState, useMemo, useCallback } from "react";
import Select from "react-select";
import { usePersistedDataStore } from './store';

const DropdownStyle = React.memo(({ initialSelection, options, onSelect, updated, inventoryUpdates, setInventoryUpdates, index }) => {
  const getCaseNumber = usePersistedDataStore((state) => state.caseNumber);
  const [editedStyle, setEditedStyle] = useState(null);

  useEffect(() => {
    setEditedStyle(null);
    const updatedInventory = { ...inventoryUpdates };

    if (index === updatedInventory.Index) {
      console.log(`deleting from ${index}`);
      for (const key in updatedInventory) {
        if (Object.hasOwnProperty.call(updatedInventory, key)) {
          console.log(`deleting ${key}`);
          delete updatedInventory[key];
        }
      }
      console.log('setting invetnroy rn')
      console.log(updatedInventory)
      setInventoryUpdates(updatedInventory);
    }
  }, [getCaseNumber, index, setInventoryUpdates]);

  const customStyles = useMemo(() => ({
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "transparent" : "transparent",
      display: 'inline-flex',
      border: "none",
      minWidth: "121px",
      width: "auto",
      "&:hover .css-tj5bde-Svg": {
        fill: "#4B5563",
      },
      boxShadow: state.isFocused ? "0 0 0 2px #3C50E0" : "none",
      "& .css-1pqaob-indicatorContainer": {
        fill: state.isFocused ? "#4B5563" : "#EFF2F7",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3B82F6" : state.isFocused ? "#EFF2F7" : "none",
      color: state.isSelected ? "#FFFFFF" : "#4B5563",
      zIndex: 60,
      "&:hover": {
        backgroundColor: state.isSelected ? "#3B82F6" : "#EFF2F7",
        color: state.isSelected ? "#4B5563" : "#4B5563",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingLeft: '8px',
      overflow: 'visible',
      color: updated ? '#2AAB5D' : "#6F7E94",
      margin: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused || state.isSelected ? "transparent" : "transparent",
      "&:hover": {
        color: "#4B5563",
      },
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,  // Increase the zIndex to ensure it is above other elements
      position: 'absolute',  // Use absolute position to align correctly
      width: 'auto',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,  // Ensure the portal has a high zIndex
    }),
    input: (provided) => ({
      ...provided,
      paddingLeft: '16px',
      margin: 0,
      boxShadow: 'none',
      border: 'none',
      "&:focus": {
        outline: '2px solid #D1D5DB',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
      padding: 0,
    }),
  }), [updated]);

  const handleSelect = useCallback((option) => {
    onSelect(option.value);
    setEditedStyle(option.value);
    // console.log(option);
  }, [onSelect]);

  return (
    <Select
      className="min-w-[60px] text-center content-center xl:pl-3"
      options={options.map(option => ({ value: option, label: option }))}
      value={
        editedStyle !== null && updated ?
          { value: editedStyle, label: editedStyle }
          :
          { value: initialSelection, label: initialSelection }
      }
      onChange={handleSelect}
      styles={customStyles}
      menuPortalTarget={document.body}
      menuPlacement="bottom"
      classNamePrefix="custom-select"
    />
  );
});

DropdownStyle.whyDidYouRender = true;

export default React.memo(DropdownStyle);
