
import React, { useState } from 'react';

const MunicipalCard = ({ municipalityData, isAdmin, muniName, yoyChanges, analysisObj }) => {
  const [showZipCodes, setShowZipCodes] = useState(false);
  // console.log(municipalityData)
  // console.log(yoyChanges.municipal_yoy_changes.regular)
  const selectedMuniYoY = yoyChanges.municipal_yoy_changes.regular.find(muniData => muniData.municipality === municipalityData.municipality);
  console.log(selectedMuniYoY)

  return (
    <div key={municipalityData.municipality} className="my-2 p-2 mx-2 border m-auto text-center">
      <div className="flex text-center justify-center">
        <div className=" font-bold text-lg">{muniName}</div>
      </div>
      
      {/* Render data conditionally */}
      <>
        {isAdmin && (
          <div className="grid grid-cols-2 flex">
            <div className="text-left">Total Amount:</div>
            <div className="flex grid grid-cols-2 text-left ml-2">
              {municipalityData.total_amount.toLocaleString()}
              {selectedMuniYoY && (
                <div className={`text-xs flex items-center ${selectedMuniYoY.total_amount >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                  {selectedMuniYoY.total_amount >= 0 ? '+' + selectedMuniYoY.total_amount.toLocaleString() : '(' + selectedMuniYoY.total_amount.toLocaleString() + ')'}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="grid grid-cols-2">
          <div className="text-left">Client #:</div>
          <div className="flex grid grid-cols-2 text-left ml-2">
            {municipalityData.retainers_count.toLocaleString()}
            {selectedMuniYoY && (
              <div className={`text-xs flex items-center ${selectedMuniYoY.retainers_count >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                {selectedMuniYoY.retainers_count >= 0 ? '+' + selectedMuniYoY.retainers_count.toLocaleString() : '(' + selectedMuniYoY.retainers_count.toLocaleString() + ')'}
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div className="text-left">Invoiced #:</div>
          <div className="flex grid grid-cols-2 text-left ml-2">
            {municipalityData.invoice_count.toLocaleString()}
            {selectedMuniYoY && (
              <div className={`text-xs flex items-center ${selectedMuniYoY.invoice_count >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                {selectedMuniYoY.invoice_count >= 0 ? '+' + selectedMuniYoY.invoice_count.toLocaleString() : '(' + selectedMuniYoY.invoice_count.toLocaleString() + ')'}
              </div>
            )}
          </div>
        </div>

        {isAdmin && (
          <div className="grid grid-cols-2">
            <div className="text-left">Avg Invoice:</div>
            <div className="flex grid grid-cols-2 text-left ml-2">
              {Math.round(municipalityData.avg_fee).toLocaleString()}
              {selectedMuniYoY && (
                <div className={`text-xs flex items-center ${selectedMuniYoY.avg_fee >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                  {Math.round(selectedMuniYoY.avg_fee) >= 0 ? '+' + Math.round(selectedMuniYoY.avg_fee).toLocaleString() : '(' + Math.round(selectedMuniYoY.avg_fee).toLocaleString() + ')'}
                </div>
              )}
            </div>
          </div>
        )}

        <div className={`grid grid-cols-2 ${isAdmin ? '' : 'font-bold'}`}>
          <div className="text-left">Win Rate:</div>
          <div className="flex grid grid-cols-2 text-left ml-2">
            {(municipalityData.win_rate).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
            {selectedMuniYoY && (
              <div className={`text-xs flex items-center ${(selectedMuniYoY.win_rate) >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                {(selectedMuniYoY.win_rate) >= 0 ? '+' + (selectedMuniYoY.win_rate).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) : '(' + (selectedMuniYoY.win_rate).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) + ')'}
              </div>
            )}
          </div>
        </div>

        {isAdmin && (
          <div className="grid grid-cols-2">
            <div className="font-bold text-left">REV/CLIENT:</div>
            <div className="flex grid font-bold grid-cols-2 text-left ml-2">
              {Math.round(municipalityData.revenue_per_client).toLocaleString()}
              {selectedMuniYoY && (
                <div className={`text-xs font-normal flex items-center ${Math.round(selectedMuniYoY.revenue_per_client) >= 0 ? 'text-success' : 'text-danger'} text-left ml-2`}>
                  {Math.round(selectedMuniYoY.revenue_per_client) >= 0 ? '+' + Math.round(selectedMuniYoY.revenue_per_client).toLocaleString() : '(' + Math.round(selectedMuniYoY.revenue_per_client).toLocaleString() + ')'}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default MunicipalCard;