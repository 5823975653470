import {useAdminSettingsStore, usePersistedDataStore} from './store';

function ZipAnalysis({isAdmin, analysisObj, microLoad, }){
    const getTaxYear = usePersistedDataStore((state) => state.taxYear)
    
    const sortedZipData = analysisObj.zip

    console.log(sortedZipData)


    // Abstract the in-line calculations for the zip data object to the top level here so you can setup the object, and enable flexible
    // Filtering (if you want to swap to filter by avg revenue, etc.
    return(
        <div className=" ">
            <div className="w-full shadow-lg items-center content-center bg-white dark:bg-boxdark">
                <div className="flex m-auto border-b items-center content-center justify-center text-center">Zip Code Summary</div>
                {/* Loading widget if fetch not done. */}
                {/* I don't think we need this. */}
                {microLoad?
                <div className="p-4 items-center content-center m-auto">
                    <div>
                    <div className="flex items-center content-center justify-center z-[9999]">
                    <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
                    </div>
                </div>
                </div>
                :
                null}

                {analysisObj && !microLoad ? ( // these total displays can be removed
                    <div className="justify-center items-center">
                        {/* Display TOTAL values at the top */}
                        
            {/* County level data in its own grid */}
            {/* NEED TO DO THE MATH IN ORDER TO  */}
            <div className="grid grid-cols-2 lg:grid-cols-3 pb-4 gap-4">
              
           
                  {/* return ( */}
                  {sortedZipData.data.map((zipData) => {
                    const zipYoY = sortedZipData.yoy_changes.find(zipData=> zipData.zip === sortedZipData.data.zip);
                    console.log(zipYoY) // the above seems like its not really working.      
                    return(
                    <button key={zipData.zip} className="border m-auto text-center">
                      <div className="flex items-center justify-center">
                        <div className="flex">
                          {/* <div className="font-bold text-lg">County: </div> */}
                          <div className="text-left font-bold text-lg">{zipData.zip}</div>
                        </div>
                      </div>
                      <div className="mb-1 mx-4 grid grid-cols-1 m-auto">
                        {isAdmin?
                        <div className="grid grid-cols-2 flex">
                        <div className="text-left">Total Amount:</div>
                        <div className="text-left ml-2">{zipData.InvoiceTotal.toLocaleString()}</div>
                        </div>
                        :
                        null}

                        <div className="flex grid grid-cols-2">
                        <div className="text-left">Client Count:</div>
                        <div className="text-left ml-2">{zipData.RetainersCount.toLocaleString()}</div>
                        </div>

                        <div className="flex grid grid-cols-2">
                        <div className="text-left">Invoiced Count:</div>
                        <div className="text-left ml-2">{zipData.InvoiceCount.toLocaleString()}</div>
                        </div>

                        {isAdmin?
                        <div className="flex grid grid-cols-2">
                        <div className="text-left">Avg Invoice:</div>
                        <div className="text-left ml-2">{Math.round((zipData.InvoiceTotal/zipData.InvoiceCount)).toLocaleString()}</div>
                        </div>
                        :
                        null}

                        <div className="flex grid grid-cols-2">
                        <div className="text-left">Win Rate:</div>
                        <div className="text-left ml-2">
                          {((zipData.InvoiceCount / zipData.RetainersCount)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
                        </div>
                        </div>

                        {isAdmin?
                        <div className="flex grid grid-cols-2">
                        <div className="font-bold text-left">REV/CLIENT:</div>
                        <div className="font-bold text-left ml-2">
                          {Math.round((zipData.InvoiceTotal/zipData.InvoiceCount) * (zipData.InvoiceCount / zipData.RetainersCount)).toLocaleString()}
                        </div>
                        </div>
                        :
                        null}
                      </div>
                    </button>
                    )
                })
        }
            </div>
          </div>
        ) :
        null
        }
      </div>
    </div>
    )
}export default ZipAnalysis