import React, { useEffect, useState } from "react";
import {usePersistedDataStore} from './store';
import { TEInput } from "tw-elements-react";

const NumberInput = ({ onChange, value, style, keyName, updated, textSize}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(null);
  const getCaseNumber = usePersistedDataStore((state) => state.caseNumber)

  useEffect(()=>{
    if(!updated){
    setEditedValue(null)
    setIsEditing(false)
  }
  },[getCaseNumber,updated])

  const customInputStyles = { // for baths element
    // Define your custom styles here using Tailwind CSS classes
    base: 'px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500',
    activeLabel: 'scale-[0.6]',
    input: 'peer w-[50px] block min-h-[auto] rounded border-0 bg-white outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary motion-reduce:transition-none` placeholder:opacity-0 disabled:bg-neutral-100 read-only:bg-neutral-100 dark:disabled:bg-neutral-700 dark:read-only:bg-neutral-700 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary',
    label: `pointer-events-none absolute right-2 top-0 mt-[1px] pt-0 mb-0 max-w-[90%] bg-transparent origin-[10_0] truncate transition-all duration-200 ease-out peer-focus:scale-[0.6] motion-reduce:transition-none`,
  };

  // See if you can make it autofocus the full baths thing hwen u click the first time

  const handleInputChange = (e) => {
    const rawValue = e.target.value;
    let newValue;
    if (rawValue.includes('.')) {
        newValue = parseFloat(rawValue.replace(/[^\d.]/g, '')); // Convert the string to a float
    } else {
        newValue = parseInt(rawValue.replace(/[^\d]/g, ''), 10); // Convert the string to an integer
    }
    if(keyName==='Bath'){
      const bathValueUpdate = {...editedValue}
      bathValueUpdate[e.target.id] = newValue
      console.log(Object.keys(bathValueUpdate).length)
      setEditedValue({...bathValueUpdate});
      if(Object.keys(bathValueUpdate).length>1){ // only fire the onchange if you've got both values.
        onChange(bathValueUpdate, value, keyName);
      }
    // Have it only fire change if you've entered values for both ID's
    }else{
      setEditedValue(newValue)
      onChange(newValue, value, keyName);
    }
  };

  const handleInputClick = () => {
    setIsEditing(true);
    setEditedValue('')
  };

  const handleInputBlur = () => { // close out of the input option if you click away:
    if ((editedValue !== null)){
      if((Object.keys(editedValue).length>1 || Object.keys(editedValue).length<1) && keyName==='Bath'){
      // this if statement kicks you out of baths edit only when values are in both (or none).
      setIsEditing(false);
      }
    }
    if(keyName!=='Bath'){
      setIsEditing(false);
    }

    if(editedValue===''){ // if no value, set it back to defaults.
      if(style==='imputed'){ // These are the nsame now, no need to do anything diff (seemingly?)
        setEditedValue(null)
        onChange(value,value,keyName)
      }else{
        setEditedValue(null)
        onChange(value,value,keyName)
      }
    }
  };

  return (
    isEditing&&keyName==='Bath'?
    <div className="flex">
      <TEInput
        id="Bath"
        label="Full"
        size='xs'
        className="bg-transparent"
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        theme={customInputStyles}
      >
      </TEInput>
      <div className="mr-0.5"></div>
        <TEInput
        id="HalfBath"
        // value={value.HalfBath}
        label="Half"
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        theme={customInputStyles}
      ></TEInput>
    </div>
    :
    // The input below is when not editing the bathroom component.
    <input
      type="text"
      value={
      // First ternary is for normal features (raelly just SQFT atm, incldue the , seperator by passing to localestring.)
      keyName === 'Sqft' ? (editedValue !== null ? editedValue.toLocaleString() : (style === 'imputed' ? Math.round(value,0).toLocaleString() + '*' : value.toLocaleString()))
      :
      // If not Baths (baths have dual values:)
      keyName!=='Bath'?(editedValue !== null ? editedValue : (style === 'imputed' ? Math.round(value,0) + '*' : value))
      :
      // Our baths ternary:
      (editedValue !== null && Object.keys(editedValue).length > 1 ? editedValue.Bath+editedValue.HalfBath/2 : (style === 'imputed' ? Math.round(value.Bath+value.HalfBath/2,0) + '*' : value.Bath+value.HalfBath/2))
      }
      readOnly={!isEditing}
      onClick={handleInputClick}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      className={`px-1 py-[0.32rem] rounded-md w-[60px] bg-transparent text-center outline-none  ${isEditing ? '' : ' border-none'} ${textSize === 'small' ? 'text-xs w-[80px]' : ''} ${style === 'imputed' ? 'text-danger' : ''} ${editedValue && updated ? 'text-success' : ''}`}
    />

  );
};

export default NumberInput;
