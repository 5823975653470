import { usePersistedDataStore } from '../components/store';
import axiosInstance from '../axiosConfig.js'
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';

export default function useUpdateDB() {
    const persistedDataStore = usePersistedDataStore();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const view = queryParams.get('view') || '';
    const navigate = useNavigate()
    // const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.

    async function updateDB(confirmed = false, setLoading, inventoryUpdates, setInventoryUpdates, setConfirmModal, index) {
      // if (typeof setConfirmModal !== 'function') {
      //     console.error('setConfirmModal is not a function!@@!#@!!$#!@!$%!@!@');
      //     return;
      // }

    const getCompSheet = persistedDataStore.compSheet
    const getNegotiationObj = persistedDataStore.negotiationObj
    const setOptimizedComps = persistedDataStore.setOptimizedComps
    const setCompSheet = persistedDataStore.setCompSheet
    const getOptimizedComps = persistedDataStore.optimizedComps
    const getCourtComps = persistedDataStore.courtComps

    if(!confirmed){
      setConfirmModal(true)
    }else{ // go ahead with ur db update function.
    try{
      setLoading(true)
    let inventoryUpdatesPlusPID = { ...inventoryUpdates };
    delete inventoryUpdatesPlusPID['Index']; 

    // THIS GETS THE PID FROM REGULAR
    if(view==='regular'){
      inventoryUpdatesPlusPID['parcel_id'] = getCompSheet[comp].parcel_id[index]
    }else if(view==='optimized'){
      inventoryUpdatesPlusPID['parcel_id'] = getOptimizedComps[comp].parcel_id[index]

    }else if(view==='court'){
      inventoryUpdatesPlusPID['parcel_id'] = getCourtComps[comp].parcel_id[index]
    }
    // console.log(inventoryUpdatesPlusPID)

    // Remove court date
    let inventoryUpdateObj = {
      ...getNegotiationObj,
      record: inventoryUpdatesPlusPID,
    }
    
    if (courtDate !== '' ) { // if you did a court date run, setup your obj to re-run JSUT court date.
      console.log('court date not null!')
      console.log(courtDate)
      const [month, day, year] = courtDate.split('/');
      const courtDateFormatted = [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
      inventoryUpdateObj = {
        ...getNegotiationObj,
        record: inventoryUpdatesPlusPID,
        CourtDate: courtDateFormatted,
        CourtRun: 1
      }
    }
    const casePIDs = inventoryUpdateObj.cases.map(obj => obj.PID); // only run this for the proper UIDS
    inventoryUpdateObj = {
      ...inventoryUpdateObj,
      uids: casePIDs,
    }

    delete inventoryUpdateObj.courtDate;
    console.log(inventoryUpdateObj)
    
    const updateInventoryResponse = await axiosInstance.post(`/update_inventory`,inventoryUpdateObj)
    // THE RESPONSE DOESN"T preserve if you constrained with setteld only or not

    console.log(updateInventoryResponse) // data.parameters is how you can see the actual values updated.
    let updatedObj;
    // this conditional logic attempts to parse JSON (reuired based on response returned in prod), otherwise it just extracts the value.
    try {
        updatedObj = JSON.parse(updateInventoryResponse.data);
    } catch (error) {
      // this catch makes it work locally since the JSON parse i  not required.
        updatedObj = updateInventoryResponse.data;
        console.log("JSON coulnd't be parsed, using raw response. if you're in development, this can be ignored.");
    }
    console.log(updatedObj);
    if(updateInventoryResponse.status===200){
      setInventoryUpdates({})
      setCompSheet(updatedObj[0])
      setOptimizedComps(updatedObj[1])
      const url = new URL(location.pathname+location.search, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      // searchParams.set('comp', 1); // don't reset the comp if you run inventory update
      const newUrlString = `${url.pathname}?${searchParams.toString()}`;
      navigate(newUrlString);
    }
    }catch (error) {
      console.log(error, 'Error while reading settlements', setLoading);
      setLoading(false)
    }
  }
  setLoading(false)
}
return updateDB
}