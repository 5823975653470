import {useCompStore, useAdminSettingsStore, useLocalDataStore, usePersistedDataStore} from './store';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import _, {isEqual} from 'lodash';
import CurrencyInput from 'react-currency-input-field';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { updateMuniObj, getMunicipalityOptions, getVillageOptions, getCountyOptions } from '../common/helper_functions';
import axiosInstance from '../axiosConfig.js'
import NewDropdown from './NewDropdown.js'

export default function MuniSelection({nyDataObj, fetchComps, yearDropdownOptions, upcomingCourtDates}){
    const getShowSettledCases = useAdminSettingsStore((state) => state.showSettledCases)
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const [displayOptions, setDisplayOptions] = useState(false);
    const setShowSettledCases = useAdminSettingsStore((state) => state.setShowSettledCases)
    const getDefaultNumCases = useAdminSettingsStore((state) => state.defaultNumCases)
    const setDefaultNumCases = useAdminSettingsStore((state) => state.setDefaultNumCases)
    const getManualReview = useAdminSettingsStore((state) => state.manualReview)
    const setManualReview = useAdminSettingsStore((state) => state.setManualReview)
    const [addedPID, setAddedPID] = useState('')
    const [error, setError] = useState(false)
    // console.log('rerendered muniselect')
    const navigate = useNavigate();
    const location = useLocation();
    // console.log(location.search)
    const queryParams = new URLSearchParams(location.search);
    const urlString = location.pathname+location.search
    const [loading, setLoading] = useState(false);
    // const MuniCode = queryParams.get('MuniCode') || 'All';
    // const comp = parseInt(queryParams.get('comp'));
    const [muniOptions, setMuniOptions] = useState([{value:'All', label:'All'}])
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const repIDList = ['',1,2]
    const repID = queryParams.get('Rep') || '';
    const [villageOptions, setVillageOptions] = useState([{value:'All', label:'All'}])
    const [countyOptions, setCountyOptions] = useState([{ value: "All", label: "All", selectVal: 'All'},
      ...Object.keys(nyDataObj).map(key => ({
          label: key,
          value: nyDataObj[key].selectVal
      }))])

    useEffect(()=>{ // update the available counties when courtdate is updated.
      if (!courtDate || !county) {
        console.log('its setting default, could just return nothing here.')
        return
      }
      if(courtDate !== ''){
        // Set a loading widget with value for spinner for the county dropdown.

        console.log('loading the counties for the court date:')
        console.log(courtDate)
        loadCountyOptions(courtDate);
      }
    }, [courtDate]);

    const [courtMunis, setCourtMunis] = useState([]);

    useEffect(() => {
      // Update the available municipalities when the county is updated.
      if (courtDate !== '' && county !== '' && county !== 'All' && county !== 'select') {
        console.log('Loading the munis for the court date:');
        setLoading()
        console.log(courtDate);
        loadMuniOptions();
      } else { // set the default muni options if no court date.
        getMunicipalityOptions({
          setMuniOptions,
          nyDataObj,
          county,
          courtMuni: [],
          taxYear,
          navigate,
          countyOptions,
        });
      }
      console.log(county);
    }, [county]);
  
    async function loadMuniOptions() {
      console.log(courtDate);
      const courtMunis = await loadCourtMunis(courtDate);
      setCourtMunis(courtMunis);  // Store the fetched courtMunis in state
      getMunicipalityOptions({
        setMuniOptions,
        nyDataObj,
        county,
        courtMuni: courtMunis,
        taxYear,
        navigate,
        countyOptions,
      });
    }
  
    async function loadCountyOptions(courtDate) {
      const courtMunis = await loadCourtMunis(courtDate);
      setCourtMunis(courtMunis);  // Store the fetched courtMunis in state
      getCountyOptions({
        setMuniOptions,
        nyDataObj,
        county,
        courtMuni: courtMunis,
        taxYear,
        navigate,
        setCountyOptions,
      });
    }
  
    const loadCourtMunis = useCallback(async (courtDate) => {
      console.log('Running the load court munis function');
      const response = await axiosInstance.post(`/load_court_munis`, { CourtDate: courtDate });
      console.log(response.data);
      return response.data;
    }, [courtDate]);  // Add dependencies here if necessary
  
    // Memoized function to update municipality options
    const updateMuni = useCallback(({ updateField, updateValue, updateString = '', urlString }) => {
      updateMuniObj({
        updateField,
        updateValue,
        updateString,
        navigate,
        urlString,
        nyDataObj,
        county,
        taxYear,
        setCountyOptions,
        courtMuni: courtMunis,  // Pass courtMunis from state
      });
    }, [navigate, nyDataObj, county, taxYear, courtMunis]);

  // Combine the useeffect function of updatecourtmunioptions into a usememo that sets the munioptions.

  useEffect(()=>{
    console.log('running the get village useeffect (do this in the function for muniupdate')
    if(courtDate===''){
    getVillageOptions(setVillageOptions, nyDataObj, municipality, county)
    }
  },[municipality])
  
    return(
        <div className='justify-center m-auto  content-center bg-white p-8'>
          <div className=" m-auto justify-center content-center ">
            
            {/* Main dropdowns element */}
            <div className='flex m-auto gap-4 justify-center items-end min-w-[190px]'>
              {/* County dropdown, always visible. */}
                <div className='countyDropdown'>
                    <NewDropdown
                          value={county}
                          label="County"
                          dropdownOptions={countyOptions.map(option => ({
                              value: option.value,
                              label: option.label
                          }))}
                          updateFunction={updateMuni}
                          updateFunctionArgs={{
                              updateField: 'county',
                              updateString: '', // assuming `option.label` is not needed
                              navigate,
                              urlString,
                              nyDataObj,
                              county,
                              TaxYear: taxYear,
                              setCountyOptions,
                          }}
                          disabled={countyOptions.length > 1 ? false : true}
                      />
                      {courtDate !== '' && county ==='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a County</span>
                      : null}
                </div>

              {/* Municipality dropdown, conditionally rendered. */}
              <div className='relative flex-col items-center'>
                    <NewDropdown
                        value={municipality}
                        label="Municipality"
                        dropdownOptions={muniOptions.map(option => ({
                            value: option.value,
                            label: option.selectVal,
                            county: option.county,   // Passing additional fields for updateFunction
                            TaxYear: option.TaxYear  // Passing additional fields for updateFunction
                        }))}
                        updateFunction={(...args) => {
                          // console.log('Arguments passed to updateMuni:', args);
                          updateMuni(...args);
                      }}
                        updateFunctionArgs={{
                            updateField: 'municipality',
                            updateString: '', // assuming `option.label` is not needed
                            urlString,
                            nyDataObj,
                            county,
                            navigate,
                            setCountyOptions,
                        }}
                        disabled={county === 'All' && courtDate=== '' || county==='select'}
                    />
                    {error ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>{error}</span>
                    : courtDate !== '' && municipality === 'select' && county !=='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a Municipality</span>
                      : null}
              </div>

              {/* Village */}
              <NewDropdown
                    value={village}
                    label="Village"
                    dropdownOptions={villageOptions.map(option => ({
                        value: option.value,
                        label: option.selectVal
                    }))}
                    updateFunction={updateMuni}
                    updateFunctionArgs={{
                        updateField: 'village',
                        updateString: '', // assuming `option.label` is not needed
                        urlString,
                        nyDataObj,
                        navigate,
                        county: null,  // You can pass actual county if available
                        TaxYear: null,  // You can pass actual TaxYear if available,
                        setCountyOptions,
                    }}
                    disabled={(municipality === 'All' && villageOptions[0] === 'All') || villageOptions.length > 1 ? false : true}
                />

              {/* Taxyear drop */}
              <NewDropdown
                  value={taxYear}
                    label="Tax Year"
                    dropdownOptions={yearDropdownOptions}
                    updateFunction={updateMuni}
                    updateFunctionArgs={{
                      updateField: 'TaxYear',
                      updateString: '',
                      navigate: useNavigate(),
                      urlString,
                      nyDataObj,
                      county: null,
                      TaxYear: taxYear,
                      setCountyOptions,
                  }}
                />
            </div>

      <div className='justify-center m-auto text-center content-center'>
        <span>
          OR: 
        </span>
        {/* Court date dropdown */}
        <div className='flex justify-center'>
          <div className='flex w-fit min- mt-2'>
                <NewDropdown
                    value={courtDate}
                    width={125}
                    label="Court Date"
                    dropdownOptions={
                        upcomingCourtDates.length < 1
                            ? []
                            : upcomingCourtDates.map(value => ({
                                  value: value,
                                  label: value,
                              }))
                    }
                    updateFunction={(...args) => {
                        updateMuni(...args);
                    }}
                    updateFunctionArgs={{
                        updateField: 'CourtDate',
                        updateString: '',
                        urlString,
                        nyDataObj,
                        navigate,
                        setCountyOptions: setCountyOptions,
                    }}
                />
          </div>
        </div>
      </div>
      
          <div className=' mt-6 border-t text-center min-w-[210px]'>
            <div className='my-1 text-sm flex items-center justify-center'>
              <div className="mr-0.5">Additional Options:</div>
              <button className="float items-center justify-center content-center" onClick={() => {
                setDisplayOptions(!displayOptions);
                // setDefaultNumCases(null);
                // setShowSettledCases(false);
              }}>
                {displayOptions?
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-x"><rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
                :
                // Up arrow:
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-arrow-down"><rect width="18" height="18" x="3" y="3" rx="2"/><path d="M12 8v8"/><path d="m8 12 4 4 4-4"/></svg>
                }
              </button>
            </div>

            {displayOptions?
            <div className='flex justify-center'>
            <div className='m-auto content-center justify-center'>

              {/* Settled cases */}
            <div className='my-1 flex justify-center m-auto content-center'>
            <FormControl className='flex w-fit min-w-[115px] justify-end' size='small' fullWidth>
                <InputLabel id="demo-simple-select-label">Show settled:</InputLabel>
                <Select
                    value={getShowSettledCases} // set a default value for manualReview.
                    className='w-fit min-w-[115px]'
                >
                    {[true, false].map((value, index) => (
                        <MenuItem
                            key={index}
                            value={value.toString()} // Convert boolean to string to match the state type
                            onClick={()=>setShowSettledCases(value)}
                            style={{ minHeight: '30px' }} // Set your desired minimum height here
                        >
                            {value.toString()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

            {/* DROPDOWN FOR MANUAL REVIEW */}
            <div className='my-3 flex justify-center m-auto content-center'>
            <FormControl className='flex w-fit min-w-[115px] justify-end' size='small' fullWidth>
                <InputLabel id="demo-simple-select-label">Manual Review:</InputLabel>
                <Select
                    value={getManualReview} // set a default value for manualReview.
                    className='w-fit min-w-[115px]'
                >
                    {[true, false].map((value, index) => (
                        <MenuItem
                            key={index}
                            value={value.toString()} // Convert boolean to string to match the state type
                            onClick={()=>setManualReview(value)}
                            style={{ minHeight: '30px' }} // Set your desired minimum height here
                        >
                            {value.toString()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

            {/* Rep ID */}
            <div className='my-1 flex justify-center m-auto content-center'>
            <NewDropdown
                value={repID}
                label="RepID"
                dropdownOptions={repIDList.map(id => ({
                    value: id,
                    label: id === null ? 'None' : id,
                }))}
                updateFunction={updateMuni}
                updateFunctionArgs={{
                    updateField: 'Rep',
                    updateString: '',
                    urlString,
                    nyDataObj,
                    navigate,
                }}
            />
        </div>


            <div className={`flex max-h-[30px] mt-3 justify-end`}>
              <span className={`font-bold pr-2 `}>
                Run "x" cases:
              </span>
                <fieldset className=" dark:text-gray-100 ">
                  <div className="flex">
                    <CurrencyInput type="text"
                      name="settlement"
                      // prefix=""
                      id="settlement"
                      placeholder="ex: 50"
                      className={`flex flex-1 text-center max-w-[80px] text-black sm:text-sm rounded-md focus:ri p-1 border`}
                      onChange={(e) => setDefaultNumCases(e.target.value)}
                      autoComplete="off"
                      // Disable your input for settlement when it is settled. (should also gray it out more)
                      value={
                        getDefaultNumCases ?
                        getDefaultNumCases
                        :
                        ''
                      }
                    />
                  </div>
                </fieldset>
            </div>

            <div className='mt-1'>
              <span className='text-xs font-bold'>
              Run one Comp:
              </span>
            <fieldset className=" dark:text-gray-100">
                <div className="flex">
                  <input type="text"
                    id="PID"
                    placeholder="Parcel ID"
                    className="flex flex-1 p-1 text-xs text-black text-center border w-[240px] mx-1 rounded-md focus:ri dark:border-gray-700 dark:text-gray-100 dark:bg-gray-800"
                    onChange={(e) => setAddedPID(e.target.value)}
                    value={addedPID || ''}
                  />
                    </div>
              </fieldset>
              </div>
          </div>
          </div>
            :null}
        </div>
      </div>

          <div className='flex items-center '>
            <button
              className='rounded-sm border items-center hover:bg-meta-5 border-stroke bg-primary text-white py-2 px-2 max-w-[200px] dark:text-white m-auto mt-4 shadow-default dark:border-strokedark'
              onClick={() => {
                // If you have a courtdate but no muni, display error
                if(courtDate!=='' && municipality === 'All'){
                  setError('Select a Municipality')
                }
                fetchComps(addedPID);
              }}
            >
              Generate Comparables
            </button>
          </div>
        </div>

    )
}