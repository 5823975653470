
const ErrorTooltip = ({ message, onClose }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-danger text-white p-4 rounded-lg shadow-md">
          <p className="max-w-[400px]">{message}</p>
          <button
            onClick={onClose}
            className="mt-2 bg-white text-danger font-semibold px-4 py-2 rounded-full hover:bg-warning"
          >
            Close
          </button>
        </div>
      </div>
    );
  };
  
  export default ErrorTooltip;