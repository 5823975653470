import MunicipalCard from "./MunicipalCard";
import React, { useEffect, useState } from "react";

function MuniAnalysis({analysisObj, nyDataObj, detailedView, selectedCounty, isAdmin, setShowZipCodes, showZipCodes}){
    const [sortedMuniData, setSortedMuniData] = useState([])

    const selectedCountyData = analysisObj.county.data.county_data.find(countyData => countyData.county === nyDataObj[selectedCounty].selectVal);
    const selectedCountyYoY = analysisObj.county.yoy_changes.county_data.find(countyData => countyData.county === nyDataObj[selectedCounty].selectVal);
    console.log(analysisObj)
    // Useeffect and set state for sortedmunidata (changing w/ detailedView)
    useEffect(() => {
      console.log(detailedView)
      let sortedMuniDataObj;
    
      // Check if selectedCountyData is defined before accessing its properties
      if (selectedCountyData && selectedCountyData.municipal_data) {
        if (!detailedView) {
          console.log('set to regular')
          sortedMuniDataObj = selectedCountyData.municipal_data.regular.slice().sort((a, b) => (b.avg_fee * (b.invoice_count / b.retainers_count) - a.avg_fee * (a.invoice_count / a.retainers_count)));
        } else {
          console.log('setting to detailed')
          sortedMuniDataObj = selectedCountyData.municipal_data.detailed.slice().sort((a, b) => (b.avg_fee * (b.invoice_count / b.retainers_count) - a.avg_fee * (a.invoice_count / a.retainers_count)));
        }
      } else {
        // Handle the case when selectedCountyData is undefined
        sortedMuniDataObj = [];
        // You can also add additional error handling logic or display a message to the user
        console.error("selectedCountyData is undefined");
      }
    console.log(sortedMuniDataObj)

    
      setSortedMuniData(sortedMuniDataObj);
    }, [selectedCountyData, detailedView]);

    
    return(
        <div className="grid grid-cols-3"> 
        {/* Non village municard - don't display unless not detailed. */}
            {!detailedView?
            sortedMuniData.map((muni) => {
              let muniName;
              let yoyChanges;
          
              for (const key of Object.keys(nyDataObj[selectedCounty].municipalities)) {
                  const objMuniVal = nyDataObj[selectedCounty].municipalities[key].selectVal;
                  if ((muni.municipality.substring(1, 3)) === objMuniVal) {
                      // Store the result in yoyChanges
                      muniName = key + ` (${objMuniVal})`;
                      break;
                  } else {
                      // if you cannot find the match, set it to the number name.
                      muniName = muni.municipality;
                  }
              }
          
              return (
                  <div key={muni.municipality}>
                      <MunicipalCard municipalityData={muni} yoyChanges={selectedCountyData} analysisObj={analysisObj} muniName={muniName} isAdmin={isAdmin}></MunicipalCard>
                  </div>
              );
            })
                :
                sortedMuniData.map((muni) => {
                  let villageName;
                  let selfAss = false;
                
                  // Iterate over the municipalities
                  for (const key of Object.keys(nyDataObj[selectedCounty].municipalities)) {
                    // Check if villages exist for the current municipality
                    if (nyDataObj[selectedCounty].municipalities[key].villages) {
                      // Iterate over the villages
                      for (const village of Object.keys(nyDataObj[selectedCounty].municipalities[key].villages)) {
                        const objMuniVal = nyDataObj[selectedCounty].municipalities[key].villages[village].selectVal;
                        // Check if the substring of muni.municipality matches objMuniVal
                        if (muni.municipality.substring(1, 5) === objMuniVal) {
                          console.log(village);
                          villageName = `${village} ${objMuniVal}`;
                          selfAss = true
                          break;
                        }
                      }
                    }
                  }
                
                  // If villageName is still undefined, set it to muni.municipality
                  if (!villageName) {
                    villageName = muni.municipality;
                  }
                
                  return (
                  <div key={villageName}>
                    <div className="my-2 p-2 mx-2 border m-auto text-center">
                    <div className="flex text-center justify-center">
                    {/* <div className="font-bold text-lg">Muni: </div> */}
                    <div className={`ml-4 font-bold text-lg ${selfAss ? 'text-[#dd6b20]' : ''}`}>{villageName}</div>
                    </div>

                    {isAdmin?
                      <div className="grid grid-cols-2">
                      <div className="text-left">Billing:</div>
                      <div className="text-left ml-4">{muni.total_amount.toLocaleString()}</div>
                      </div>
                      :
                      null}

                      <div className="grid grid-cols-2">
                      <div className="text-left">Client #:</div>
                      <div className="text-left ml-4">{muni.retainers_count.toLocaleString()}</div>
                      </div>

                      <div className="grid grid-cols-2">
                      <div className="text-left">Invoiced #:</div>
                      <div className="text-left ml-4">{muni.invoice_count.toLocaleString()}</div>
                      </div>

                      {isAdmin?
                      <div className="grid grid-cols-2">
                      <div className="text-left">Avg Invoice:</div>
                      <div className="text-left ml-4">{Math.round(muni.avg_fee).toLocaleString()}</div>
                      </div>
                      :
                      null}

                      <div className={`grid grid-cols-2 ${isAdmin ? '' : 'font-bold'}`}>
                      <div className="text-left">Win Rate:</div>
                      <div className="text-left ml-4">
                        {((muni.invoice_count / muni.retainers_count)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
                      </div>
                      </div>

                      {isAdmin?
                      <div className="grid grid-cols-2">
                      <div className="font-bold text-left">REV/CLIENT:</div>
                      <div className="font-bold text-left ml-4">
                        {Math.round(muni.avg_fee * (muni.invoice_count / muni.retainers_count)).toLocaleString()}
                      </div>
                    </div>
                    :null}
                </div>
              </div>
              )
            })
            }
        </div>
    )
} export default MuniAnalysis