import { useCompStore, useAdminSettingsStore, usePersistedDataStore, useLocalDataStore } from './store';
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import ErrorTooltip from './ErrorTooltip';
import _, { truncate, isUndefined } from 'lodash';
import { toast } from 'react-toastify';
import SubjectTableRow from './SubjectTableRow';
import DropdownStyle from './DropdownStyle';
import useUpdateInventory from '../common/updateInventory.js';
import DBConfirmModal from '../common/DBConfirmModal.js';
import axiosInstance from '../axiosConfig.js'
import AdditionalCompsRow from './AdditionalCompsRow.js'
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import useReviewedComps from '../common/helper_functions.js'; // Adjust the path as necessary

function AdditionalComps({ compList, filter, sortColumn, setLoading }) {
  let caseMode = 'Tax' // set this to muni if you want to show red BG

  const [error, setError] = useState(null);
  const getCourtComps = usePersistedDataStore((state) => state.courtComps)
  const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const getFetchLoad = usePersistedDataStore((state) => state.fetchLoad);
  const getSalesCompReport = useAdminSettingsStore((state) => state.salesCompReport);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const getOriginalCompList = useCompStore((state) => state.originalCompList);
  const setOriginalCompList = useCompStore((state) => state.setOriginalCompList);
  const getOriginalOptimComps = useCompStore((state) => state.originalOptimComps);
  const setOriginalOptimComps = useCompStore((state) => state.setOriginalOptimComps);
  const getSortOrder = useCompStore((state) => state.sortOrder);
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const setCourtComps = usePersistedDataStore((state) => state.setCourtComps);
  // const [getCourtComps, setCourtComps] = useState([])
  const [editIndex, setEditIndex] = useState(null);
  const [editValues, setEditValues] = useState({});
  // const [inventoryUpdates, setInventoryUpdates] = useState({}); // update this so that it isn't a local state
  const inputRef = useRef(null);
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear;
  const view = queryParams.get('view') || 'regular';
  const updateInventory = useUpdateInventory();

  // const { confirmModal, setConfirmModal, inventoryUpdates, setInventoryUpdates } = useModalContext();

  // console.log(getCourtComps)

  useEffect(() => {
    if (getSortOrder === 'asc' && view === 'standard') {
      const newCompList = _.cloneDeep(compList);
      if (!_.isEqual(compList, newCompList)) {
        if(view==='standard'){
          setOriginalCompList(newCompList);
        }else if (view==='optimized'){
          setOriginalOptimComps(newCompList);
        }
      }
      // calculateAvgPrice(newCompList[comp]);
    } // calculateAvgPrice(newOptimComps[comp]);
  }, [comp, getSortOrder, view, compList]);
    // console.log(compList)
    const getReviewedComps = useReviewedComps(comp, getCourtComps, taxYear, getCompSheet, setCourtComps);

    useEffect(() => { 
      console.log('running get court comp useeffect')
      // This does NOT work properly with things that are not in regular view. Add logic for that.
      // Is a combination of splicing reviewedCompInventory (w/ proper index) into your final obj
      let comps = [{}];
      if (getNegotiationObj.MuniCode && getNegotiationObj.cases.length > 0) { // don't get comps if we haven't run fetch yet
        const hasComps = getCourtComps[comp] && Object.keys(getCourtComps[comp]).length > 0;
        const fetchData = async () => { // This has bad runtime because we force await?
          try {
            if (getNegotiationObj?.cases[comp]?.Comps) {
              // this means that you HAVE comps (in terms of the saved PIDs)
              comps = await getReviewedComps(getNegotiationObj.cases[comp]); // get data for your comps
            }else{
              // No comps at all, may need a call reviewed with an empty object to prevent error
              // calculateAvgPrice(); // Call calculateAvgPrice after fetching
            }
          } catch (error) {
            console.error('Error fetching additional comps:', error);
          }
        };

        if(hasComps){ // don't re-fetch them if comps already have been loaded in.
          return;
        }else{
        fetchData(); // get reviewed comp information.
        }
        console.log(getCourtComps)
      }
    }, [comp]); // idk if u have to ccall this twice


    useEffect(() => {
      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleEnterKeyPress();
        }
      };

      const compsTable = document.getElementById('compsTable');
      if(compsTable){
        compsTable.addEventListener('keydown', handleKeyPress);
      }
      
      return () => {
        if(compsTable){
          compsTable.removeEventListener('keydown', handleKeyPress);
        }
      };
    }, [editValues]); // removed editValues from dependencies
    
    
    if (!compList || compList.length === 0) {
      return (
        <div>
          {/* Render Nothing when complist empty */}
          {/* <p>No data available</p> */}
        </div>
      );
    }
    const handleCopy = (text, type) => {
      navigator.clipboard.writeText(text).then(() => {
        toast.success(`Copied ${type} to clipboard!`, {
          position: 'top-left',
          className: 'mt-10',
          autoClose: 3000,
      });
          // alert(`Copied ${type} to clipboard!`);
      }).catch(err => {
          console.error('Failed to copy: ', err);
      });
  };

    const handleError = (message) => {
      setError(message);
    }
    const closeError = () => {
      setError(null);
      setEditValues('') // reset your editing object if you have an error.
    }

    function hasDuplicates(array) {
      return (new Set(array)).size !== array.length;
    }

      const handleEnterKeyPress = () => { // modify this such that it has two if statements, one that clones deep for other state, one that 
        // you have to go into the compList[comp] object, and change the order of EVERY key->value pair?
        console.log(compList)
         
          const updatedCompSheet = _.cloneDeep(compList);
          let updatingCompSheet = updatedCompSheet
          // if(view=='court'){
          //   updatingCompSheet = updatedCompSheet
          // }
          
          const newPositions = Object.entries(editValues).map(([pid, newPosition]) => ({
            pid,
            newPosition: parseInt(newPosition), // Ensure it's parsed as an integer
          }));
          newPositions.sort((a, b) => a.newPosition - b.newPosition);          
        console.log(editValues)
          // Move the property to new index
          console.log(updatingCompSheet)
          Object.entries(newPositions).forEach(([pid, newValueObj]) => { // Loop over the array of comps you just re-ordered
            // do this error handling if it is standard view, otherwise nah
            console.log(newValueObj)

            if ( // error handling to display a tooltip if input is not 1-5 value
                  editValues[newValueObj.pid] !== null &&
                  editValues[newValueObj.pid] !== 0 &&
                  editValues[newValueObj.pid] !== '' &&
                  !hasDuplicates(Object.values(editValues)) // prevents duplicate entries
                )
                {
                  const originalIndex = updatingCompSheet.parcel_id.findIndex(
                    (row) => { // returns the original index from your updated compsheet clone.
                      return String(row) === String(newValueObj.pid);
                    } // REMINDER - 0 is equal to subject. so 1 means first position in your rendered array.
                  );
                  // console.log(`OG Index:${originalIndex}: target: ${newValueObj.newPosition}`) // log the pid and target.
                  Object.entries(updatingCompSheet).forEach(([key, value]) => {
                    // if (Array.isArray(value)) { // it may make sense to put this back in as its protects the code (but I'd rather see the error if cases come up atm so commented out)
                    if(key==='price_adjustments'){
                      // Now you need to loop over the 
                      if(view!=='court'){
                      Object.entries(updatingCompSheet[key]).forEach(([key2,value]) => {
                        // Truthfully, I think this entire adjustments array is completley unnecessary given our current codebase, but wanted to re-order it just in case.
                        const movedVal = updatingCompSheet[key][key2][originalIndex];
                        updatingCompSheet[key][key2].splice(originalIndex, 1);
                        updatingCompSheet[key][key2].splice(newValueObj.newPosition, 0, movedVal);
                      })
                    }}else if(key!=='Regular_midpoint' && key!== 'Regular_straight_average' && key!=='Optimized_midpoint' && key!=='Optimized_straight_average' && key!=='minimum_optimized_value'){ // every case except for price adjustments.
                      const movedVal = updatingCompSheet[key][originalIndex];
                      updatingCompSheet[key].splice(originalIndex, 1);
                      updatingCompSheet[key].splice(newValueObj.newPosition, 0, movedVal);
                    }
                  }
                  );
          }
          else{handleError('Error in comp entry: Do not enter numbers outside 1-5, do not leave any numbers blank, and ensure you did not enter any duplicates.')}
          });
          // console.log(updatedCompSheet[comp])

          if(view==='optimized'){
            const updatedCompSheetArray = _.cloneDeep(getOptimizedComps);
            updatedCompSheetArray[comp] = updatingCompSheet
            setOptimizedComps(updatedCompSheetArray)
          }else if(view==='regular'){
            const updatedCompSheetArray = _.cloneDeep(getCompSheet);
            updatedCompSheetArray[comp] = updatingCompSheet
            setCompSheet(updatedCompSheetArray);
          }else{
            const updatedCompSheetArray = _.cloneDeep(getCourtComps);
            updatedCompSheetArray[comp] = updatingCompSheet
            setCourtComps(updatedCompSheetArray)
          }
          setEditValues({})
          setEditIndex(null);

        if (inputRef.current) { // idk if you actually need this if statement, probably always want to clear?.
          inputRef.current.blur();
        }
      };

    // down sort arrow

    const renderSortArrow = (column) => {
      if (sortColumn === column) {
        return (
          <div className='mt-0.5'>
            {getSortOrder === 'desc' ? ( // if 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
            ) : 
            getSortOrder==='standard'? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
              </svg>
            ) :
            null
            }
          </div>
        );
      }
    };

return( // need to replace "compList" with intermediary results
<div className='w-auto mx-auto' id='compsTable'>
  {/* <div className="flex justify-center mb-2 overflow-x-hidden font-bold underline"></div> */}

  {/* need to change the way the confirm modal is rendered instead of popping it up from this location */}
  {/* And update your process of rendering this for the comps (probably abstract into individual comp components.) */}
  {/* {confirmModal?
      <DBConfirmModal
      confirmModal={confirmModal}
      setConfirmModal={setConfirmModal}
      compList={compList}
      setLoading={setLoading}
      index={inventoryUpdates.Index}
      />:null} */}
  {/* Table div starts here: */}
  <div className="table-container overflow-auto w-auto ">
  {error && <ErrorTooltip message={error} onClose={closeError} />}
  {/* ERROR POPUP. */}
            <table className="w-full">
            <thead className="bg-meta-2 dark:bg-boxdark w-full sticky top-0 z-[10] text-base dark:text-white lg:text-base">
              <SubjectTableRow compList={compList} setLoading={setLoading} />
                {/* This is your actual table header, you want this to be fixed. */}

              {/* Sticky border class in here adds p-y border (top and bottom). */}
                  <tr className='' >
                    <th className="dark:border-gray-400 w-[40px] sticky-border">#</th>
                    <th className="py-2 text-left dark:border-gray-400 sticky-border">Address</th>
                    <th className="text-center dark:border-gray-400 sticky-border">
                        <div className="flex flex-col">
                        <button onClick={()=>{filter(compList,view==='regular' ? getOriginalCompList : getOriginalOptimComps,'PCC',view);}} className='flex flex-col items-center justify-center h-full m-auto'>
                            <span className="text-sm">PCC</span>
                            <span className="text-sm">SD</span>
                      {renderSortArrow('PCC')}
                      </button>
                        </div>
                    </th>
                
                    <th className="py-2 dark:border-gray-400 max-w-[120px] sticky-border">
                    {/* <button onClick={()=>{filter(compList,view==='standard' ? getOriginalCompList : getOriginalOptimComps,'Style',view);}} className='flex items-center justify-center h-full m-auto'> */}
                    Style
                      {/* {renderSortArrow('Style')} */}
                      {/* </button> */}
                      </th>

                    <th className="py-2 dark:border-gray-400 sticky-border">
                    <button onClick={()=>{filter(compList,view==='regular' ? getOriginalCompList : getOriginalOptimComps,'YearBuilt',view);}} className='flex items-center justify-center h-full m-auto'>
                      Yr
                      {renderSortArrow('YearBuilt')}
                      </button>
                    </th>

                    <th className="py-1 dark:border-gray-400 sticky-border">
                    <button onClick={()=>{filter(compList,view==='regular' ? getOriginalCompList : getOriginalOptimComps,'Sqft',view);}} className='flex items-center justify-center h-full m-auto'>
                        SQFT
                      {renderSortArrow('Sqft')}
                      </button>
                      </th>

                    <th className="py-2 dark:border-gray-400 sticky-border">
                    <button onClick={()=>{filter(compList,view==='regular' ? getOriginalCompList : getOriginalOptimComps,'Acres',view);}} className='flex items-center justify-center h-full m-auto'>
                        Acres
                      {renderSortArrow('Acres')}
                      </button>
                    </th>

                    <th className="py-2 dark:border-gray-400 sticky-border">BR</th>
                    
                    <th className="py-2 dark:border-gray-400 sticky-border">
                    <button onClick={()=>{filter(compList,view==='regular' ? getOriginalCompList : getOriginalOptimComps,'IFMV',view);}} className='flex items-center justify-center h-full m-auto'>
                        IFMV
                      {renderSortArrow('IFMV')}
                      </button>
                      </th>

                    <th className="py-2 dark:border-gray-400 sticky-border">
                    <button onClick={()=>{filter(compList,view==='regular' ? getOriginalCompList : getOriginalOptimComps,'SalePrice',view);}} className='flex items-center justify-center h-full m-auto'>
                        Sale Price
                      {renderSortArrow('SalePrice')}
                      </button>
                      </th>
                    
                    {/* Ability to sort by adjusted sale px */}
                    <th className="py-2 dark:border-gray-400 sticky-border">
                    <button onClick={()=>{filter(compList,view==='regular' ? getOriginalCompList : getOriginalOptimComps,'adj_price',view);}} className='flex items-center justify-center h-full m-auto'>
                        Adj Sale
                      {renderSortArrow('adj_price')}
                      </button>
                      </th>
                    
                    <th className='dark:border-gray-400 sticky-border'> Time adj </th>

                    <th className="items-center justify-center h-full cursor-pointer dark:border-gray-400 sticky-border">
                      <button onClick={() => { filter(compList, view === 'regular' ? getOriginalCompList : getOriginalOptimComps, 'Distance', view); }} className='flex items-center justify-center h-full m-auto'>
                        Dist
                        {renderSortArrow('Distance')}
                      </button>
                    </th>
                  </tr>
                </thead>
      <tbody className={getSalesCompReport?'dark:text-white overflow-auto':'text-gray-200 dark:text-black overflow-auto'}>
          {/* // THIS RETURN IS FOR WHEN YOU are in table view, NOT the compmsheet. */}
        {/* {getSalesCompReport? // if true you are doing the tabular view.
          rows.slice(6)
        : */}
          {compList[[Object.keys(compList)[0]]].length?
          // compList[[Object.keys(compList)[0]]]
            compList[[Object.keys(compList)[0]]].map((value,index)=>
            {
              if(index===0){ // do not render the subject (left this dumb code b/c we still want the correct index for the rest of these.)
                // could change table indexing to +1 if you wanna
                return
              }else{ // render the rest of the rows.
            return(
              <AdditionalCompsRow
              index={index}
              key={index+view}
              compList={compList}
              caseMode={caseMode}
              editValues={editValues}
              setEditValues={setEditValues}
              setEditIndex={setEditIndex}
              editIndex={editIndex}
              handleCopy={handleCopy}
              updateInventory={updateInventory}
              setLoading={setLoading}
              >
              </AdditionalCompsRow>
            )
          }
            })
            :
            null}
        {/* } */}
        {/* This last TR because bottom row been getting cut off. */}
        <tr className='h-[30px]'></tr>
      </tbody>
  </table>
  </div>
</div>
)}

// const areEqual = (prevProps, nextProps) => { // this is working to prevent re-renders of this it seems. May be fine with just memoization
//   console.log( _.isEqual(prevProps.compList, nextProps.compList))
//   return _.isEqual(prevProps.compList, nextProps.compList) &&
//          prevProps.view === nextProps.view &&
//          prevProps.filter === nextProps.filter &&
//          prevProps.sortColumn === nextProps.sortColumn &&
//          prevProps.setLoading === nextProps.setLoading;
// };

// , areEqual appended to after adtl comps.

AdditionalComps.whyDidYouRender = true;
export default React.memo(AdditionalComps);