import { useEffect } from "react";
import {useAdminSettingsStore} from './store';
import demoPath from '../images/demo/Demo.mp4'
function Placeholder({demoPage}){
const apiKey = process.env.REACT_APP_FORM_API_KEY; // put this top level if it works?


	// Form handler:
	useEffect(()=>{

	},[])

	function submitForm(e){
		e.preventDefault();
		const form = document.getElementById('form');
		const result = document.getElementById('result');
		const formData = new FormData(form);
		const topText = document.getElementById('submitText')
		const button = document.getElementById('submitBut')

		var object = {};
		formData.forEach((value, key) => {
			object[key] = value
		});
		var json = JSON.stringify(object);
		button.innerHTML = "Please wait..."
		fetch('https://api.web3forms.com/submit', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				body: json
			})
			.then(async (response) => {
				let json = await response.json();
				if (response.status === 200) {
					// Reduce the top margin
					form.classList.toggle('hidden')
					topText.classList.add('hidden')
					result.classList.remove('hidden')
					// Maybe break out fantastic to be bold?
					result.innerHTML = 'Our team will be in touch, and as soon as our beta goes live you will be the first to know!'
					// result.classList.add('font-bold')
				} 
				else {
					console.log(response);
					result.classList.remove('hidden')
					result.innerHTML = json.message;
					result.classList.add('text-danger');
				}
			})
			.catch(error => {
				console.log(error);
				result.classList.remove('hidden')
				result.innerHTML = "Something went wrong!";
			})
			.then(function() {
				form.reset();
				// if you want to include something after a period of time, can throw this funct back in.
				// setTimeout(() => {
				// 	result.innerHTML = "Go Back"; 
				// 	result.classList.add('text-white');
				// }, 5000);
			});
	}


    // Dark mode demo if in dark mode
    // Light mode demo if in light mode
    // Disable the menu option
    // Disable search
    // Provide a tooltip for 'hold your horses!'

	// create a top level state variable (in storage)
	// if route === / then set it to true, use this to disable all the stuff you want to.


	// Hamburger menu onclick == disabled when at / route.
	// Onclick set the tooltip "hold your horses!"
	// 
    return(
		<section className="p-6 dark:bg-gray-800 dark:text-gray-100 h-full">
			<div className="container grid gap-6 mx-auto text-center lg:grid-cols-2 xl:grid-cols-5">
				<div className="w-full px-6 py-16 rounded-md sm:px-12 md:px-16 xl:col-span-2 bg-white dark:bg-gray-900">
					<span className="block mb-2 text-primary dark:text-violet-400 font-bold">New age property valuation</span>
					<h1 className="text-5xl font-extrabold dark:text-gray-50">Propriety</h1>
					
					<p className="my-8" id="submitText">
						<span>
						Precision property valuation. Built with ensemble Machine Learning models, enhanced by AI data validation.
						{/* Should this be re-written? jargon heavy. */}
						</span>
					</p>
					<div className="mt-8 hidden" id='result'>
						Fantastic.
					</div>
					<form noValidate="" action="https://api.web3forms.com/submit" method="POST" onSubmit={submitForm} className="self-stretch space-y-3" id='form'>
						{/* can turn autoComplete='off' if you want on form el above. */}
						<input type="hidden" name="access_key" value={apiKey}/>
						<div>
							<label htmlFor="name" className="text-sm sr-only">Your name</label>
							<input id="name" type="text" name="name" required placeholder="Your name" className="w-full rounded-md focus:ring focus:ri bg-meta-2 dark:border-gray-700 border border-white dark:bg-gray-800 p-2" />
						</div>
						<div>
							<label htmlFor="email" className="text-sm sr-only">Email address</label>
							<input id="email" type="email" name="email" placeholder="Email address" required className="w-full rounded-md focus:ring bg-meta-2 focus:ri dark:border-gray-700 border border-white dark:bg-gray-800 p-2" />
						</div>
						<button type="submit" className="w-full py-2 font-semibold rounded bg-primary dark:bg-violet-400 text-white dark:text-gray-900" id='submitBut'>Coming Soon...Join the waitlist!</button>
					</form>
				</div>

				{/* Image needs to be replaced with demo gif. */}
				{/* <img src="https://source.unsplash.com/random/480x360" alt="" className="object-cover w-full rounded-md xl:col-span-3 dark:bg-gray-500" /> */}
				<div className="object-cover w-full rounded-md xl:col-span-3">
					<video controls loop autoPlay muted className="object-cover w-full rounded-md xl:col-span-3">
						<source src={demoPath} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</section>
    )
} export default Placeholder