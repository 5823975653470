import React, { useState, useEffect, isValidElement } from 'react';
import { useCompStore, usePersistedDataStore } from './store';
import DownloadXLSButton from './DownloadXLSButton';
import { showModalAndWaitForSelection } from '../common/ShowModalAndWaitForSelection.js'; // you should be using this instead of ur manual jury rigged version in fetchcomps.
import axiosInstance from '../axiosConfig.js';

function ReviewSettlements({isAdmin}) {
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet)

  const [countState, setCountState] = useState(0);
  const [totalScarCases, setTotalScarCases] = useState(0)
  const [avgReduction, setAvgReduction] = useState(0);
  const [totalNC, setTotalNC] = useState(0);
  const [taxSavings, setTaxSavings] = useState(0)
  const [scarCases, setScarCases] = useState()
    let runningSetlement = 0; // local var to tally your settlement value.
    let runningIFMV = 0; // var to tally your IFMV's (as cases are settled to calc the % reduct)
    let runningNC = 0;
    // For the download
    const keys = ['PID', 'propertyAddress', 'propertyTown', 'propertyZip','Name', 'SCARIndexNumber','PreviousAssessTown', 'IFMVTown', 'RunDate', 'SalePrice', 'SaleDate', 'RecentSale', 'EstimatedTaxes', 'SubjectMarketValue', 'RAR', 'courtAverage', 'optimAvg', 'optimMidpoint', 'regularAvg', 'regularMidpoint', 'RequestedAssessment', 'RepID', 'CaseNotes'];
              // Get data to pull in the sale price and date, and include

  useEffect(() => { // you currently do not account for ones that did NOT get filed at scar. You're including them in scar stats which is wrong.
    calcSavings(); // Call the calculation function when the component mounts
    loadSales();
    splitScarCases()
  }, []);

  async function loadSales() {
    console.log(getNegotiationObj)
    const uids = getNegotiationObj.cases.map((item) => item.PID);

    const salesResponse = await axiosInstance.post('/get_sales_data', {
      TaxYear: getNegotiationObj.TaxYear,
      MuniCode: getNegotiationObj.MuniCode,
      uids: uids
    })

    const salesData = salesResponse.data;
    const updatedCases = getNegotiationObj.cases.map((caseItem) => {
      // Find the corresponding sales data for the caseItem
      const saleInfo = salesData.find((sale) => sale.pid === caseItem.PID);
      if (saleInfo) {
        // Update the caseItem with the sales data
        return {
          ...caseItem,
          SaleDate: saleInfo.saleDate,
          SalePrice: saleInfo.salePrice,
          RecentSale: saleInfo.recentSale
        };
      }
      // If no matching sales data, return the caseItem unchanged
      return caseItem;
    });

    setNegotiationObj({ ...getNegotiationObj, cases: updatedCases });
  }
  async function calcSavings() {
    setTotalScarCases(getNegotiationObj.cases.filter((item) => item.type !== null)); // to not count the ones which aren't at SCAR
  
    let missingValuesFlag = false; // Flag to track if any SubjectMarketValue was missing
  
    const countSettled = getNegotiationObj.cases.reduce((count, obj, index) => {
      if (obj && obj.type !== null) {
        const compSheetItem = getCompSheet[index];
        if (compSheetItem && compSheetItem.PropertyAssessment) { // Check if getCompSheet[index] is defined and has 'PropertyAssessment' property
          runningIFMV += parseInt(compSheetItem.PropertyAssessment[0]);
          runningSetlement += parseInt(obj.SCARDeterminationValue);
          if (obj.SCARDeterminationAction === 'W' || obj.SCARDeterminationAction === 'SD') {
            runningNC++;
          }
  
          // Check if SubjectMarketValue is missing
          // console.log(obj)
          if (obj.SubjectMarketValue === null || obj.SubjectMarketValue === '') {
            missingValuesFlag = true; // Set the flag if any SubjectMarketValue is null or empty
          }
        }
        if(obj.settled){ // only increment your count if case was settled
        return count + 1;
        }
      }
      return count;
    }, 0);
  
    setCountState(countSettled);
    setAvgReduction((runningSetlement - runningIFMV) / runningIFMV);
    setTaxSavings(runningIFMV * 0.02 * ((runningSetlement - runningIFMV) / runningIFMV));
    setTotalNC(runningNC);
  
    // Update negotiation Obj with the changes made above (to add in assessment plus RAR)
    const updateNegotiationObj = { ...getNegotiationObj };
    updateNegotiationObj.cases = updateNegotiationObj.cases.map((item, index) => {
      if (item && item.type !== null) {
        const compSheetItem = getCompSheet[index];
        if (compSheetItem && compSheetItem.PropertyAssessment) {
          let requestedAssessment = item.SubjectMarketValue;
  
          // Check if compSheetItem.IFMV[0] > 450000 and if RequestedAssessment needs to be bounded
          if (compSheetItem.IFMV[0] > 450000 && requestedAssessment < 0.75 * compSheetItem.PropertyAssessment[0]) {
            requestedAssessment = 0.75 * compSheetItem.PropertyAssessment[0];
          }
  
          return {
            ...item,
            PropertyAssessment: compSheetItem.PropertyAssessment[0],
            RAR: compSheetItem.RAR[0],
            RequestedAssessment: requestedAssessment
          };
        }
      }
      return item;
    });
  
    setNegotiationObj(updateNegotiationObj); // update your negotiation object with the new values
  
    // If any SubjectMarketValue was missing, handle the modal display here
    if (missingValuesFlag) {
      const userSelection = await showModalAndWaitForSelection(
        "You are missing subject market values",
        'Generate them in the admin portal, then re-run and proceed to download xls.',
        'ignore',
      );
      if (userSelection === 'ignore') {
        console.log('User chose to ignore missing values.');
        return; // Exit the function if user chooses to ignore
      }
    }
  }
  
  function splitScarCases(){
    const filteredCases = getNegotiationObj.cases.filter((caseItem) => caseItem.SCARFiled === 1);
    setScarCases(filteredCases)
    return filteredCases;
  }

  return (
    // On this page, if there's no settlements (or even if there are) - have an option to "save review" - this will save the comp order,
    // And save any min/max offers (and settlements)
    <div>
    <div className='relative rounded-sm border  border-stroke max-w-[475px] bg-white py-6 px-7.5 dark:text-white m-auto mt-12 shadow-default dark:border-strokedark dark:bg-boxdark'>
      <p>Number of Cases Settled: {countState} / {totalScarCases.length}</p>
                  {/* Change denominator to only count if getnegotiationobj type !== null. */}
      <p className='flex'>Avg red (settled cases): <span className='font-bold flex ml-1'> {avgReduction.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
      
      {isAdmin?
      <div>
        <p>Estimated tax savings (2% ETR): {Math.floor(taxSavings).toLocaleString()}</p>
        <p className='flex'>Our estimated fee: <span className='font-bold ml-1'>{Math.floor(Math.abs(taxSavings / 2)).toLocaleString()}</span></p>
      </div>
      :null  
      }
      <p>% win rate: {((countState-totalNC)/countState).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })} ({countState-totalNC} wins)</p>
      
      <p className='text-xs mt-2'>*These stastics only reflect SCAR</p>
    </div>

    {/* Temporary download */}
    <div className='flex mx-auto w-fit px-6 py-2 mt-4 font-semibold rounded bg-violet-400 text-gray-800'>
      <DownloadXLSButton jsonData={getNegotiationObj.cases} fileName={`${getNegotiationObj.MuniCode}_${getNegotiationObj.TaxYear}_${getNegotiationObj.courtDate}`} keys={keys} />
      </div>

  <div className='mx-auto mt-6 text-center'>
  Download SCAR cases only:
        <div className='flex mx-auto w-fit px-6 py-2 mt-1 font-semibold rounded bg-violet-400 text-gray-800'>        
          <DownloadXLSButton jsonData={scarCases} fileName={`${getNegotiationObj.MuniCode}_${getNegotiationObj.TaxYear}_${getNegotiationObj.courtDate}_SCAR_Only`} keys={keys} />
        </div>
  </div>

    </div>
  );
} export default ReviewSettlements
