import React, { useEffect, useState, useRef, useCallback } from 'react';
import axiosInstance from '../axiosConfig.js'
import { toast } from 'react-toastify';
import Plot from 'react-plotly.js';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useLocalDataStore, usePersistedDataStore, } from './store';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import _, {isEqual} from 'lodash';
import { Query } from '@syncfusion/ej2-data';
// import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { updateMuniObj, getMunicipalityOptions, getVillageOptions } from '../common/helper_functions';
import GraphParams from './GraphParams.js'
// import { GridFilterPanel } from '@mui/x-data-grid';
import GridFilterPanel from '@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel.js';
// TODO Change axis 1 to be X axis
// Axis 2 to be Y axis

function Visualizations({nyDataObj, setMicroLoad, microLoad, yearDropdownOptions}){
    // You should refactorr this, so that the state for the dropdowns is managed within each dropdown component.
    console.log('rerender viz')
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const [chartType, setChartType] = useState('');
    const [numGraphs, setNumGraphs] = useState(1)
    const elementRef = useRef(null); // Reference to the element
    // Local state for dropdown values
    const [xAxisVal, setxAxisVal] = useState('') // pass this down into each dropdown select
    const [yAxisVal, setyAxisVal] = useState('')
    const [zAxisVal, setzAxisVal] = useState('')
    const [programxAxisVal, setProgramxAxisVal] = useState('')
    const [programyAxisVal, setProgramyAxisVal] = useState('')
    const [programzAxisVal, setProgramzAxisVal] = useState('')
    const [lag, setLag] = useState(1)
    const [activeGraph, setActiveGraph] = useState(1)
    const [plotRenderedOnce, setPlotRenderedOnce] = useState(false)
    const [graphType, setGraphType] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [axesLoaded, setAxesLoaded] = useState(false)
    const [axisOptions, setAxisOptions] = useState([])
    const [groupValue, setGroupValue] = useState('No Groups') // default to ungrouped view (can change based on default?)
    const [plot, setPlot] = useState('');
    const groupOptions = ["No Groups", "Style", "NeighborhoodCode", "high_end", "Client", "Sold", "SaleYear"]
    const filterOptions = ["NeighborhoodCode", "Sales", "Style", "Sale Price", "Client", ]
    console.log(filterOptions)
    const [filterValue, setFilterValue] = useState('')
    const [graphError, setGraphError] = useState(null)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const MuniCode = queryParams.get('MuniCode') || 'All';
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
        // this getTaxyear nmay be the commonality theme rerendering everything

    const memoizedSetLag = useCallback((lag) => {
        setLag(lag);
    }, []);

    useEffect(() => {
        // Set the dropdown options for year based on the current date
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const options = []; // tax year dropdown options set based on what time of year you are in
        for (let i = currentYear; i >= currentYear - 4; i--) { // populate options based on curr date
          if (i === currentYear) {
            options.push((i + 1).toString());
            options.push((i).toString());
          } else {
            options.push((i).toString());
          }
        }
        setDropdownOptions(options);
      }, []);

      const fields = { text: "Country", value: "Index" };
      
    //   Update your graph opptions to be in this format, text = the key for value, index = s then index#
      // define the filtering data
      const searchData = [
          { Index: "s1", Country: "Alaska" }, { Index: "s2", Country: "California" },
          { Index: "s3", Country: "Florida" }, { Index: "s4", Country: "Georgia" }
      ];
      // filtering event handler to filter a Country
      function onFiltering(args) {
          let query = new Query();
          // frame the query based on search string with filter type.
          query = (args.text !== "") ? query.where("Country", "startswith", args.text, true) : query;
          // pass the filter data source, filter query to updateData method.
          args.updateData(this.searchData, query);
      }

    //   You should update this so that the chart doesnt disappear when it is loadign new one (put loader above it, and retain axes)

    useEffect(() => { // fire a graph reload on user update of axis, or groups.
        // If your axis changes, it should re-render the graph 
        if(plotRenderedOnce){
            let userChartType = chartType
            if(userChartType!==''){ // update the chart with load graph function
                loadGraph(userChartType)
            }
        }
    }, [groupValue, xAxisVal, yAxisVal, zAxisVal,chartType]);

    async function loadGraph(graphType) { // update this 
        setPlot('loading')
        setChartType(graphType)
        setMicroLoad(true)
        if(MuniCode === 'All' && county === 'All'){
            setPlot('')
            setMicroLoad(false)
            setGraphError('select a County and Municipality')
        return
        }else{
            setGraphError(null)
        }
        if(axisOptions<4){
            const muniObj = {
                MuniCode: MuniCode,
                TaxYear: taxYear
            };
            await loadOptions(muniObj);
        }
        // Requirements:
        // Color / color-by (?? ask bren)
        // plot-title (if you wanna name)

        const axisValues = [xAxisVal, yAxisVal, zAxisVal];
        const programAxisValues = [programxAxisVal, programyAxisVal, programzAxisVal]

        let numRequiredAxes = 0;
        // Determine the number of required axes based on graph type
        if (graphType === 'Distribution') {
            numRequiredAxes = 1;
        } else if (graphType === 'X,Y Scatter') {
            numRequiredAxes = 2;
        } else if (graphType === '3-D Scatter') {
            numRequiredAxes = 3;
        }
    
        // Check if all axis values are blank or if the number of non-blank axis values is less than required
        const allBlank = axisValues.filter(value => value !== '').length !== numRequiredAxes;
        
            // Initialize visualization object
            const visualizationObject = {
                MuniCode: MuniCode,
                TaxYear: taxYear,
                lag: lag,
                PlotType: graphType,
                ...(groupValue !== 'No Groups' && { GroupBy: groupValue }), // Conditionally include GroupValue key
            };

            // This is NOT passing in the program axis -- need to look at both states, and act accordingly. if a progarm axis exists (and user inptu is blank) then use that.
            for (let i = 0; i < numRequiredAxes; i++) {
                const userValue = axisValues[i];
                const programValue = programAxisValues[i];
                if (userValue !== '') {
                    visualizationObject[`Axis${i + 1}`] = userValue;
                } else if (programValue !== '') {
                    visualizationObject[`Axis${i + 1}`] = programValue;
                }
            }
            
            try{
            const visualization = await axiosInstance.post(`/get_plot`, visualizationObject);
            console.log(visualization)
            visualization.data.layout.margin = visualization.data.layout.margin || {};
            visualization.data.layout.pad = visualization.data.layout.pad || {};
            visualization.data.layout.margin.b = 80; // this is default
            visualization.data.layout.margin.t = 100; // this is the default
            visualization.data.layout.margin.r = 80; // this is the default, and seems wrong
            visualization.data.layout.margin.l = 110; // updated this to drive plot towards center - seemed to have worked.
            visualization.data.layout.autosize = true;
            
            // Initialize axis values as empty strings
            let axis1 = '';
            let axis2 = '';
            let axis3 = '';
            
            // Set axis values using the respective setter functions
            if (graphType === 'Distribution') { // figure out sizing of components for non-3d chart
                visualization.data.layout.margin.l = 280; // updated this to drive plot towards center - seemed to have worked.
                visualization.data.layout.margin.r = 180;
                axis1 = visualization.data.layout.yaxis.title.text;
            } else if (graphType === 'X,Y Scatter') {
                axis1 = visualization.data.layout.xaxis.title.text;
                axis2 = visualization.data.layout.yaxis.title.text;
                visualization.data.layout.margin.l = 280
            } else if (graphType === '3-D Scatter') {
                visualization.data.layout.scene.camera = visualization.data.layout.scene.camera || {};
                visualization.data.layout.scene.camera.eye = visualization.data.layout.scene.camera.eye || {};
                visualization.data.layout.scene.camera.eye.z = 1.35; // changed default here to prevent cutoff corner. 1.4 is fully non-cut.
                const scene = visualization.data.layout.scene;
                // Iterate over keys and set axis values accordingly
                Object.keys(scene).forEach(key => {
                    if (key === 'xaxis') {
                        axis1 = scene[key].title.text;
                    } else if (key === 'yaxis') {
                        axis2 = scene[key].title.text;
                    } else if (key === 'zaxis') {
                        axis3 = scene[key].title.text;
                    }
                });
            }
            
            // Set axis values only if all axis values are blank
            if (allBlank) { // if user doesn't have required values, set our default chart
                setProgramxAxisVal(axis1);
                setProgramyAxisVal(axis2);
                setProgramzAxisVal(axis3);
            }
        
        setPlot(visualization.data)
        // visualization.data.layout.scene.camera.eye.x = 1.25 // these are defaults.
        // visualization.data.layout.scene.camera.eye.y = 1.25
        console.log(visualization.data)
        setPlotRenderedOnce(true)
        }catch{
        setPlot('')
        setGraphError('Uncaught Data error. Try a different Year/Area')
        }
        
        setMicroLoad(false)
    }

    function attemptFilter(){ // your FE filtering component shell
        console.log('retain your originalChart')
        console.log(plot) // make a deep ccopy if you want to retain the original chart.
        const plotObject = {...plot}
        // Perform FE filtering to just a single point:
        // This filters onlyf or violin plot probably at the moment
        console.log(plot.data[0].y)
        // skip null values in the filtering

        const filteredData = plot.data[0].y.filter((point) => point > 1);
        console.log('filteredData')
        console.log(filteredData)
        plotObject.data[0].y = filteredData
        setPlot(plotObject)
    }

    async function loadOptions(muniObj){ // consolidate the options load into your other call.
        setMicroLoad(true)
        try {
            const visualizationObject = {
                ...muniObj,
                lag: lag,
                PlotType: chartType,
                ...(groupValue !== 'No Groups' && { GroupBy: groupValue }), // Conditionally include GroupValue key
              };
            const visualizationFeatures = await axiosInstance.post(`/load_viz_features`, visualizationObject);
            const axisOptions = visualizationFeatures.data;
            setAxisOptions(axisOptions);
            setAxesLoaded(true);
            setMicroLoad(false);
        } catch (error) {
            // Handle the error here
            console.error('An error occurred while loading visualization features:', error);
            toast.error(`${error} `, {
                position: 'top-right',
                className: 'mt-10',
                autoClose: 3000,
            });
            // Optionally, you can update the state to reflect the error condition
            setAxesLoaded(false);
            setMicroLoad(false);
        }
    }

    const handleAxisChange = (axisNumber, value) => { // This need to update chart type based on what is removed (# of axes)
        if (axisNumber === 1) {
            setxAxisVal(value);
            setProgramxAxisVal('')
        } else if (axisNumber === 2) {
            if (xAxisVal === '' && programxAxisVal == '') {
                setxAxisVal(value);
                setProgramxAxisVal('')
            } else {
                setyAxisVal(value);
                setProgramyAxisVal('')
                if(zAxisVal ==='' && programzAxisVal === ''){
                setChartType('X,Y Scatter')
                }
            }
        } else if (axisNumber === 3) {
            if (xAxisVal === '' && yAxisVal === '' && programxAxisVal ==='' && programyAxisVal === '') {
                setxAxisVal(value);
                setProgramxAxisVal('')
            } else if (yAxisVal === '' && programyAxisVal === '') {
                setyAxisVal(value);
                setProgramyAxisVal('')
            } else {
                setzAxisVal(value);
                setProgramzAxisVal('')
                setChartType('3-D Scatter')
            }
        }

        // Could have a fourth state in axisNumber to be 'remove' and handle in an else in this same function.
    };

    function removeAxis(axis){ // dynamically change chart type based on what you add / remove
        if(axis===3){
            setzAxisVal('')
            setProgramzAxisVal('')
            setChartType('X,Y Scatter')
        }else if(axis===2){
            setyAxisVal('')
            setProgramyAxisVal('')
            setChartType('Distribution')
        }else if(axis===1){
            setPlot('')
            setxAxisVal('')
            setProgramxAxisVal('')
        }

    }

return(
    <div className='flex flex-col h-full'>
        <div className=' py-2 '>
            <div className='justify-center content-center w-full bg-white h-auto rounded-md items-center  min-h-[90px] flex-col'>
                <div className='text-center font-bold '>
                    Visualization Parameters:
                </div>
                <GraphParams nyDataObj={nyDataObj} lag={lag} microLoad={microLoad} setMicroLoad={setMicroLoad} setLag={memoizedSetLag} yearDropdownOptions={yearDropdownOptions} loadGraph={loadGraph}
                />
            </div>
        </div>

    {/* Graph Visualizations */}
    {numGraphs <= 1? // actually 1
    <div ref={elementRef} className='justify-center h-full flex-grow'>
        <div className='content-center h-full text-center w-full bg-white rounded-md'>

        {graphError?
            <div className="flex justify-center items-center h-full z-[9999]">
                <div className="text-meta-1">Error: {graphError}</div>
            </div>
        :
        (microLoad || plot === 'loading') && !plotRenderedOnce ?
        
                    <div className="flex justify-center items-center h-full z-[9999]">
                        <div className="h-16 w-16 animate-spin z-[400] rounded-full border-4 border-solid border-primary border-t-transparent"/>
                    </div>
                    :
        plotRenderedOnce && ( // Once you've rendered a graph, retain the axes.
            <div className='content relative h-full'>
                {/* This does not auto-place well, need to figure out getting it absolutely in mid if you do bottom. */}
                
                {/* BOTTOM DROPDOWN MENU */}
                {/* <div className='absolute z-[50] left-1/3 flex bottom-4 items-center justify-end'>
                    <div className='flex mr-8 items-center justify-end'>
                        <FormControl className='flex w-fit min-w-[125px] justify-end' size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">Axis 1</InputLabel>
                            <Select
                                value={programxAxisVal !== '' ? programxAxisVal : xAxisVal} // set a default value for axis1.
                                className='w-fit min-w-[125px]'
                                label="Axis 1"
                                onChange={(e) => handleAxisChange(1, e.target.value)}
                            >
                                {axisOptions.map((axis, index) => (
                                    <MenuItem key={index} value={axis}>{axis}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {xAxisVal || programxAxisVal !== '' ?
                            <IconButton
                            onClick={() => {setxAxisVal(''); setProgramxAxisVal('')}}
                                sx={{
                                    ml: 'auto', p: 0,
                                    color: '#F00', // Default color
                                    '&:hover': {
                                        color: '#F00', // Color on hover (white)
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <CloseIcon
                                />
                            </IconButton>
                            :
                            null}
                    </div>

                    <div className=' mr-8 flex items-center justify-end'>
                        <FormControl className='flex w-fit min-w-[125px] justify-end' size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">Axis 2</InputLabel>
                            <Select
                                value={programyAxisVal !== '' ? programyAxisVal : yAxisVal} // set a default value for axis1.
                                className='w-fit min-w-[125px]'
                                label="Axis 2"
                                onChange={(e) => handleAxisChange(2, e.target.value)}
                            >
                                {axisOptions.map((axis, index) => (
                                    <MenuItem key={index} value={axis}>{axis}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {yAxisVal || programyAxisVal !== ''  ?
                            <IconButton
                            onClick={() => {setxAxisVal(''); setProgramxAxisVal('')}}
                                sx={{
                                    ml: 'auto', p: 0,
                                    color: '#F00', // Default color
                                    '&:hover': {
                                        color: '#F00', // Color on hover (white)
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <CloseIcon
                                />
                            </IconButton>
                            :
                            null}
                    </div>

                    <div className='mr-8 flex items-center'>
                        <FormControl className='flex w-fit min-w-[125px]' size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">Axis 3</InputLabel>
                            <Select
                                value={programzAxisVal !== '' ? programzAxisVal : zAxisVal} // set a default value for axis3.
                                className='w-fit min-w-[125px]'
                                label="Axis 3"
                                onChange={(e) => handleAxisChange(3, e.target.value)}
                            >
                                {axisOptions.map((axis, index) => (
                                    <MenuItem key={index} value={axis}>{axis}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {zAxisVal || programzAxisVal !== ''  ?
                            <IconButton
                            onClick={() => {setzAxisVal(''); setProgramzAxisVal('')}}
                                sx={{
                                    ml: 'auto', p: 0,
                                    color: '#F00', // Default color
                                    '&:hover': {
                                        color: '#F00', // Color on hover (red, should slightly darken)
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <CloseIcon
                                />
                            </IconButton>
                            :
                            null
                        }
                    </div>
                </div> */}

            {/* WE LIKE THIS THE MOST> */}
                {/* Secondary, vertical AXIS Container. */}
                <div className='absolute z-[50] left-[2%] top-1/3 items-center justify-left'>
                <div className='flex items-center mb-2.5'>

                {/* Update to this dropdown component once you refactor the selection options */}
                {/* <DropDownListComponent id="ddlelement" popupHeight="250px" fields={fields} filtering={onFiltering = onFiltering.bind(this)} allowFiltering={true} dataSource={searchData}
                placeholder="X Axis"
                width={'125px'}
                value={programxAxisVal !== '' ? programxAxisVal : xAxisVal}
                text={programxAxisVal !== '' ? programxAxisVal : xAxisVal}
                label="Axis 1"
                onChange={(e) => handleAxisChange(1, e.target.value)}
                /> */}
                    <FormControl className='flex w-fit min-w-[125px]' size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">X-axis</InputLabel>
                        <Select
                            value={programxAxisVal !== '' ? programxAxisVal : xAxisVal}
                            className='w-fit min-w-[125px]'
                            
                        >
                            {axisOptions.map((axis, index) => (
                                <MenuItem key={index} value={axis}>{axis}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {xAxisVal || programxAxisVal !== '' ?
                        <IconButton
                        onClick={()=>{removeAxis(1)}}
                        sx={{p: 0,
                            color: '#F00', // Default color
                            '&:hover': {
                                color: '#F00', // Color on hover (white)
                                backgroundColor: 'transparent'}}}
                        >
                        <CloseIcon 
                        />
                        </IconButton>
                        :
                    null}
                </div>

                {/* If not an axis2plot */}
                <div className={`z-[50] flex mb-2.5 items-center justify-start ${chartType==='Distribution' || (programyAxisVal ==='' && yAxisVal === '') ? 'opacity-25' : '' }`}>
                    <FormControl className='flex w-fit min-w-[125px] justify-left' size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">Y-axis</InputLabel>
                        <Select
                            value={programyAxisVal !== '' ? programyAxisVal : yAxisVal}
                            className='w-fit min-w-[125px]'
                            label="Axis 1"
                            onChange={(e) => handleAxisChange(2, e.target.value)}
                        >
                            {axisOptions.length < 1 ? (
                                <div className='flex m-auto items-center justify-center'>
                                    <div className="flex m-auto justify-center items-center h-6 w-6 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"/>
                                </div>
                            ) : (axisOptions.map((axis, index) => (
                                <MenuItem key={index} value={axis}>{axis}</MenuItem>
                            )))}
                        </Select>
                    </FormControl>
                    {yAxisVal || programyAxisVal !== '' ?
                        <IconButton
                        onClick={()=>{removeAxis(2)}}
                        sx={{p: 0,
                            color: '#F00', // Default color
                            '&:hover': {
                                color: '#F00', // Color on hover (white)
                                backgroundColor: 'transparent'}}}
                        >
                        <CloseIcon 
                        />
                        </IconButton>
                        :
                    null}
                </div>

                <div className={`z-[50] flex mb-2.5 items-center justify-start ${chartType==='Distribution' || chartType === 'X,Y Scatter' || (programzAxisVal ==='' && zAxisVal === '') ? 'opacity-25' : ''}`}>
                    <FormControl className='flex w-fit min-w-[125px] justify-end' size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">Z-axis</InputLabel>
                        <Select
                            value={programzAxisVal !== '' ? programzAxisVal : zAxisVal} // set a default value for axis1.
                            className='w-fit min-w-[125px]'
                            label="Axis 3"
                            onChange={(e) => handleAxisChange(3, e.target.value)}
                        >
                            {axisOptions.map((axis, index) => (
                                <MenuItem key={index} value={axis}>{axis}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {zAxisVal || programzAxisVal !== '' ?
                        <IconButton
                        onClick={()=>{removeAxis(3)}}
                        sx={{p: 0,
                            color: '#F00', // Default color
                            '&:hover': {
                                color: '#F00', // Color on hover (white)
                                backgroundColor: 'transparent'}}}
                        >
                        <CloseIcon 
                        />
                        </IconButton>
                        :
                    null}
                </div>
                </div>

                <div className='float absolute z-[50] right-[0.5%] top-[8.5%]  flex items-center justify-end'>
                <>
                <div className=''>
                    <FormControl className='flex w-fit min-w-[125px] mx-4 justify-end' size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">Add Filter:</InputLabel>
                        <Select
                        // disabled={true}
                            value={''} // set a default value for axis1.
                            className='w-fit min-w-[125px]'
                            label="Add Filter:"
                            onChange={(e) => setFilterValue(e.target.value)}
                            // onClick={attemptFilter}
                        >
                            {filterOptions.map((group) => (
                                <MenuItem key={group} value={group}>{group}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                </>
                {/* GROUP BY filter */}
                <>
                <div className=''>
                    <FormControl className='flex w-fit min-w-[125px] justify-end' size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">Group By:</InputLabel>
                        <Select
                            value={groupValue} // set a default value for axis1.
                            className='w-fit min-w-[125px]'
                            label="Group By:"
                            onChange={(e) => setGroupValue(e.target.value)}
                        >
                            {groupOptions.map((group, index) => (
                                <MenuItem key={index} value={group}>{group}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                </>
                </div>

                {plot && (
                    microLoad || plot === 'loading' ?
                    <div className="flex justify-center items-center h-full z-[9999]">
                        <div className="h-16 w-16 animate-spin z-[400] rounded-full border-4 border-solid border-primary border-t-transparent"/>
                    </div>
                    :
                    <div className='content relative h-full'>
                        {/* Here, if plot has rendered ONCE retain all your axes, only show this below plot if plot */}
                            {/* TEST FILTERING for 'y' */}
                        <Plot 
                        data={plot.data} 
                        layout={plot.layout}
                        transforms ={[{
                            type: 'filter',
                            target: 'y',
                            operation: '>',
                            value: 100
                          }]}
                        style={{width: '100%', height: '100%'}}
                        useResizeHandler={true}
                        config={{displaylogo: false, responsive: true, datarevision: plot}}
                        // config object lets you easily config stuff. can displayModebar: false, etc.
                        />
                    </div>
                    )}
                </div>
                )}
        </div>
    </div>
    :
    numGraphs === 2 ?
    <div className='m-auto grid grid-cols-2 gap-4 px-12 justify-center'>
        {/* Isolate graph in its own component probably once you have the inputs to pass in */}
        <div className={`m-auto content-center text-center h-[80vh] w-full bg-white rounded-md ${activeGraph === 1 ? 'border-primary border-2' : 'hover:cursor-pointer'}`}
        onClick={()=>setActiveGraph(1)}>
        Graphic display
        </div>
        <div className={`m-auto content-center text-center h-[80vh] w-full bg-white rounded-md ${activeGraph === 2 ? 'border-primary border-2' : 'hover:cursor-pointer'}`}
        onClick={()=>setActiveGraph(2)}>
        Graphic display
        </div>
    </div>
    :
    numGraphs === 3 ?
    <>
    <div className='h-[80vh] flex justify-between gap-4 px-12'>
        <div className=' w-1/2 bg-white rounded-md'>
        Graphic display
        </div>
        <div className='flex flex-col w-1/2 gap-4'>
        <div className='h-[40vh] bg-white rounded-md '>
            Graphic display
        </div>
        <div className='h-[40vh] bg-white rounded-md'>
            Graphic display
        </div>
        </div>
    </div>
    </>
    :
    numGraphs > 3?
        <>
        <div className='h-[80vh] flex justify-between gap-4 px-12'>
            <div className='flex flex-col w-1/2 gap-4'>
            <div className='h-1/2 bg-white rounded-md'>
                Graphic display
            </div>
            <div className='h-1/2 bg-white rounded-md'>
                Graphic display
            </div>
            </div>
            <div className='flex flex-col w-1/2 gap-4'>
            <div className='h-1/2 bg-white rounded-md'>
                Graphic display
            </div>
            <div className='h-1/2 bg-white rounded-md'>
                Graphic display
            </div>
            </div>
        </div>
        </>
    :
    null
    }
    {/* </div> */}
    </div>

    )
} export default React.memo(Visualizations)
