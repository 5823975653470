import React, { useState, useMemo, useEffect, useCallback, } from "react";
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
// import {
//   Popover,
//   PopoverHandler,
//   PopoverContent,
//   Button,
//   Chip,
//   Typography,
// } from "@material-tailwind/react";
import {
  useCompStore,
  useLocalDataStore,
  useAdminSettingsStore,
  usePersistedDataStore,
} from "./store";
import AdditionalComps from "./AdditionalComps";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import CompDashboard from "./CompDashboard";
import Map from "./Map";
import ReviewSettlements from "./ReviewSettlements";
import _ from "lodash";
import SettingsWidget from "./SettingsWidget";
import { ModalProvider } from "../common/ModalContext";

function useAveragePrice(getCourtComps, comp) {
  // Memoized function to calculate average price
  return useMemo(() => {
    const prices = getCourtComps[comp]?.adj_price || [];

    // Filter out blanks and zeros, then calculate average
    const validPrices = prices.filter((price) => price !== "" && price !== 0);

    // Return the average or 0 if there are no valid prices
    const averagePrice =
      validPrices.length > 0
        ? validPrices.reduce((sum, price) => sum + price, 0) /
          validPrices.length
        : 0;
    // console.log('Average Price:', averagePrice);
    return averagePrice;
  }, [getCourtComps, comp]); // Dependencies to recalculate if they change
}

const MemoizedCompDashboard = React.memo(CompDashboard);
const MemoizedReviewSettlements = React.memo(ReviewSettlements);
// const MemoizedAdditionalComps = React.memo(AdditionalComps);
const MemoizedMap = React.memo(Map);

function Home({
  // municode,
  // comp,
  loading,
  setLoading,
  globalLoad,
  initializationFetch,
  setfetchError,
  fetchError,
  weightWidget,
  setWeightWidget,
  fetchComps,
  isAdmin,
  nyDataObj,
  yearDropdownOptions,
}) {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const testMode = false; // this is temporarily being set

  // Home -> comprows
  // Instead of rendering All the rows, only render visible rows

  // const handleMuniChange = (newMuni) => {
  //   onUpdateParams({ municode: newMuni });
  // };

  // const handleCompChange = (newComp) => {
  //   onUpdateParams({ comp: newComp });
  // };

  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const getCompsheetB = usePersistedDataStore((state) => state.compSheetB);
  const memoizedRegCompSheet = useMemo(() => getCompSheet, [getCompSheet]); // idk that this memoization does anything.
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const [reOrderedComps, setReOrderedComps] = useState({});
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
  const getSalesCompReport = useAdminSettingsStore((state) => state.salesCompReport);
  const getMap = useAdminSettingsStore((state) => state.map);
  const getReviewPage = useLocalDataStore((state) => state.reviewPage);
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const getCaseNotes = usePersistedDataStore((state) => state.caseNotes);
  const setCaseNotes = usePersistedDataStore((state) => state.setCaseNotes);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
  const getOptimizedCompsB = usePersistedDataStore((state) => state.optimizedCompsB);
  const getSortOrder = useCompStore((state) => state.sortOrder);
  const getAdjustments = useCompStore((state) => state.adjustments);
  const setSortOrder = useCompStore((state) => state.setSortOrder);
  const getEvalDate = usePersistedDataStore((state) => state.evalDate);
  const [sortColumn, setSortColumn] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const getCourtComps = usePersistedDataStore((state) => state.courtComps);
  const navigate = useNavigate()
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
  // console.log(getNegotiationObj)
  // console.log(comp)
  const view = queryParams.get('view') || 'regular';
  const courtAvgPrice = useAveragePrice(getCourtComps, comp);
  const hasComps = getCourtComps[comp] && Object.keys(getCourtComps[comp]).length > 0;
  const [averagePrice, setAveragePrice] = useState(0);

  // console.log(getNegotiationObj)
  useEffect(() => {
    // Recalculate the average price when the comps change
    console.log('useeffect to re-calc price running, as workup reference has changed');
    const updateNegotiationObj = { ...getNegotiationObj };
  
    // Log the relevant comp data for debugging
    console.log(getCompSheet[comp]);
    if(getNegotiationObj.cases[comp] === undefined){ // break out of func to prevent error
      return
    }
  
    if (view === 'regular') {
      const newAvg = getCompSheet[comp]?.adj_price?.slice(1, 6) || [];
      if (newAvg.length > 0) {
        const averagePrice = newAvg.reduce((sum, comp) => sum + (comp || 0), 0) / newAvg.length;
        updateNegotiationObj.cases[comp].WorkupValueAvg = parseInt(averagePrice);
        setAveragePrice(updateNegotiationObj.cases[comp].WorkupValueAvg);

      } else {
        updateNegotiationObj.cases[comp].WorkupValueAvg = 0; // Default value if no valid prices
      }
    } else if (view === 'optimized') {
      const newAvg = getOptimizedComps[comp]?.adj_price?.slice(1, 6) || [];
      if (newAvg.length > 0) {
        const averagePrice = newAvg.reduce((sum, comp) => sum + (comp || 0), 0) / newAvg.length;
        updateNegotiationObj.cases[comp].WorkupValueOptimAvg = parseInt(averagePrice);
        setAveragePrice(updateNegotiationObj.cases[comp].WorkupValueOptimAvg);
      } else {
        updateNegotiationObj.cases[comp].WorkupValueOptimAvg = 0; // Default value if no valid prices
      }
    }
    // Set the avg price as a seperate state.
    setNegotiationObj(updateNegotiationObj);
  }, [getCompSheet, getOptimizedComps, getCourtComps, view, comp]);
  
  
  const triggers = useMemo( // this needs to be changed. the hover re-renders the whole home should exist at the lowest level.
    () => ({
      onMouseEnter: () => setOpenPopover(true),
      onMouseLeave: () => setOpenPopover(false),
    }),
    []
  );
  // const [tempState, setTempState] = useState(false)
  // This funciton should be the generalized one for all other ones (after the fetch itself)
  const handleCaseNotes = useCallback((e) => { // memoized this function
    const updatedSettlement = _.cloneDeep(getCaseNotes);
    updatedSettlement[comp] = e.target.value
      setCaseNotes(updatedSettlement)
  }, [getNegotiationObj, comp]);

  const updateView = useCallback((view) => {
      const url = new URL(location.pathname+location.search, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      searchParams.set('view', view);
      const newUrlString = `${url.pathname}?${searchParams.toString()}`;
      // console.log(newUrlString)
      navigate(newUrlString)
    }, [location]);

  const filter = useCallback((compList, originalCompList, feature, view) => {
    // complist, originalcomplist, and feature
    // take in the compList input
    const updatedCompSheet = _.cloneDeep(compList);
    let indexing = 1 // by default this is one, but if there's a 
    setSortColumn(feature)
    if(getSortOrder!=='standard'){
      // ths goes through all the PID's except the subject. (and drops undefineds)
    const restElements = updatedCompSheet.parcel_id.map((pid, index) => {
      if (index === 0 || pid===updatedCompSheet.parcel_id[0]) {
        if(index!==0){
          indexing = 2
        }
      }else{ // create the array to re-order, the top things are excluded. Need to increment index if excluding more.
        return {
          // return feature: .feature instead of distance below.
          pid,
          feature: updatedCompSheet[feature][index],
          newPosition: index,
        };
      }
    }).filter(Boolean);

    // Sort the rest of the elements based on the 'feature' property
    let newPositions = restElements.sort((a, b) => {
      const valueA = a.feature;
      const valueB = b.feature;

      return getSortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    });

    // Adjust the newPosition property for the sorted elements
    newPositions.forEach((element, index) => {
      element.newPosition = index + indexing; // Start from 1 for the rest of the elements (or two if there was a subj sale)
    });
    Object.entries(newPositions).forEach(([pid, newValueObj]) => { // Loop over the array of comps you just re-ordered
      // do this error handling if it is standard view, otherwise nah
            // As it goe through, once it has moved the 
            const originalIndex = updatedCompSheet.parcel_id.findIndex(
              (row) => { // returns the original index from your updated compsheet clone.
                return String(row) === String(newValueObj.pid);
              } // REMINDER - 0 is equal to subject. so 1 means first position in your rendered array.
            );
            // console.log(`OG Index:${originalIndex}: target: ${newValueObj.newPosition}`) // log the pid and target.
    
            Object.entries(updatedCompSheet).forEach(([key, value]) => {

            // console.log(`key: ${key}`)
            // if (Array.isArray(value)) { // it may make sense to put this back in as its protects the code (but I'd rather see the error if cases come up atm so commented out)
            if(key==='price_adjustments'){
              // Now you need to loop over the 
              Object.entries(updatedCompSheet[key]).forEach(([key2,value]) => {
                // Truthfully, I think this entire adjustments array is completley unnecessary given our current codebase, but wanted to re-order it just in case.
                const movedVal = updatedCompSheet[key][key2][originalIndex];
                updatedCompSheet[key][key2].splice(originalIndex, 1);
                updatedCompSheet[key][key2].splice(newValueObj.newPosition, 0, movedVal);
              })
            }else if(key!=='Regular_midpoint' && key!== 'Regular_straight_average' && key!=='Optimized_midpoint' && key!=='Optimized_straight_average' && key!=='minimum_optimized_value'){ // every case except for price adjustments.
              const movedVal = updatedCompSheet[key][originalIndex];
              updatedCompSheet[key].splice(originalIndex, 1);
              updatedCompSheet[key].splice(newValueObj.newPosition, 0, movedVal);
            }
          });
        })

          if(view==='optimized'){
            const updatedCompSheetArray = _.cloneDeep(getOptimizedComps);
            updatedCompSheetArray[comp] = _.cloneDeep(updatedCompSheet);
            setOptimizedComps(updatedCompSheetArray);
          }else if(view==='regular'){
            const updatedCompSheetArray = _.cloneDeep(getCompSheet);
            updatedCompSheetArray[comp] = _.cloneDeep(updatedCompSheet);
            setCompSheet(updatedCompSheetArray);
          }
    }else{
      if(view==='regular'){
        // console.log(originalCompList)
      setCompSheet(originalCompList) // pass original complist as a functino input
      }else{
        // console.log(originalCompList)
      setOptimizedComps(originalCompList)
      }
    }
    if(getSortOrder==='asc'){
      setSortOrder('desc')
    }else if (getSortOrder==='desc'){
      setSortOrder('standard')
    }else{
      setSortOrder('asc')
    }
  }, [getSortOrder, getCompSheet, setCompSheet, setOptimizedComps]);

  const formatEvalDate = (evalDate) => {
    let [year, month, day] = evalDate.split('-');
    let date = new Date(year, month - 1, day, 12, 0, 0); // Set time to noon
    let formattedDate = date.toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'});
    return formattedDate;
};

return(
    <div className="bg-gray-200 overflow-x-hidden ">
      {/* Weight widget ternary */}
      {/* This can get deleted down to end of widget ternary */}
      {/* ADD IN THE SECOND COlumn with other weights */}
      {weightWidget? // display the weight widget, with an X to exit it.
        <SettingsWidget nyDataObj={nyDataObj} initializationFetch={initializationFetch} setWeightWidget={setWeightWidget}
        setLoading={setLoading}
        ></SettingsWidget>
        :null}
    {/* End of weight widget ternary */}

    {/* The below top nav section */}
          <div className=" ">
        <section className="min-h-[700px] overflow-y-none max-w-screen-22xl pt-2 px-6" id='home'>    
        
        {/* Top navigration above map. */}
        <CompDashboard fetchError={fetchError} yearDropdownOptions={yearDropdownOptions} setfetchError={setfetchError} reOrderedComps={reOrderedComps} weightWidget={weightWidget} setWeightWidget={setWeightWidget} setReOrderedComps={setReOrderedComps} loading={loading} setLoading={setLoading} globalLoad={globalLoad} fetchComps={fetchComps} nyDataObj={nyDataObj}></CompDashboard>

        {/* // Your standard compsheet one pager view below: */}
          {/* REFACTOR THIS SO ITS NOT DRAG AND DROP ANYMORE */}
        {/* Make this one && not the terminal view state - && !reviewPage */}
        {getReviewPage?
        // Display your review page IF state is set to true.
        <ReviewSettlements isAdmin={isAdmin}/>
        :
        // used to havev another ternary here for if you were in 
        !getSalesCompReport && getCompSheet? // if you are not in sales comparable view, and you have data:
          <div className=" ">
            {/* MAP COMPONENT - This is for NON-TABLE view figure out getting lat / longs into here. */}
        
        {/* // display the map for non-court view. */}
        {!getMap 
          ?null:
          view==='court' ? 
          getCourtComps[comp] ?
          <Map view={view} />
        :
        <div>Loading Court Comps</div>
        :
        <Map view={view} />
        }

        {/* Ternary IF there are casenotes */}
        {getCompSheet[comp] === undefined ? (
          <>
            <div style={{ textAlign: "center" }} className="text-danger h-18 justify-center content-center">No clients in Municipality</div>
          </>
          ) : (
            <>
            <div className="py-2">
                  <input
                    type="text"
                    placeholder="Type to enter case notes for this property"
                    className="w-full border rounded-md text-black outline-none focus:outline-none"
                    onChange={(e)=>handleCaseNotes(e)}
                    value={getCaseNotes[comp] || getNegotiationObj.cases[comp]?.CaseNotes || ''}
                  />
                </div>

                  <div className="flex justify-between">
                      <div className="flex">
                        <button
                          className={`border-t border-x px-2 ${view==='regular' ? 'text-primary bg-white border-r' : ''}`}
                          onClick={() => updateView('regular')}>
                          Regular
                        </button>
                        {testMode? // only render if testing.
                        <button className={`border-t border-x px-2 ${view==='regularb' ? 'text-primary bg-white':''}`} onClick={() => updateView('regularb')}>
                          Regular(B)
                        </button>
                        :
                        null}
                        <button
                          className={`border-t border-r px-2 ${view==='optimized' ? 'text-primary bg-white' : ''}`}
                          onClick={() => updateView('optimized')}>
                          Optimized
                        </button>
                        {testMode? // only render if we are testing.
                        <button
                          className={`border-t border-r px-2 ${view==='optimizedb' ? 'text-primary bg-white' : ''}`}
                          onClick={() => updateView('optimizedb')}>
                          Optimized(B)
                        </button>
                        :null}
                        <button
                          className={`border-t border-r px-2 ${view==='court' ? 'text-primary bg-white border-r' : ''}`}
                          onClick={() => updateView('court')}>
                        Court
                      </button>
                      </div>

                      {/* middle section  */}
                      {/* Refactor this to be based on what view it is. And calculate */}
                      {Number.isFinite(getNegotiationObj.cases[comp]?.WorkupValueAvg) && view === 'regular' ?
                      <div className={`pl-auto justify-end items-center content-center whitespace-nowrap`}>
                        Top 5 comps average price:
                        <span className="font-bold ml-2">{Math.round(averagePrice).toLocaleString()}</span>

                        {/* If avvg price - IFMV */}
                        {(((averagePrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])<-0.25)?
                        <>
                        <span className="float text-sm">({(Math.round(0.75*getCompSheet[comp].IFMV[0]*getCompSheet[comp]?.RAR[0])).toLocaleString()} AV)</span>
                        <span className={`content-center justify-center text-sm items-center text-success`}>
                        (-25%)
                        </span>
                        </>
                        :
                        <>
                        <span className="text-sm">({(Math.round(averagePrice*getCompSheet[comp]?.RAR[0])).toLocaleString()} AV)</span>
                        <span className={`content-center justify-center text-sm items-center ${((averagePrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])>=0?'text-danger':'text-success'}`}>
                          ({(((averagePrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])*100).toFixed(2)}%)
                        </span>
                        </>
                        }
                      </div>
                      :
                      null}

                      {/* Optimized number view: */}

                  {Number.isFinite(averagePrice) && view==='optimized'?
                      <div className={`pl-auto justify-end items-center content-center whitespace-nowrap`}>
                        Top 5 comps average price:
                        <span className="font-bold ml-2">{Math.round(averagePrice).toLocaleString()}</span>

                        {/* If avvg price - IFMV */}
                        {(((averagePrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])<-0.25)?
                        <>
                        <span className="float text-sm">({(Math.round(0.75*getCompSheet[comp].IFMV[0]*getCompSheet[comp]?.RAR[0])).toLocaleString()} AV)</span>
                        <span className={`content-center justify-center text-sm items-center text-success`}>
                        (-25%)
                        </span>
                        </>
                        :
                        <>
                        <span className="text-sm">({(Math.round(averagePrice*getCompSheet[comp]?.RAR[0])).toLocaleString()} AV)</span>
                        <span className={`content-center justify-center text-sm items-center ${((averagePrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])>=0?'text-danger':'text-success'}`}>
                        ({(((averagePrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])*100).toFixed(2)}%)
                        </span>
                        </>
                        }
                      </div>
                      :
                      null}

                    {view==='court'? // temporarily have this not working, your values are slightly off from theirs anyways
                      <div className={`pl-auto justify-end items-center content-center whitespace-nowrap`}>
                        Top 5 comps average price:
                        <span className="font-bold ml-2">{Math.round(courtAvgPrice).toLocaleString()}</span>

                        {/* If avvg price - IFMV */}
                        {(((courtAvgPrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])<-0.25)?
                        <>
                        <span className="float text-sm ">({(Math.round(0.75*getCompSheet[comp].IFMV[0]*getCompSheet[comp]?.RAR[0])).toLocaleString()} AV)</span>
                        <span className={`content-center justify-center text-sm items-center text-success`}>
                        (-25%)
                        </span>
                        </>
                        :
                        <>
                        <span className="text-sm">({(Math.round(courtAvgPrice*getCompSheet[comp]?.RAR[0])).toLocaleString()} AV)</span>
                        <span className={`content-center justify-center text-sm items-center ${((courtAvgPrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])>=0?'text-danger':'text-success'}`}>
                        ({(((courtAvgPrice-getCompSheet[comp].IFMV[0])/getCompSheet[comp].IFMV[0])*100).toFixed(2)}%)
                        </span>
                        </>
                        }
                      </div>
                      :
                      null}

                                  {/* adjustments here - can make inputs so it changes + recalcs the adj sale price (rerun algo?)*/}
                      <div className="flex items-center text-[0.875rem]">
                      <div className="mr-2">$/Sqft: {getAdjustments.Sqft ? getAdjustments.Sqft.toLocaleString() : ''}</div>
                      <div className="mx-2">$/Acre: {getAdjustments.LotSqFt ? (getAdjustments.LotSqFt*43560).toLocaleString() : ''}</div>
                      <div className="ml-2 mr-4">$/Bath: {getAdjustments.Bath ? getAdjustments.Bath.toLocaleString() : ''}</div>
                        
                        {/* Valuation date and rar */}
                        {/* REMOVED THIS WHILE TRANSITIONING TO MUI - swap this to MUI popovver instead. */}
                        {/* <div className="mr-2 pr-1 flex">
                          <span className="mr-1">Val Date: {formatEvalDate(getEvalDate[comp])}</span>
                          <Popover open={openPopover} handler={setOpenPopover}>
                          <PopoverHandler {...triggers}>
                          <div className="flex items-center justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-info hover:pointer"
                              >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="16" x2="12" y2="12"></line>
                              <line x1="12" y1="8" x2="12" y2="8"></line>
                            </svg>
                          </div>
                          </PopoverHandler>
                          <PopoverContent {...triggers} className="z-50 max-w-[12rem]" >
                              <Typography
                                as="span"
                                variant="h6"
                                color="blue-gray"
                                className="font-bold transition-colors hover:text-gray-900 mb-1 flex items-center text-center"
                              >
                                Ratios
                              </Typography>
                            <div className="text-left">RAR: {getCompSheet[comp]?.RAR[0]}</div>
                                  <div className="text-left">EQ: {getCompSheet[comp]?.EQ[0]}</div>
                          </PopoverContent>
                          </Popover>
                        </div> */}
                      </div>
                  {/* Spacing div to get the calc up near the top */}
                  </div>
            </>
          )}
        {/* Based on the vview defined by your next/prev subject function, choose which data to show. */}
        {
          view==='court'?
          <div>
            {/* render additionalcomps with this complist instead of the below. */}
              <AdditionalComps compList={getCourtComps[comp]} setLoading={setLoading} sortColumn={sortColumn} filter={filter} view='Court'></AdditionalComps>
          </div>
          :
          // Waterfall
        view==='regular' ? 
          <AdditionalComps compList={getCompSheet[comp]} setLoading={setLoading} sortColumn={sortColumn} filter={filter} view='standard' ></AdditionalComps>
        :
        view==='regularb' && testMode ?
          <AdditionalComps compList={getCompsheetB[comp]} setLoading={setLoading} sortColumn={sortColumn} filter={filter} view='standard' ></AdditionalComps>
        :
        view==='optimized'?
        <AdditionalComps compList={getOptimizedComps[comp]} setLoading={setLoading} sortColumn={sortColumn} filter={filter} view='optimized' ></AdditionalComps>
        :
        view==='optimizedb' && testMode ?
        <AdditionalComps compList={getOptimizedCompsB[comp]} setLoading={setLoading} sortColumn={sortColumn} filter={filter} view='optimized' ></AdditionalComps>
        :
        null
        }
          </div>
          :
          // Display nada if no active case.
          null}
        </section>
    </div>
  </div>
  )
}


// Home.whyDidYouRender = true;
export default Home;

