import ReactDOM from 'react-dom/client';
import Modal from './Modal';

export async function showModalAndWaitForSelection(header, message, option1, option2) {
    return new Promise((resolve) => {
      const handleSelection = (selectedOption) => {
        // Do any additional processing if needed
        resolve(selectedOption);
      };
  
      // Render your modal component here, passing the handleSelection function as onSelection prop
  
      const modalContainer = document.createElement('div');
      document.body.appendChild(modalContainer);

      // Use createRoot to render the ModalComponent into the container
      const root = ReactDOM.createRoot(modalContainer);
      root.render(
        <Modal
          header={header}
          message={message}
          option1={option1}
          option2={option2}
          onSelection={handleSelection}
        />
      );
    })
  }