import { usePersistedDataStore } from '../components/store';
import useUpdateDB from './updateDB.js';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';

const DBConfirmModal = ({ compList, setLoading, index, inventoryUpdates, setInventoryUpdates, confirmModal, setConfirmModal }) => {
  // const { confirmModal, setConfirmModal } = useModalContext();
  const updateDB = useUpdateDB();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
  // console.log(confirmModal)
  console.log(inventoryUpdates)

  // console.log(compList)
  // console.log(index)

  if (!confirmModal) {
    console.log('not rendering')
    return null; // Render nothing if confirmModal is false
  }

  // delete inventoryUpdates.Index; // remove the index so this component doesn't break.


  return (
    <td>
    <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
      <div className="w-full max-w-md bg-white shadow-lg rounded-md p-6 relative">
        {/* 'X' svg */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => { setConfirmModal(false); setInventoryUpdates({}); }}
          className="w-3.5 cursor-pointer shrink-0 fill-black hover:fill-danger float-right"
          viewBox="0 0 320.591 320.591"
        >
          <path
            d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
            data-original="#000000"
          ></path>
          <path
            d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
            data-original="#000000"
          ></path>
        </svg>

        <div className="my-6 text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#fbbf24"
            className="w-24 h-24 inline "
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>

          <h4 className="text-xl font-semibold text-black mt-4 underline">This will update the database</h4>
          <p className="text text-gray-500 my-2">
            For <span className='font-bold text-black'>{compList.Address[index]}</span> with:
          </p>

          {Object.keys(inventoryUpdates).map(key => {
            if(key!=='Index'){ // handle not rendering index.
            return (
            <div key={key} className='mt-1 flex flex-col'>
              <div className="flex text-center justify-center"> {/* Apply flex class here */}
                <span className='font-bold text-black text-right w-[185px]'>
                  <span className='font-normal text-body'>{key}:</span> {key !== 'YearBuilt' ? inventoryUpdates[key].toLocaleString() : inventoryUpdates[key]}
                </span>
                <div className="flex ml-2 w-[150px]"> {/* Apply flex class here */}
                  <span className='font-normal'>
                    (originally: {key === 'FullBath' ? Math.round(compList['Bath'][index], 0) : key === 'Style' ? compList[key][index] : key !== 'YearBuilt' ? Math.round(compList[key][index], 0).toLocaleString() : Math.round(compList[key][index], 0)})
                  </span>
                </div>
              </div>
            </div>
          )
        }
      })}
        </div>
        <div className="flex items-center justify-center">
          {/* Cancel button */}
          <button
            type="button"
            onClick={() => { setConfirmModal(false); setInventoryUpdates({}); }}
            className="px-6 py-2.5 w-36 mr-4 rounded-md text-black text-sm font-semibold border-2 border-gray-300 border-inset outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => { setConfirmModal(false); updateDB(true, setLoading, inventoryUpdates, setInventoryUpdates, setConfirmModal, index); }}
            className="px-6 py-2.5 w-36 rounded-md hover:bg-[#14532d]  bg-[#166534] text-white text-sm font-bold border-none outline-none bg-red-500 hover:bg-red-600 active:bg-red-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
    </td>
  );
};

export default DBConfirmModal;
