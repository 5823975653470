import React, {useState } from "react";
import {useAdminSettingsStore} from '../components/store.js';

function Modal({header, message, option1, option2, onSelection}) { // feed header, and message into component to render (also ensure you have adarkened bg behind like w/ the loader.)
const getSidebarOpen = useAdminSettingsStore((state=>state.sidebarOpen))
const [modalVisible, setModalVisible] = useState(true);

const handleOptionClick = (selectedOption) => {
    // Call the callback function to pass the selection
    if (onSelection) { // idt we need this.
      onSelection(selectedOption);
    }
	setModalVisible(false);
  };


  return modalVisible ? (
	getSidebarOpen?
	<div className="fixed inset-0 flex flex-col gap-2 p-6 rounded-md z-[9999] jusetify-center ml-[18.25rem] w-96 h-64 m-auto shadow-md bg-black bg-opacity-80 dark:text-gray-100">
		<h2 className="flex items-center gap-2 text-xl font-semibold leadi tracki">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-6 h-6 fill-current shrink-0 dark:text-violet-400">
				<path d="M451.671,348.569,408,267.945V184c0-83.813-68.187-152-152-152S104,100.187,104,184v83.945L60.329,348.568A24,24,0,0,0,81.432,384h86.944c-.241,2.636-.376,5.3-.376,8a88,88,0,0,0,176,0c0-2.7-.135-5.364-.376-8h86.944a24,24,0,0,0,21.1-35.431ZM312,392a56,56,0,1,1-111.418-8H311.418A55.85,55.85,0,0,1,312,392ZM94.863,352,136,276.055V184a120,120,0,0,1,240,0v92.055L417.137,352Z"></path>
				<rect width="32" height="136" x="240" y="112"></rect>
				<rect width="32" height="32" x="240" y="280"></rect>
			</svg>{header}
		</h2>
		<p className="flex-1 dark:text-gray-400">{message}</p>
		<div className="flex flex-col justify-end gap-3 mt-6 sm:flex-row">
				<button className="px-6 py-2 rounded-sm bg-gray-400 text-white" onClick={()=>handleOptionClick(option1)}>{option1}</button>
			<button className="px-6 py-2 rounded-sm shadow-sm bg-gray-700 dark:bg-violet-400 text-white dark:text-gray-900" onClick={()=>handleOptionClick(option2)}>{option2}</button>
			</div>
	</div>
	:
	// WHen sidebar is NOT open:
	<div className="fixed inset-0 flex flex-col gap-2 p-6 rounded-md z-[9999] jusetify-center shadow-md bg-black bg-opacity-80 dark:text-gray-100">
		<div className=' w-96 h-fit m-auto bg-meta-2 dark:bg- p-6 rounded-md'> 
			<h2 className="flex items-center gap-2 text-xl font-semibold leadi tracki">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-6 h-6 fill-current shrink-0 dark:text-violet-400">
					<path d="M451.671,348.569,408,267.945V184c0-83.813-68.187-152-152-152S104,100.187,104,184v83.945L60.329,348.568A24,24,0,0,0,81.432,384h86.944c-.241,2.636-.376,5.3-.376,8a88,88,0,0,0,176,0c0-2.7-.135-5.364-.376-8h86.944a24,24,0,0,0,21.1-35.431ZM312,392a56,56,0,1,1-111.418-8H311.418A55.85,55.85,0,0,1,312,392ZM94.863,352,136,276.055V184a120,120,0,0,1,240,0v92.055L417.137,352Z"></path>
					<rect width="32" height="136" x="240" y="112"></rect>
					<rect width="32" height="32" x="240" y="280"></rect>
				</svg>{header}
			</h2>
			<p className="flex-1 dark:text-gray-400">{message}</p>
			{option2?
			<div className="flex flex-col justify-end gap-3 mt-6 sm:flex-row">
				{/* These color schemes are garbage. need to make generic if using again. */}
				<button className="px-6 py-2 rounded-sm bg-gray-400 text-white" onClick={()=>handleOptionClick(option1)}>{option1}</button>
			<button className="px-6 py-2 rounded-sm shadow-sm bg-gray-700 dark:bg-violet-400 text-white dark:text-gray-900" onClick={()=>handleOptionClick(option2)}>{option2}</button>
			</div>
			:
			<div className="flex flex-col justify-end gap-3 mt-6 sm:flex-row">
				<button className="px-6 py-2 rounded-sm bg-gray-700 text-white" onClick={()=>handleOptionClick(option1)}>{option1}</button>
			</div>
			}
		</div>
	</div>
	)
	:
	null
}export default  Modal