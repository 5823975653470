import { toast } from 'react-toastify';
import axios from 'axios';

export const handleApiError = (error, customErrorMessage = 'An unexpected error occurred.', setLoading) => {
    console.error(`${customErrorMessage}:`, error);
    console.log(error)
    setLoading(false);
    // Display the specific error message using react-toastify
    if (axios.isAxiosError(error)) {
      if (error.response) {
        toast.error(`Error: ${error.response.status} - ${error.response.data.message}`, {
          autoClose: false,
          closeOnClick: false,
          pauseOnHover: false
        });
      } else if (error.request) {
        toast.error('An error occurred while waiting for the response.', {
          autoClose: false,
          closeOnClick: false,
          pauseOnHover: false
        });
      } else {
        toast.error('An unexpected error occurred while setting up the request.', {
          autoClose: false,
          closeOnClick: false,
          pauseOnHover: false
        });
      }
    } else {
      toast.error(customErrorMessage, {
        autoClose: false,
        closeOnClick: false,
        pauseOnHover: false
      });
    }
  };