// import { signup, login, auth, passwordReset, logout, useAuth, googleProvider } from "../firebase";
import { signup, login, auth, useAuth, googleProvider } from "../firebase";
// import { , signInWithPopup, onAuthStateChanged, , sendPasswordResetEmail, signOut as googleSignOut } from "firebase/auth";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { signInWithRedirect, getRedirectResult, sendPasswordResetEmail, deleteUser, signOut as googleSignOut } from "firebase/auth";
import { useLocalDataStore, useAdminSettingsStore, usePersistedDataStore, useCompStore} from './store';
import { useRef, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';
import axiosInstance from '../axiosConfig.js'
// import { useNavigate } from "react-router-dom";

export default function Login({handlePasswordResetNavigation, setTempSigning}) {
    const setUser = useAdminSettingsStore((state) => state.setUser)
    const [signing, setSigning] = useState(true); //state if user is signing in or signing up.
    const [loginError, setLoginError] = useState(false);
    const [signupError, setSignupError] = useState(false);
    const [signupErrorText, setSignupErrorText] = useState(false);
    const [spinningLoad, setSpinningLoad] = useState(false);
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState(false)
    const [passwordReset, setPasswordReset] = useState(false)
    const buttonRef = useRef();
    const [emailSent, setEmailSent] = useState(false)
    // const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const currentUser = useAuth(); // This object contains the tokenID. I am sure there is an easier way to get this but all the wiring is functional for now.


    useEffect(() => {
      const storedError = localStorage.getItem('myAppError');
      if (storedError) {
        setError(true);
        localStorage.removeItem('myAppError');
      }
  
      const auth = getAuth();
      console.log(auth)
getRedirectResult(auth)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access Google APIs.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    // The signed-in user info.
    const user = result.user;
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });
    }, []);

    // useEffect(
    //   () => {
    //   console.log('this ran')
    //       if (user) {
    //           axiosInstance
    //               .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
    //                   headers: {
    //                       Authorization: `Bearer ${user.access_token}`,
    //                       Accept: 'application/json'
    //                   }
    //               })
    //               .then((res) => {
    //                   setProfile(res.data);
    //               })
    //               .catch((err) => console.log(err));
    //       }
    //   },[ user ]);
  
// Add in SSO for "sign in with google"
async function handleGoogleSSO(credentialResponse) {
    const decoded = jwtDecode(credentialResponse.credential);
    console.log(decoded)
    if (decoded.email.endsWith('@aventine.ai')) { // Adjust domain check
      try {
        setUser(true)
        // This is temporary and janky as hell. This needs to be revisited in earnest, and have JWT authenticaiton
        // Implemented on client + server side, and pass the jwt into fetch reqs the user then amkes.
        setTempSigning(true);
        // await signInWithRedirect(auth, googleProvider);
        setTempSigning(false);
      } catch (error) {
        console.error('Error during sign-in with credential:', error);
      }
    } else {
      localStorage.setItem('myAppError', 'Unauthorized domain. Please use an authorized email address.');
      await googleSignOut(auth);
    }
  setTempSigning(false);
}


    useEffect(() => {
        const handleKeyPress = (event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            // Trigger a click on the button element
            buttonRef.current.click();
          }
        };
        // Add event listener when the component mounts
        document.addEventListener('keydown', handleKeyPress);
      
        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
      }, [signing]);

      const handlePasswordReset = async () => {
      setSpinningLoad(true); // set microload within password reset email
      const auth = getAuth();
      try{

      await sendPasswordResetEmail(auth, emailRef.current.value);
    }catch (error) {
          if (error.code === 'auth/invalid-email') {
              setError(true); // haven't built this to display the error text yet.
              emailRef.current.value = '';
          }
      }
      setSpinningLoad(false)
      setEmailSent(true);
      // Display a temp state for the success thing.
      // Return text of "password reset email sent"
    //     // Set it to display a message about "password reset email has been sent"
    }

    // sign up for acct - make a specific post to instantiate a DEMO user (by default)
    async function handleSignup() {
    setSpinningLoad(true); // set state to prevent spamming of the button while request in in progress
    localStorage.clear();
    try {
      localStorage.firebaseAccess = await signup(emailRef.current.value, passwordRef.current.value);
      setSignupError(false);
    } catch (error) {
      setSignupError(true);
      // Conditional error code handling
    // Email already in use - Firebase
      if (error?.code === 'auth/email-already-in-use') {
        setSignupErrorText('Email already in use. Please try a different email');
        emailRef.current.value = '';
        passwordRef.current.value = '';
        console.log(error.code);
        // Invalid password - Firebase
      } else if (error?.code === 'auth/weak-password') {
        setSignupErrorText('Passwords need to be longer than 6 characters');
        passwordRef.current.value = '';
        console.log(error.code);
        // Invalid email - Firebase
      } else if (error?.code === 'auth/invalid-email') {
        setSignupErrorText('Invalid email. Please use a different email.');
        emailRef.current.value = '';
        passwordRef.current.value = '';
        console.log(error.code);
        // Username taken - TheQ
      } else if(error.response?.data.errorMessage === 'Invalid username'){
        setSignupErrorText('Invalid Username.');
        passwordRef.current.value = ''; // do we even want to clear this?
        const user = auth.currentUser;
        await deleteUser(user)
        .then(() => {console.log('The user has been deleted with Firebase')})
        .catch((error) => {console.log('The Firebase error was: ', error)})
      }
      else if(emailRef.current.value===''||passwordRef.current.value===''){
        setSignupErrorText('Please fill out required fields.');
      }
      else {
        setSignupErrorText('Unknown error occured. Please contact support');
        console.log(error);
      }
    }
    setSpinningLoad(false);

    // set your user object, and have it redirect into the application
    }

  // sets signing state - renders different Login screen.
  function swapToSignIn(){
    // let flip = !signing
    setSigning(!signing);
    emailRef.current.value = '';
    passwordRef.current.value = '';
    setSignupError(false)
    setSignupErrorText('')
    setLoginError(false)
  }

  // log into game
    async function handleLogin(){
        setSpinningLoad(true) // can't even see this, idk if necessary.
    try {
        localStorage.firebaseAccess = await login(emailRef.current.value, passwordRef.current.value)
        setLoginError(false);
        window.scrollTo(0,0);
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoginError(true);
        emailRef.current.value = '';
        passwordRef.current.value = '';
        console.log(errorCode+': '+errorMessage);
        }
        setSpinningLoad(false)
        // If currentuser -> then navigate
    }

  // JSX
  return (
    <div className="flex justify-center mt-12 ">

        {!passwordReset? // if the user is trying to reset their password, display password reset componenet (below)
        <div className="w-full max-w-md p-4 rounded-md shadow sm:p-8 dark:bg-gray-900 dark:text-gray-100 bg-white">
        {signing? // sign in vs. sign up ternary
        <>
            <h2 className="mb-3 text-3xl font-semibold text-center">Login to your account</h2>
            <p className="text-sm text-center dark:text-gray-400">Dont have an account? 
                <button rel="noopener noreferrer" className="focus:underline hover:underline text-primary"
                onClick={swapToSignIn}
                > Sign up here</button>
            </p>
        </>
        :
        <>
        <h2 className="mb-3 text-3xl font-semibold text-center">Sign up for an account</h2>
            <p className="text-sm text-center dark:text-gray-400">Already have an account? 
                <button rel="noopener noreferrer" className="focus:underline hover:underline text-primary dark:text-violet-400"
                onClick={swapToSignIn}
                > Sign in here</button>
            </p>
        </>
        }

        <div className="my-6 space-y-4">
        <form noValidate="" action="" className="">
            <div className="space-y-4">
                <div className="space-y-2">
                    <label htmlFor="email" className="block text-sm">Email address</label>
                    <input type="email" name="email" ref={emailRef} id="email" placeholder="email@address.com" className="w-full px-3 py-2 border rounded-md dark:border-gray-700 dark:bg-gray-900 dark:text-gray-100 focus:dark:border-violet-400" />
                </div>
                <div className="space-y-2">
                    <div className="flex justify-between">
                        <label htmlFor="password" className="text-sm">Password</label>
                        <button rel="noopener noreferrer" onClick={()=>setPasswordReset(true)} className="text-xs hover:underline dark:text-gray-400">Forgot password?</button>
                    </div>
                    <input type="password" ref={passwordRef} name="password" id="password" placeholder="••••••••" className="w-full px-3 py-2 border rounded-md dark:border-gray-700 dark:bg-gray-900 dark:text-gray-100 focus:dark:border-violet-400" />
                </div>
            </div>

            {loginError ? // generic error handling message.
            <div className="relative">
            <div className="absolute inset-x-0 flex items-center justify-center">
              <div className="bg-red-500 text-danger px-4 rounded">
                Invalid email or password. Please try again.
              </div>
            </div>
          </div> 
            : 
            null}

            {signupError ? 
            <div className="relative">
            <div className="absolute inset-x-0 flex items-center justify-center">
                <div className="text-danger text-sm">{signupErrorText ? signupErrorText : null}</div>
            </div> 
            </div>
            : 
            null}

        {/* Add in loading component WITHIN the button (and make it white) */}
            <button type="button" className="w-full flex bg-primary items-center justify-center text-center text-white px-8 py-3 mt-8 font-semibold rounded-md dark:bg-violet-400 dark:text-gray-900"
            onClick={signing ? handleLogin : handleSignup}
            ref={buttonRef}
            disabled={spinningLoad}
            >{signing ? 'Sign in':'Sign up'}
                {spinningLoad? // spinning loader
                <div className="ml-2 flex items-end justify-between">
                    <div className="flex items-center justify-center mt-0.5">
                        <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent dark:border-gray-900 dark:border-t-transparent"></div>
                    </div>
                </div>
                :
                null            
                }
            </button>
        </form>
    </div>

    {/* Break and google SSO below. If other options, can make this a small grid 3 w/ just logo.*/}
        <div className="flex items-center w-full my-4">
            <hr className="w-full dark:text-gray-400"/>
            <p className="px-3 dark:text-gray-400">OR</p>
            <hr className="w-full dark:text-gray-400"/>
        </div>
            {/* <button aria-label="Login with Google" type="button" className="flex items-center justify-center w-full p-4 space-x-4 border rounded-md focus:ri focus:ri dark:border-gray-400 focus:ri"
            onClick={handleGoogleSSO}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="w-5 h-5 fill-current">
                    <path d="M16.318 13.714v5.484h9.078c-0.37 2.354-2.745 6.901-9.078 6.901-5.458 0-9.917-4.521-9.917-10.099s4.458-10.099 9.917-10.099c3.109 0 5.193 1.318 6.38 2.464l4.339-4.182c-2.786-2.599-6.396-4.182-10.719-4.182-8.844 0-16 7.151-16 16s7.156 16 16 16c9.234 0 15.365-6.49 15.365-15.635 0-1.052-0.115-1.854-0.255-2.651z"></path>
                </svg>
                <p>Login with Google</p>
            </button> */}

            {/* GOOGLE LOGIN #2 */}
            <div className='flex items-center justify-center w-full p-4 space-x-4 border rounded-md focus:ri focus:ri dark:border-gray-400 focus:ri'>
            <GoogleLogin
      clientId="511916914273-ip82hd3h57cjkjc00o6qoromqh89d32e.apps.googleusercontent.com"
      ux_mode="redirect"
      // Redirect URI seemst o not be working atm.
      redirectUri="http://localhost:3000/oauth2callback"
      onSuccess={(response) => {
        console.log(response); // This won't be used in redirect mode
      }}
      onFailure={(error) => {
        console.log('Login Failed:', error);
      }}
    />

            </div>
            {error ? ( //prevent logins from an unauthorized domain.
              <div className="relative text-center">
                <div className=" inset-x-0 flex items-center justify-center">
                  <div className="bg-red-500 text-danger px-4 rounded">
                    Unauthorized domain, please contact the team if you believe this is in error
                  </div>
                </div>
              </div>
            ) : null}
    </div>
    :

    !emailSent?
    <div className="w-full max-w-md p-4 rounded-md shadow sm:p-8 dark:bg-gray-900 dark:text-gray-100 bg-white flex flex-col justify-between">
        <h2 className="mb-3 text-4xl font-semibold text-center">Forgot Password</h2>
        <p className="text-md text-center dark:text-gray-400 mb-2">Enter your email address
        </p>
        {/*  */}
        <div className="space-y-2">
            <input type="email" name="email" ref={emailRef} id="email" placeholder="email@address.com" className="w-full px-3 py-2 border rounded-md dark:border-gray-700 dark:bg-gray-900 dark:text-gray-100 focus:dark:border-violet-400" />
        </div>
        <button
        type="button"
        className="flex w-full bg-primary items-center justify-center text-white px-8 py-3 mt-4 font-semibold rounded-md dark:bg-violet-400 dark:text-gray-900 self-end"
        onClick={handlePasswordReset}
        ref={buttonRef}
        disabled={spinningLoad}
        >
        Reset Password
        {spinningLoad? // spinning loader
                <div className="ml-2 flex items-end justify-between">
                    <div className="flex items-center justify-center mt-0.5">
                        <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent dark:border-gray-900 dark:border-t-transparent"></div>
                    </div>
                </div>
                :
                null            
                }
    </button>
    </div>
    :
    <div className="w-full max-w-md p-4 rounded-md shadow sm:p-8 dark:bg-gray-900 dark:text-gray-100 bg-white flex flex-col justify-between">
    <h2 className="mb-3 text-4xl font-semibold text-center">Email Sent!</h2>
        <p className="text-md text-center dark:text-gray-400 mb-2">Follow the steps in your email to reset your password.
        </p>
        <button
        type="button"
        className="flex w-full bg-primary items-center justify-center text-white px-8 py-3 mt-4 font-semibold rounded-md dark:bg-violet-400 dark:text-gray-900 self-end"
        onClick={()=>navigate('/')}
        ref={buttonRef}
        disabled={spinningLoad}
        >
        Back
        </button>
    </div>
    }
    {currentUser ?
      <Navigate to='/dashboard'></Navigate>
      :
      null}
</div>



  );
}