import React from 'react';
import {useLocalDataStore,} from './store';

function Dropdown({
  dropdownLabel,
  handleChange,
  displayValue,
  dropdownOptions,
  format,
  isOpen,
  setDropToggle,
  type,
}) {
  const setIsCountyOpen = useLocalDataStore((state)=> state.setIsCountyOpen)
  const setIsMuniOpen = useLocalDataStore((state)=> state.setIsMuniOpen)
  const setIsTaxYearOpen = useLocalDataStore((state)=> state.setIsTaxYearOpen)
  const setIsVillageOpen = useLocalDataStore((state)=> state.setIsVillageOpen)
  // Format stuff should be cleaned up?

  const toggleDropdown = () => { 
      setIsCountyOpen(false)
      setIsMuniOpen(false)
      setIsTaxYearOpen(false)
      setIsVillageOpen(false)
      setDropToggle(!isOpen)
  };

  const formatDisplayValue = (value) => {
    if(type === 'T/F'){
      return value ? "True" : "False"; // Example formatting
    }else{
    return value
    }
  };

  const renderDropdownContent = () => {
    if (isOpen) {
      return (
        <div className="absolute inline-block min-w-[150px] max-w-[350px] right-0 mt-1 border bg-white dark:bg-boxdark dark:[color-scheme:dark] rounded-md shadow-md  max-h-[55vh]  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 overflow-y-scroll whitespace-nowrap z-50 ">
          <ul id={dropdownLabel}>
          {dropdownOptions.map((value, index) => (
      <li key={index}>
        <button
        // Ugly, but seperate logic if a True false drop instead of regular muniobj.
           onClick={(e) => { type === 'T/F' || type==='regular' ? handleChange(value) : handleChange(e); toggleDropdown(); }}
          className="block w-full hover:bg-meta-2 dark:hover:bg-meta-4 dark:bg-[#1B222C] px-1 py-1 z-20000"
          value={value}
        >
          {formatDisplayValue(value)}
          </button></li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };
  
  return (
    <div className={`flex max-h-[35px] content-center items-center ${format === 'comps' ? 'justify-end':'justify-center'}`}>
      <span className={`font-bold pr-2 text-[0.9rem] ${format === 'admin' ? '' : ''} `}>
        {dropdownLabel}:
      </span>
      <div className="relative">
        <button
          onClick={() => toggleDropdown()}
          id={dropdownLabel}
          className={`flex items-center px-1 border rounded-md justify-end bg-meta-2 max-w-[230px] ${type === 'regular' || type=='regularMuni' ?'max-w-[300px] px-1.5 py-0.5 ':'min-w-[80px]'}`}
        >
          {formatDisplayValue(displayValue)}
            <svg
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ pointerEvents: 'none' }}
              className="w-4 h-4 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z"
              />
            </svg>
        </button>
        {renderDropdownContent()}
      </div>
    </div>
  );
}

Dropdown.whyDidYouRender = true;
export default React.memo(Dropdown);
