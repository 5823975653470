import './wdyr';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './components/App';
import './satoshi.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {ErrorBoundary} from 'react-error-boundary'
import { checkVersion } from './check-version';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// Run version check
// checkVersion();

window.addEventListener('error', (e) => {
  if (e.message.includes('Unexpected token <')) {
      window.location.reload(true); // Force a reload from the server
  }
});

root.render(
  <BrowserRouter>
  <GoogleOAuthProvider clientId="511916914273-ip82hd3h57cjkjc00o6qoromqh89d32e.apps.googleusercontent.com">
    <ErrorBoundary fallback={<div className='text-danger text-xl mt-8 m-auto text-center'>Uncaught error, please reload the page.</div>}>
      <App />
    </ErrorBoundary>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

serviceWorkerRegistration.register({
  onUpdate: async registration => {
    // alert('New version available!  Ready to update?');
    // React toastify here instead
    toast.info(
      'New version available! Click here to update', {
      autoClose: false, // Disable auto-closing
      onClick: async () => {
        if (registration && registration.waiting) {
          await registration.unregister();
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();
        }
      }
    });
  }
});

reportWebVitals();