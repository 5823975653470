import React, { useState, useEffect } from 'react';
import { useLocalDataStore, useCompStore, useAdminSettingsStore, usePersistedDataStore } from './store';
import { ToastContainer, toast } from 'react-toastify';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../axiosConfig.js'
import _ from 'lodash';
import {handleApiError} from '../common/handleApiError.js'

const WeightedFactorSliders = ({ defaultFactorWeights, initializationFetch, setDefaultFactorWeights, setLoading, style, setWeightWidget, dispLabels }) => {
    const getCompSheet = usePersistedDataStore((state) => state.compSheet)
    const setCompSheet = usePersistedDataStore((state) => state.setCompSheet)
    const getFetchLoad = usePersistedDataStore((state)=> state.fetchLoad)
    // const [loading, setLoading] = useState(false) // took this out of the top level, maybe use it if it doesn't work to rendre loader locally only.
    const [factorWeights, setFactorWeights] = useState({}); // updating two diff states, causes two re-renders.
    // const comp = usePersistedDataStore((state) => state.caseNumber)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
    const MuniCode = queryParams.get('MuniCode') || 'All';
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const getOptimizationWeights = useLocalDataStore((state) => state.optimizationWeights)
    const setOptimizationWeights = useLocalDataStore((state) => state.setOptimizationWeights)
    const setOptimizedComps = usePersistedDataStore((state)=> state.setOptimizedComps)
    
    // Add a rubric to view, add sucess thing for model updating
    // 2 = basically needs to match, only show me soemthign else if you're pretty much out of data in that category
    // 1.5 = only deviate out of htis category in extreme cases
    // 1, unless these things are QUITE different, these things better match
    // SD code is being set to 1 and not exposed rn
    // if you wanted to do a distance sort, you could basically turn up to 10 for anything. (maybe 100? need to test)
    // When updatign weights, if you wanted to see distance as more important, you'd set it = to the value of the one above it
    //  (ex set it to 0.8 which is what sqft is rn), and see how it is. If not enough, then switch them, dist = 0.8 sqft = 0.7
    
    // console.log(factorWeights)
    // once you hit apply, it runs the sorted factors.
    const handleSliderChange = (factor, weight) => {
        // Ensure the weight is within the desired range of 0.5 to 2
        weight = Math.min(Math.max(weight, 0.5), 2);

        // Calculate the position of the slider input value (blue dot) based on the weight
        const position = weight === 1 ? '50%' : weight === 2 ? '100%' : '0%';

        if(style==='Optimization'){
          setOptimizationWeights({...getOptimizationWeights,[factor]:parseFloat(weight)})
        }

        setFactorWeights(prevWeights => ({
            ...prevWeights,
            [factor]: parseFloat(weight)
        }));
      
        // Update the style of the slider input value (blue dot)
        const slider = document.getElementById(`slider-${factor}`);
        if (slider) {
            slider.style.setProperty('--position', position);
        }
    };

    async function loadWeights() {
      try {
        if(Object.keys(getOptimizationWeights) && style==='Optimization'){
          const entries = Object.entries(getOptimizationWeights);
          entries.sort((a, b) => b[1] - a[1]);
          // Convert sorted entries into a single object
          const sortedWeights = Object.fromEntries(entries);
          setFactorWeights(sortedWeights)
          return
        }
        const response = await axiosInstance.get(`/load_weights`);
        // const response = await axiosInstance.get(`/test_db`);
        const weightObj = response.data;
        console.log(weightObj);
    
        setOptimizationWeights(weightObj.optim_params); // Set your optimization weights.
    
        const entries = Object.entries(weightObj.weights);
        entries.sort((a, b) => b[1] - a[1]);
    
        // Convert sorted entries into a single object
        const sortedWeights = Object.fromEntries(entries);
        
        console.log(sortedWeights); // Check the sorted weights object
        setFactorWeights(sortedWeights); // Set the factor weights with sorted key-value pairs
    
      } catch (error) {
        handleApiError(error, 'Error loading model weights', setLoading);
      }
      
        setLoading(false);
    }
    

useEffect(()=>{
  setLoading(true)
  loadWeights()
},[])

    const handleInputChange = (factor, weight) => { // fixed
      console.log(factor,weight)
        weight = weight.replace(/[^0-9.]/g, ''); // Use a regular expression to allow only numbers and decimals
        
        // If you are updating optimization weights ternary, this should obviously be combined at some point.
        setFactorWeights(prevWeights => ({ // Update the input value directly without modifying the slider value
            ...prevWeights,
            [factor]: parseFloat(weight)
        }));
        if(style==='Optimization'){
          setOptimizationWeights({...getOptimizationWeights,[factor]:parseFloat(weight)})
        }
    };

    async function updateWeights(sortedFactorWeights) { // seend your weights to the backend. Should ONLY use sortedfactorweight
    console.log('running weights update')
    console.log(sortedFactorWeights)
      if(getFetchLoad){ // now redundant code.
      toast.error('Fetch is still running, please wait')
      setWeightWidget(false)
      }else{
        setLoading(true);
        if(location.pathname=='/table'){
          setWeightWidget(false)
        }

        // Construct muniObj for fetch, then perform the initialization fetch for clients you run.
        let muniObj = {}
        // Only run this if cases is blank
        if(getNegotiationObj.cases.length<1){
          console.log('fetching ')
          muniObj = {
            MuniCode: MuniCode,
            TaxYear: taxYear,
            ...(courtDate !== '' ? { CourtDate: courtDate, CourtRun: 1 } : { CourtRun: 0 } ),
          }
        const updatedCases = await initializationFetch(muniObj);
          muniObj.cases = updatedCases
        }else{
          muniObj = {
            ...getNegotiationObj,
            MuniCode: MuniCode,
            TaxYear: taxYear,
            ...(courtDate !== '' ? { CourtDate: courtDate, CourtRun: 1 } : { CourtRun: 0 } ),
          }

        }
        if(muniObj.cases.length===0){
        setCompSheet([])
        }else{
        console.log(`Starting Weights update + KNN fetch`)
        const casePIDs = muniObj.cases.map(obj => obj.PID);
        try {
            let subjId = '';
            if (getCompSheet) { //sets the subject parcel ID
                subjId = getCompSheet[comp].parcel_id[0];
            }
            console.log(muniObj)
            const response = await axiosInstance.post(`/update_weights`, {
                weights: {...sortedFactorWeights},
                optimizationWeights: {...getOptimizationWeights},
                uids: casePIDs, // Include the casePIDs array as 'uids' parameter
                ...muniObj,
                subj_id: subjId,
            });
            const data = response.data;
            const updatedComps = JSON.parse(data);

            // Update regular comps with full array, updated optimized with full array
            setCompSheet(updatedComps[0])
            setOptimizedComps(updatedComps[1])
            console.log(updatedComps)  // temporary to see about updating things.
            
            // Sorts your weights you get back int he correct order. Dumb code.
            const entries = Object.entries(sortedFactorWeights);
            entries.sort((a, b) => b[1] - a[1]);
            let value = entries.map(([key, value]) => key);

            // if(style!=='Optimization'){ // Dumb split weights logic, optimization ewights in a seperate variable.
            //   setDefaultFactorWeights(value); // only set the defaults from the fetch response.
            // }
            setFactorWeights(sortedFactorWeights);

            toast.success('Model weights have been updated successfully! ✅', {
                position: 'top-right',
                className: 'mt-10',
                autoClose: 3000,
            });
            setLoading(false)

            return response;
        } catch (error) {
        handleApiError(error, 'Error updating weights', setLoading);
        }
        }
      }
    }

    const applyUpdate = () => {
        {
            const sortedFactors = Object.keys(factorWeights).sort((a, b) => { // Sort the factors based on their existing factorWeights in descending order
                return factorWeights[b] - factorWeights[a];
            });
            const sortedFactorWeights = {}; // Create a new object to store the sorted factor weights            
            sortedFactors.forEach(factor => { // Loop through the sorted factors and update the sorted factor weights
              sortedFactorWeights[factor] = factorWeights[factor];
                if (factor === 'Latitude') {
                  sortedFactorWeights[factor] = factorWeights['Longitude'];
                } else if (factor === 'HalfBath') {
                  sortedFactorWeights[factor] = factorWeights['Bath'];
                }
            });
            updateWeights(sortedFactorWeights) // run the async fetch
            // setDefaultFactorWeights(sortedFactorWeights)
            // location.reload()
          }
            // its re-running on th ebackend, so you just need to update a state for re-render.
    };
    // Define custom scale for the slider's min and max values
    const sliderMin = 0;
    const sliderMax = 2;
    const sliderStep = 0.05;
  
    return (
      <div className="">
        <div className="grid grid-cols-1">
          {factorWeights!==null ?
          Object.keys(factorWeights).map(factor => {
            const weight = factorWeights[factor];
            
            // Check if the weight is outside the desired range
            const isOutOfRange = weight < sliderMin || weight > sliderMax;

            if( factor==='Latitude' || factor==='HalfBath' || factor==='SaleDate' || factor==='subj_id' || factor==='SalePrice' || factor==='Fireplaces' || factor==='Bath' || factor==='IFMV_Village'){ // hiding latitude for the moment. For updating, need to set this value to the be same
              // console.log(`factor being skipped: ${factor}`)
              return
            }else
            return (
            factor==='SaleDate'?
            null
            :
            <div className="flex items-center" key={factor}>
              {dispLabels? // show labeels if you teell it to.
              <div className={` ${style === 'Optimization' ? 'w-[125px]' : 'w-24'} `}>
                <span className="text-right font-semibold">{factor}:</span>
              </div>
              :
              null
              }
              
                <div className="flex items-center h-[28px] mb-2">
                    <input
                    type='number'
                      className="appearance-none h-[28px] border dark:text-white rounded py-0.5  px-0.5 text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-[42px] mr-2"
                      value={weight}
                      onChange={(e) => handleInputChange(factor, e.target.value)}
                    />
                    <input
                      type="range"
                      min={sliderMin}
                      max={sliderMax}
                      step={sliderStep}
                      value={weight}
                      className={`${weight > 6 ? "slider-2" : weight <= 0 ? "slider-0" : 'slider-def'} appearance-none h-3 rounded-lg focus:outline-none focus:shadow-outline relative`}
                      onChange={(e) => handleSliderChange(factor, Number(e.target.value))}
                        style={{ background: `linear-gradient(to right, ${isOutOfRange ? 'red' : '#2563EB'} 0%, ${isOutOfRange ? 'red' : '#2563EB'} calc(${(weight - sliderMin) / (sliderMax - sliderMin)} * 100%), #d1d5db calc(${(weight - sliderMin) / (sliderMax - sliderMin)} * 100%), #d1d5db 100%)` }}
                        key={factor}
                        id={`slider-${factor}`} // Add an ID to the slider input element
                    />
                </div>
            </div>
            )}
            )
            :
            null
            }
      </div>
      <div className='flex my-2 justify-between'>
      <div></div>
      {style!=='Optimization'?
      <button onClick={applyUpdate} className="flex mr-2 bg-primary text-center w-[103px] items-center  text-white content-center font-semibold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
        Save Model
      </button>
      :
      null}
      </div>
    </div>
  );
};
export default WeightedFactorSliders;