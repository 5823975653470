import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, isAuthenticated, fallbackPath = '/signin' }) => {
  return isAuthenticated ? (
    element
  ) : (
    <Navigate to={fallbackPath} />
  );
};

export default PrivateRoute;