import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';

export default function useUpdateInventory() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1

  function updateInventory(selectedOption, originalValue, key, setValue, index, compList, inventoryUpdates, setInventoryUpdates) {
    // Thios function runs and sets the index of 85 for last rendering. it is running unnecessarily every time.

    // Update this so that the "key" is an array of the keys, and this function just iterates over them.
    // Should let you remove all the conditional logic below as long as keys are properly mapped

    // For key in keys, do the same with originalvalue[key]
    if(key!=='Bath' && key!=='None'){
    originalValue = originalValue[key][index]
    }
    const updatedInventory = { ...inventoryUpdates };
    // This is not an array 

    if(typeof selectedOption === 'number' && isNaN(selectedOption)){ // don't let user enter function if no value.
      console.log('No value, breaking')
      delete updatedInventory[key];
    }else{
    if(key==='Bath'){
      // Need to check if the selected option label + value are equal to their respective values in compList
      if(selectedOption.Bath === compList[key][index]){
        // console.log('Full baths same - delete update')
        delete updatedInventory['FullBath'];
      }
      if(selectedOption.HalfBath === compList['HalfBath'][index]){
        // console.log('Half Baths same - delete update')
        delete updatedInventory['HalfBath'];
      }
      if (selectedOption.Bath !== originalValue.Bath) { // set the new updated inventory value
        // console.log('New value input, adding to updatedInventory Object')
          updatedInventory['FullBath'] = selectedOption.Bath; // update value to add Bath as an update
          // setInventoryUpdated(true);
      }
      if (selectedOption.HalfBath !== originalValue.HalfBath) { // set the new updated inventory value
        // console.log('New value input, adding to updatedInventory Object')
          updatedInventory['HalfBath'] = selectedOption.HalfBath; // update value to add Bath as an update
          // setInventoryUpdated(true);
      }
    }else if(selectedOption!=="None"){ // for NON bath changes:
      // Create a copy of the current inventoryUpdates state
      if((selectedOption==='Waterfront' || selectedOption==='Waterview') && compList[key][index] === 1){
        console.log('dete wf!@') // this deletes for the subject when same, but idk why.
        delete updatedInventory['Waterfront'];
        delete updatedInventory['WaterView'];
      }else if(selectedOption === compList[key][index]) {
        console.log('this is same as original value - deleted update.') // Remove the value from the updated inventory
          delete updatedInventory[key];
      }else if (selectedOption !== originalValue) { // set the new updated inventory value
        console.log('setting val')
          updatedInventory[key] = setValue;
        }
      }
      if(selectedOption==='None'){
        updatedInventory['Waterfront'] = 0;
        updatedInventory['WaterView'] = 0;
        if(compList.WaterView[index] === 0 && compList.Waterfront[index] === 0){
        console.log('dete wf!@')
        delete updatedInventory['Waterfront'];
        delete updatedInventory['WaterView'];
        }
      }
    } 

    // Set the updated inventoryUpdates state
    updatedInventory['Index'] = index
    console.log(updatedInventory)
    setInventoryUpdates(updatedInventory);
  }
  return updateInventory;
}