import axios from 'axios';

let baseURL

const environment = process.env.REACT_APP_ENV || 'production';
console.log('Environment:', environment);

if (process.env.REACT_APP_ENV === 'production') { // this is where your post url gets set.
  // Code to run in development mode
  baseURL = 'https://api.proprietyrealty.com'
} else if (process.env.REACT_APP_ENV === 'staging') { // this is where your post url gets set.
    // baseURL = 'http://3.139.190.156:8000/'
    baseURL = 'https://staging.proprietyrealty.com/'
  } else {
    // baseURL = 'https://3.139.190.156:8000/'
  baseURL = process.env.REACT_APP_API_BASE_URL
  }

  console.log(baseURL)

const axiosInstance = axios.create({
  baseURL: baseURL, // Your API base URL
  timeout: 900000000, // Adjust timeout as needed - was 900000 before
  headers: {
    'Content-Type': 'application/json',
    // You may have other default headers here
  },
  referrerPolicy: {
    policy: 'strict-origin-when-cross-origin'
  }
});

export default axiosInstance;