import React from 'react';
import * as XLSX from 'xlsx';
import {useCompStore, usePersistedDataStore} from './store';
// Add in RAR into this download too?

const DownloadXLSButton = ({ jsonData, fileName, keys }) => {
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
  console.log(getNegotiationObj)
  const downloadXLS = () => {
    // Define the desired order of keys
    console.log(jsonData)

    // Filter out undefined or null values and reorder keys in the JSON data
    const reorderedData = jsonData.map(item => {
      const reorderedItem = {};
      for (const key of keys) {
        const value = item[key];
        if(key==='RAR'){
          reorderedItem[key] = getNegotiationObj['RAR']; // Set undefined, null, or NaN values to an empty string
        } else
        if (value === undefined || value === null || (typeof value === 'number' && Number.isNaN(value))) {
          reorderedItem[key] = ''; // Set undefined, null, or NaN values to an empty string
        } else{
          reorderedItem[key] = value; // Keep all other values, including strings
        }
      }
      return reorderedItem;
    });

    // Convert reordered JSON to worksheet
    const ws = XLSX.utils.json_to_sheet(reorderedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Write the workbook to a file
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <button onClick={downloadXLS} className='flex mx-auto w-fit px-3 py-2 font-semibold rounded bg-violet-400 text-gray-800'>
      Download XLS
    </button>
  );
};

export default DownloadXLSButton;
