import WeightedFactor from './WeightedFactorSliders';
import {useLocalDataStore, usePersistedDataStore, useAdminSettingsStore } from './store';
import React, { useState } from "react";
import Dropdown from './Dropdown';
import { toast } from 'react-toastify';

function SettingsWidget({nyDataObj, setWeightWidget, setLoading, initializationFetch}){
    const [compNumberOpen, setCompNumberOpen] = useState(false)
    const getOptimizationWeights = useLocalDataStore((state) => state.optimizationWeights)
    const setDefaultComps = useAdminSettingsStore((state) => state.setDefaultComps)
    const getDefaultComps = useAdminSettingsStore((state) => state.defaultComps)
    const [compNumber, setCompNumber] = useState(getDefaultComps)
    const [activeTab, setActiveTab] = useState(1);
    
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    function handleSettingsOpen(openVal) {
        setCompNumberOpen(openVal)
    }
    
    function handleSettingsChange(e){
        setCompNumber(parseInt(e.target.textContent))
    }
    function updateDefaultSettings(){
        console.log('updating comps')
        setDefaultComps(compNumber) // set your default comps state
        setWeightWidget(false)
        toast.success('Default settings updated ✅', {
            position: 'top-left',
            className: 'mt-10',
            autoClose: 3000,
        });
    }

    return(
        // Set z to 1001 to be above the zoom.
        <div className="fixed top-0 bottom-0 left-0 right-0 z-[1001] flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative p-6 bg-white  w-[850px] h-[625px]">
                <div className="absolute top-0 right-0" onClick={()=>setWeightWidget(false)}>
                <button>
                    <svg className='' xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FF0000" viewBox="0 0 32 32">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    transform="scale(2)"
                    />
                    </svg>
                </button>
                </div>

                {/* Section to select which tab you're in (then conditionally render rest based on that) */}
            <div className="flex flex-col">
                <div className="flex space-x-4">
                <button
                    className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => handleTabClick(1)}
                    >
                    Default Settings
                    </button>
                    <button
                    className={`tab-button ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => handleTabClick(2)}
                    >
                    Weights
                    </button>
                    
                    {/* <button
                    className={`tab-button ${activeTab === 3 ? 'active' : ''}`}
                    onClick={() => handleTabClick(3)}
                    >
                    Tab 3
                    </button> */}
                </div>
                <div className="mt-4">
                {activeTab === 1 && 
                    <div className='grid grid-cols-2 gap-4 justify-center'>
                        <Dropdown
                            handleChange={handleSettingsChange}
                            style='settings'
                            dropdownLabel='Number of Comps' 
                            displayValue={compNumber}
                            isOpen={compNumberOpen}
                            setDropToggle={handleSettingsOpen}
                            dropdownOptions={[1,2,3,4,5,6,7,8,9,10,15]}
                            dropdownClasses='origin-top-left left-0'
                        />

                        {/* <Dropdown
                            handleChange={handleSettingsChange}
                            style='settings'
                            dropdownType='Number of Comps' 
                            displayValue={compNumber}
                            isOpen={compNumberOpen}
                            setDropToggle={handleSettingsOpen}
                            nyDataObj={{1:1, 3:3, 5:5, 7:7, 10:10, 15:15 }}
                            dropdownClasses='origin-top-left left-0'
                        /> */}
                        <div>
                            Other sample feature
                        </div>
                        
                    {/* Add more Dropdown components here as needed */}
                    <div className="col-span-2 flex justify-center">
                        <button onClick={()=>updateDefaultSettings()} className="bg-primary text-center items-center text-white content-center font-semibold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                            Update Settings
                        </button>
                    </div>
                </div>
                    }
                    {/* Content for each tab */}
                    {activeTab === 2 &&  // The Weights sliders (test that the update functions work)
                        <div className="grid grid-cols-2">
                        <div>
                        <div className="justify-center text-center mb-4 font-bold">
                        Update Model Weights
                        </div>
                            {/* Input a div which puts a border around the weight widget, */}
                            <WeightedFactor initializationFetch={initializationFetch} nyDataObj={nyDataObj} dispLabels={true} setWeightWidget={setWeightWidget} setLoading={setLoading}/>
                        </div>
                        {/* Here input your model params */}
                        <div className="justify-center text-center mb-4 w-[350px]">
                            <div className="font-bold">
                            Update Optimizaiton Weights
                            </div>
                            <div>
                            {/* This for optimization values */}
                            <WeightedFactor initializationFetch={initializationFetch} nyDataObj={nyDataObj} style={'Optimization'} factorWeights={getOptimizationWeights} dispLabels={true} setWeightWidget={setWeightWidget} setLoading={setLoading}/>
                            </div>
                        </div>
                    {/* See if you can re-instantiate the weightedFactor thing w/ these vals */}
                    {/* value_delta_cutoff (0.6 means would like to see comps that imply up to 40% reduction)
                    value_delta_wgt (weight assigned to reduction aggressiveness, e.g. want to see comps with bigger redunctions)
                    price_delta_wgt (weight assigned to superior comp preference, 0 means dont care about superior comps larger number means prioritize them, can discuss with your dad if he wants us to use a different measurement of superior)
                    distance_metric_weight (weight assigned to absolute similarity in optimization step, implicitly 1 unless otherwise specified) */}
                        </div>
                    }
                    
                    

                    {activeTab === 3 && <div>Tab 3 Content</div>}
                </div>
                </div>

            
        </div>
    </div>
    )
} export default SettingsWidget