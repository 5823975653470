import React, { useRef, useState, useMemo } from 'react';
import { useCompStore, useAdminSettingsStore, usePersistedDataStore, useLocalDataStore } from './store';
import DropdownStyle from './DropdownStyle'; // Import the DropdownStyle component
import _, { truncate, isUndefined } from 'lodash';
import DBConfirmModal from '../common/DBConfirmModal'
import InputSelect from './InputSelect';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';

const AdditionalCompsRow = React.memo(({ index, compList, caseMode, editValues, setLoading, setEditValues, setEditIndex, editIndex, handleCopy, updateInventory }) => {
  const getStyleOptions = usePersistedDataStore((state) => state.styleOptions);
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
  const village = queryParams.get('village') || null ; // Subtract 1
  // const getCaseNumber = usePersistedDataStore((state) => state.caseNumber);
  const valuationDateObj = useMemo(() => new Date('2022-07-01'), []);
  const inputDateObj = useMemo(() => new Date(compList?.SaleDate[index]), [compList, comp, index]);
  const timeDifference = inputDateObj - valuationDateObj;
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  const percentTimeAdj = useMemo(() => daysDifference / 365 * 0.06, [daysDifference]);
  const timeAdjValue = useMemo(() => compList.SalePrice[index] * -percentTimeAdj, [compList, comp, index, percentTimeAdj]);
  const color = ''; // Add logic for determining color if needed
  // const { setConfirmModal } = useModalContext(); // this context is updating, so all of the rows re-render.
  const [inventoryUpdates, setInventoryUpdates] = useState(null)
  const [confirmModal, setConfirmModal] = useState(false)

  // If inventoryUpdate is a LOCAL state purely, this should work I think? Instead of a shared context?
  return (
    <>
    {confirmModal ?
      <DBConfirmModal
      confirmModal={confirmModal}
      setConfirmModal={setConfirmModal}
      inventoryUpdates={inventoryUpdates}
      setInventoryUpdates={setInventoryUpdates}
      compList={compList}
      setLoading={setLoading}
      index={inventoryUpdates.Index}
      />:null}
        <tr
          key={index}
          style={{ backgroundColor: color }}
          className={`${
            caseMode === 'Muni'
              ? village !== 'All' && village !== undefined && village
                ? compList.adj_price[index] > compList.IFMV_Village[0]
                  ? 'bg-[#ceffd9]'
                  : 'bg-[#ffaeb0]'
                : compList.adj_price[index] > compList.IFMV[0]
                  ? 'bg-[#ceffd9]'
                  : 'bg-[#ffaeb0]'
              : village !== 'All' && village !== undefined && village
                ? compList.adj_price[index] < compList.IFMV_Village[0]
                  ? 'bg-[#ceffd9]'
                  : 'bg-[#ffaeb0]'
                : compList.adj_price[index] < compList.IFMV[0]
                  ? 'bg-[#ceffd9]'
                  : 'bg-[#ffaeb0]'
          } transition duration-300 ease-in-out hover:border-y-2 hover:border-primary hover:border-inset border-y dark:bg-boxdark dark:text-gray-400`}
        >
          <td
            className={`border-b border-gray-500 w-[50px] px-4 text-center py-2 ${
              editValues[compList['parcel_id'][index]] !== undefined &&
              editValues[compList['parcel_id'][index]] !== index.toString() &&
              editValues[compList['parcel_id'][index]] !== ''
                ? 'bg-yellow-300'
                : ''
            }`}
            onClick={() => {
              setEditIndex(index);
              setEditValues({
                ...editValues,
                [compList['parcel_id'][index]]: '',
              });
            }}
          >
            {editIndex === index ? (
              <input
                className="max-w-[20px] p-0 text-center bg-transparent border-none outline-none focus:outline-none"
                type="text"
                value={editValues[compList['parcel_id'][index]]}
                onChange={(e) =>
                  setEditValues({
                    ...editValues,
                    [compList['parcel_id'][index]]: e.target.value,
                  })
                }
                onBlur={() => setEditIndex(null)}
                autoFocus
              />
            ) : (
              editValues[compList['parcel_id'][index]] || index
            )}
          </td>
          <td>
            <>
              <div onClick={() => handleCopy(compList.Address[index], 'address')} className="cursor-pointer">
                {compList.Address[index]}
              </div>
              <div onClick={() => handleCopy(compList.parcel_id[index], 'PID')} className="cursor-pointer px-1 text-xs text-left dark:border-gray-400 whitespace-nowrap">
                {compList.parcel_id[index]}
              </div>
            </>
          </td>
          <td className="justify-center py-1 text-center">
            <div className="flex flex-col">
              <span className="text-sm">{compList.PCC[index]}</span>
              <span className="text-xs whitespace-nowrap">{compList.SDName[index]}</span>
            </div>
          </td>
          <td className="px-1 float dark:border-gray-400 text-center min-w-[85px] max-w-[140px] text-sm px-4">
            <div className="">
              <DropdownStyle
                initialSelection={compList?.Style[index]}
                options={getStyleOptions}
                onSelect={(selectedOption) =>
                  updateInventory(
                    selectedOption,
                    compList,
                    'Style',
                    selectedOption,
                    index,
                    compList,
                    inventoryUpdates,
                    setInventoryUpdates
                  )
                }
                updated={
                  !isUndefined(inventoryUpdates?.Style) &&
                  inventoryUpdates?.Index === index
                }
                inventoryUpdates={{
                  Style: inventoryUpdates?.Style,
                  Index: inventoryUpdates?.Index,
                }}
                setInventoryUpdates={setInventoryUpdates}
                index={index}
              />
              <div className="text-[0.7rem] pb-3 pt-0.5 text-left h-[10px]">
                <DropdownStyle
                  initialSelection={
                    compList?.Waterfront[index] === 1
                      ? 'Waterfront'
                      : compList?.WaterView[index] === 1
                      ? 'Waterview'
                      : 'None'
                  }
                  options={['Waterfront', 'WaterView', 'None']}
                  onSelect={(selectedOption) =>
                    updateInventory(
                      selectedOption,
                      compList,
                      selectedOption,
                      1,
                      index,
                      compList,
                      inventoryUpdates,
                      setInventoryUpdates
                    )
                  }
                  updated={(!isUndefined(inventoryUpdates?.Waterfront) || !isUndefined(inventoryUpdates?.WaterView)) && inventoryUpdates?.Index === index}
                  inventoryUpdates={inventoryUpdates}
                  setInventoryUpdates={setInventoryUpdates}
                  index={index}
                />
              </div>
            </div>
          </td>
          <td className="py-2 text-center">
            {typeof compList?.YearBuilt[index] === 'number'
              ? Number.isInteger(compList?.YearBuilt[index])
                ? compList?.YearBuilt[index]
                : <span className="text-danger">{Math.round(compList?.YearBuilt[index])}*</span>
              : 'N/A'}
          </td>

                      {/* Sqft input component. */}
        <td className="px-1 dark:border-gray-400 text-center min-w-[70px]">
          {compList?.Sqft && compList?.Sqft.length > 0 && (
            <InputSelect
              onChange={(selectedOption) => updateInventory(selectedOption, compList, 'Sqft', selectedOption, index, compList, inventoryUpdates, setInventoryUpdates)}
              style={Number.isInteger(compList?.Sqft[index]) ? undefined : 'imputed'}
              value={compList?.Sqft[index]}
              updated={!isUndefined(inventoryUpdates?.Sqft) && inventoryUpdates?.Index === index}
              keyName='Sqft'
            />
          )}
        </td>
          {/* <td className="text-center">
            {typeof compList?.Sqft[index] === 'number'
              ? Number.isInteger(compList.Sqft[index])
                ? compList.Sqft[index].toLocaleString()
                : <span className="text-danger">{Math.round(compList.Sqft[index]).toLocaleString()}*</span>
              : ''}
          </td> */}
          <td className="py-2 text-center">
            {/* {typeof compList?.Acres[index] === 'number' ? compList.Acres[index].toFixed(2) : ''} */}
            {typeof compList?.Acres[index] === 'number'
            ? (function() {
                const acres = compList.Acres[index];
                const decimalPoints = (acres.toString().split('.')[1] || '').length;
                
                if (decimalPoints > 3) {
                  return <span className="text-danger">{acres.toFixed(2).toLocaleString()}*</span>;
                } else {
                  return acres.toLocaleString();
                }
              })()
            : ''}

          </td>

          <td className="py-2 text-center border-gray-500">
          {compList?.Bath !== null && compList?.Bath[index] !== '' && (
            <InputSelect
              onChange={(selectedOption) => updateInventory(selectedOption, {'Bath':compList?.Bath[index],'HalfBath':compList?.HalfBath[index]}, 'Bath', selectedOption, index, compList, inventoryUpdates, setInventoryUpdates)}
              style={(((compList?.Bath[index]%1).toString()).length >= 5 || ((compList?.HalfBath[index]%1).toString()).length >= 5) ? 'imputed' : undefined}
              value={{'Bath':compList?.Bath[index],'HalfBath':(compList?.HalfBath[index])}}
              // updated={(inventoryUpdates.Bath) || (inventoryUpdates.HalfBath)?true:false}
              updated={(!isUndefined(inventoryUpdates?.HalfBath) || !isUndefined(inventoryUpdates?.Bath)) && inventoryUpdates?.Index === index}
              keyName='Bath'
            />
          )}
        </td>

          {/* <td className={`py-2 text-center ${((compList?.Bath[index] % 1).toString()).length >= 5 ? 'text-danger' : ''}`}>
            {compList?.Bath !== null && compList?.Bath[index] !== ''
              ? (compList.Bath[index] + (compList.HalfBath[index]) / 2) % 1 === 0
                ? (compList.Bath[index] + (compList.HalfBath[index]) / 2).toFixed(0)
                : ((compList?.Bath[index] % 1).toString()).length >= 5
                ? ((compList.Bath[index] + parseFloat(compList.HalfBath[index]) / 2).toFixed(0)) + '*'
                : ((compList.Bath[index] + parseFloat(compList.HalfBath[index]) / 2).toFixed(1))
              : ''}
          </td> */}

          {getNegotiationObj.village!=='All'? // added in village IFMV here
          <td className="text-center border-b border-gray-500">
          {(compList?.IFMV[index] !== undefined && compList?.IFMV[index] !== null) ? 
            compList?.IFMV[index].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
            "err"
          }
          <div className='text-xs'>
              {(compList?.PropertyAssessment[index] ?? "err").toLocaleString()} AV
            </div>
          </td>
          :
          <td className="text-center border-b border-gray-500">
            {(compList?.IFMV[index] !== undefined && compList?.IFMV[index] !== null) ? 
              compList?.IFMV[index].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
              "err"
            }
            <div className='text-xs'>
              {(compList?.PropertyAssessment[index] ?? "err").toLocaleString()} AV
            </div>
          </td>
          }

          <td className="text-center border-b border-gray-500">
          {
            typeof compList?.SalePrice[index] === 'number' ? 
              compList.SalePrice[index].toLocaleString() : 
              "err"
          }
            <div className='text-xs'>
              {compList?.SaleDate[index] !== undefined ? 
                new Date(compList?.SaleDate[index]).toLocaleDateString('en-US') :
                "err"
              }
            </div>
          </td>
              {/* time adjustment here */}
            

          {compList.SaleDate === 'NaT' || compList.SaleDate === '' ? (
            <>
              <td className="py-2 text-center border-gray-500 order-b">N/A</td>
              <td className="py-2 text-center border-gray-500 order-b">N/A</td>
              <td className="py-2 text-center border-gray-500 order-b">N/A</td>
            </>
          ) : (
            <>
                {(compList?.adj_price?.[index] !== undefined) ?
              <td className="py-2 font-bold text-center border-b border-gray-500">
                  {(Math.round(compList.adj_price[index])).toLocaleString()}
                  <div className='text-xs font-normal'>{compList?.MLSNumber[index] === 'None' ? 'None' : `MLS#${compList?.MLSNumber[index]}`}</div>
              </td>
                   :
                   <td className="py-2 font-bold text-center border-b border-gray-500">
                  'N/A'
                  </td>
                }
              {/* Time adjustment */}
              <td className='max-w-[45px] text-center border-t'>
                {compList?.adj_price?.[index] !== undefined &&
                  <>
                    {/* <span className=''>{(Math.round(compList.adj_price[index] + timeAdjValue)).toLocaleString()}</span>
                    <div className={`text-xs ${percentTimeAdj < 0 ? 'text-danger' : 'text-success'}`}>
                      {percentTimeAdj < 0 ? `+${Math.round(timeAdjValue).toLocaleString()}` : `${Math.round(timeAdjValue).toLocaleString()}`}
                    </div> */}
                  </>
                }
              </td>
            </>
          )}
          {/* Distance display */}

          {compList?.Distance && compList.Distance[index] !== undefined && compList.Distance[index] !== '' && compList.Distance[index] !== null ?
            inventoryUpdates && Object.keys(inventoryUpdates).some(key => key !== 'Index') && inventoryUpdates.Index ===index ?
            <td onClick={()=>setConfirmModal(true)} className='cursor-pointer px-1 dark:border-gray-400 text-center'>
              <button className='rounded-sm border items-center hover:bg-meta-5 border-stroke bg-primary text-white p-1 max-w-[200px] dark:text-white m-auto shadow-default dark:border-strokedark'>
              UPDATE
              </button>
            </td>
            :

          <td className='text-center'>
          {compList.Distance[index].toFixed(2) + ' Mi'}
          </td> :
          <td className='text-center'>
          <span className='text-danger'>Err</span>
          {/* Add in an "i" icon that has an explanation show up on mouseover - "no Lat / Long" */}
          </td>
          }
        </tr>
      </>

      );
    }
  )
  AdditionalCompsRow.whyDidYouRender = true;
    export default AdditionalCompsRow;
    