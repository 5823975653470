import {usePersistedDataStore} from './store';
import { useEffect, useState} from "react";
import DropdownStyle from './DropdownStyle';
import InputSelect from './InputSelect';
import { isUndefined } from 'lodash';
import axiosInstance from '../axiosConfig.js'
import { toast } from 'react-toastify';
import useUpdateInventory from '../common/updateInventory.js'
import { useModalContext } from '../common/ModalContext';
import DBConfirmModal from '../common/DBConfirmModal.js';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';

// import {
//   Popover,
//   PopoverHandler,
//   PopoverContent,
//   Button,
//   Chip,
//   Typography,
// } from "@material-tailwind/react";

function SubjectTableRow({compList, setLoading}){ // why do you have this instead of within the other table in additional comps? for verrtical grid view?
    const getOptimizedComps = usePersistedDataStore((state)=>state.optimizedComps)
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const getStyleOptions = usePersistedDataStore((state) => state.styleOptions)
    const getCompSheet = usePersistedDataStore((state) => state.compSheet)
    const [inventoryUpdates, setInventoryUpdates] = useState(null); // update this so that it isn't a local state
    // const { confirmModal, setConfirmModal } = useModalContext();
    const [confirmModal, setConfirmModal] = useState(false)
    // const setOptimizedComps = usePersistedDataStore((state)=> state.setOptimizedComps)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
    const village = queryParams.get('village') || null ; // Subtract 1
    const [subjTimeAdj, setSubjTimeAdj] = useState('')
    const [openPopover, setOpenPopover] = useState(false)
    const updateInventory = useUpdateInventory();
    
    useEffect(() => { // set the subj timme adj
        if (getCompSheet.length > 0 || getOptimizedComps.length > 0) {
            const valuationDateObj = new Date('2022-07-01'); // update with the dynamic val date (from home.js)
            if (compList.SaleDate[0]) {
              const inputDateObj = new Date(compList.SaleDate[0]);
              const timeDifference = inputDateObj - valuationDateObj;
              const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
              // negative days diff means sale occured in the past.
              const percentTimeAdj = daysDifference / 365 * 0.07;
              const timeAdjValue = compList.SalePrice[0] * -percentTimeAdj;
              setSubjTimeAdj(timeAdjValue);
            }
        }
      }, [comp]);

      


      const handleCopy = (text, type) => {
        navigator.clipboard.writeText(text).then(() => {
          toast.success(`Copied ${type} to clipboard!`, {
            position: 'top-left',
            className: 'mt-10',
            autoClose: 3000,
        });
            // alert(`Copied ${type} to clipboard!`);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

      const triggers = {
        onMouseEnter: () => setOpenPopover(true),
        onMouseLeave: () => setOpenPopover(false),
      };

    return(
      <tr className={`w-full bg-meta-2 dark:bg-boxdark border-sticky dark:text-white z-[25] ${
        !compList?.RepID || compList?.RepID[0] === '' || compList?.RepID.length===0 ? '' : 'bg-warning'
      }`}>
    {/* Render the DBConfirmModal conditionally here */}
    {confirmModal && inventoryUpdates && (
      <DBConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        inventoryUpdates={inventoryUpdates}
        setInventoryUpdates={setInventoryUpdates}
        compList={compList}
        setLoading={setLoading}
        index={inventoryUpdates.Index}
      />
    )}
        
        {/* <tr className={` ${compList.SalePrice[0] ? 'bg-yellow-300' : 'yourFalseClass'}`}> */}
        <td className='dark:border-gray-400 text-center  w-[40px]'></td>
        <td>
        <div className="px-1 flex items-center content-center dark:border-gray-400 min-w-[125px] text-left">
        <span className='cursor-pointer'
        onClick={() => handleCopy(compList.Address[0], 'address')} 
        >
          {compList.Address[0]}
        </span>

          {/* Client info hover */}
          {/* <div className='ml-2 items-center'>
            <Popover open={openPopover} className='ml-2' handler={setOpenPopover}>
                              <PopoverHandler {...triggers}>
                              <div className="flex items-center justify-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-info hover:pointer"
                                  >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="12" y1="16" x2="12" y2="12"></line>
                                  <line x1="12" y1="8" x2="12" y2="8"></line>
                                </svg>
                              </div>
                              </PopoverHandler>
                              <PopoverContent {...triggers} className="z-50 max-w-[16rem]" >
                                  <Typography
                                    as="span"
                                    variant="h6"
                                    color="blue-gray"
                                    className="font-bold transition-colors hover:text-gray-900 mb-1 flex items-center text-center"
                                  >
                                    Client info
                                  </Typography>
                                <div className="text-left">Name: {getNegotiationObj.cases[comp]?.Name}</div>
                                <div className="text-left">SCAR Index#: {getNegotiationObj.cases[comp]?.SCARIndexNumber}</div>
                                <div className="text-left">
                                    Estimated Taxes: {getNegotiationObj.cases[comp]?.EstimatedTaxes ? 
                                    getNegotiationObj.cases[comp].EstimatedTaxes.toLocaleString() : 'N/A'}
                                </div>
                              </PopoverContent>
            </Popover>
          </div> */}

        </div>
        <div className="px-1 text-xs text-left dark:border-gray-400 whitespace-nowrap">
        <span className='cursor-pointer'
        onClick={() => handleCopy(compList.parcel_id[0], 'PID')} 
        >
          {compList.parcel_id[0]}
        </span>
          </div>
        </td>
        <td className="dark:border-gray-400 py-2 justify-center text-center ">
          <div className="flex flex-col w-auto">
            <span className="text-sm">{compList.PCC[0]}</span>
            <span className="text-xs whitespace-nowrap">{compList.SDName[0]}</span>
          </div>
        </td>

        {/* Editable style */}
        <td className="px-1 float dark:border-gray-400 text-center min-w-[85px] max-w-[140px] text-sm px-4 ">
          <div className=''>
          <DropdownStyle
            initialSelection={compList?.Style[0]}
            options={getStyleOptions}
            onSelect={(selectedOption) => updateInventory(selectedOption, compList, 'Style', selectedOption, 0, compList, inventoryUpdates, setInventoryUpdates)}
            updated={!isUndefined(inventoryUpdates?.Style) && inventoryUpdates.Index === 0}
            inventoryUpdates={inventoryUpdates}
            setInventoryUpdates={setInventoryUpdates}
            index={0}
          />

          <div   className={`text-[0.7rem] pb-3 pt-0.5 text-left h-[10px]`}>
          <DropdownStyle
            initialSelection={compList?.Waterfront[0] === 1 ? (
              "Waterfront"
            ) : compList?.WaterView[0] === 1 ? (
              "Waterview"
            ) : 'None'}
            options={['Waterfront', 'WaterView', 'None']}
            onSelect={(selectedOption) => updateInventory(selectedOption, compList, selectedOption, 1, 0, compList, inventoryUpdates, setInventoryUpdates)}
            updated={(!isUndefined(inventoryUpdates?.Waterfront) || !isUndefined(inventoryUpdates?.WaterView)) && inventoryUpdates.Index === 0}
            inventoryUpdates={inventoryUpdates}
            setInventoryUpdates={setInventoryUpdates}
            index={0}
          />
            {/* {compList?.Waterfront[0] === 1 && "Waterfront"}
            {compList?.WaterView[0] === 1 && "Waterview"} */}
          </div>
          </div>
        </td>

      {/* Yearbuilt input component. */}
        <td className="py-2 text-center border-gray-500  max-w-[70px]">
          {compList?.YearBuilt && compList?.YearBuilt.length > 0 && (
            <InputSelect
              value={typeof compList?.YearBuilt[0] === 'number' ? compList?.YearBuilt[0] : '--'}
              onChange={(selectedOption) => updateInventory(selectedOption, compList, 'YearBuilt', selectedOption, 0, compList, inventoryUpdates, setInventoryUpdates)}
              style={Number.isInteger(compList?.YearBuilt[0]) ? undefined : 'imputed'}
              updated={!isUndefined(inventoryUpdates?.YearBuilt) && inventoryUpdates?.Index === 0}
              keyName='YearBuilt'
            />
          )}
        </td>

            {/* Sqft input component. */}
        <td className="px-1 dark:border-gray-400 text-center min-w-[70px]">
          {compList?.Sqft && compList?.Sqft.length > 0 && (
            <InputSelect
              onChange={(selectedOption) => updateInventory(selectedOption, compList, 'Sqft', selectedOption, 0, compList, inventoryUpdates, setInventoryUpdates)}
              style={Number.isInteger(compList?.Sqft[0]) ? undefined : 'imputed'}
              value={compList?.Sqft[0]}
              updated={!isUndefined(inventoryUpdates?.Sqft) && inventoryUpdates?.Index === 0}
              keyName='Sqft'
            />
          )}
        </td>
        
        {/* We shouldn't have to update acres */}
        <td className="px-1 dark:border-gray-400 text-center min-w-[65px] ">
          {(compList?.Acres?.[0]?.toFixed(2) ?? "N/A")}
        </td>


            {/* need to update ability to update baths + half baths. */}
        <td className="py-2 text-center border-gray-500">
          {compList?.Bath !== null && (
          <div>
            <InputSelect
              onChange={(selectedOption) => updateInventory(selectedOption, {'Bath':compList?.Bath[0],'HalfBath':compList?.HalfBath[0]}, 'Bath', selectedOption, 0, compList, inventoryUpdates, setInventoryUpdates)}
              style={(((compList?.Bath[0] % 1).toString()).length >= 5 || ((compList?.HalfBath[0] % 1).toString()).length >= 5 || compList?.Bath[0]==='') ? 'imputed' : undefined}
              value={{'Bath': compList?.Bath[0] || 0, 'HalfBath': compList?.HalfBath[0] || 0}}
              updated={(!isUndefined(inventoryUpdates?.HalfBath) || !isUndefined(inventoryUpdates?.Bath)) && inventoryUpdates?.Index === 0}
              keyName='Bath'
            />
            
          </div>

          )}
        </td>
        {/* <td className="px-1 dark:border-gray-400 py-2 text-center min-w-[50px]">{(compList.Bath[0] + parseFloat(compList.HalfBath[0]) / 2) % 1 === 0 ? (compList.Bath[0] + parseFloat(compList.HalfBath[0]) / 2): (compList.Bath[0] + parseFloat(compList.HalfBath[0]) / 2).toFixed(1)}</td> */}
        
        {village !== 'All' && village !== undefined && village?
        <td className="px-1 dark:border-gray-400 text-center min-w-[90px] ">{compList?.IFMV_Village[0].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        <div className='text-xs'>{(compList.PropertyAssessment[0]).toLocaleString()+" AV"}</div>
        </td>
        :
        <td className="px-1 dark:border-gray-400 text-center min-w-[90px] ">{compList?.IFMV[0].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        <div className='text-xs'>
        {compList?.PropertyAssessment && compList?.PropertyAssessment.length > 0 && (
            <InputSelect
              value={typeof compList?.PropertyAssessment[0] === 'number' ? compList?.PropertyAssessment[0].toLocaleString()+" AV" : '--'}
              onChange={(selectedOption) => updateInventory(selectedOption, compList, 'PropertyAssessment', selectedOption, 0, compList, inventoryUpdates, setInventoryUpdates)}
              style={Number.isInteger(compList?.PropertyAssessment[0]) ? undefined : 'imputed'}
              updated={(inventoryUpdates?.PropertyAssessment)?true:false}
              keyName='PropertyAssessment'
              textSize='small'
            />
            )}
            
        </div>


        </td>
        }
        
        {['NaT', '', null, undefined].includes(compList?.SaleDate[0])? 
        // if getvvillage !== All, then do .IFMV not IFMV_village
        (
          <>
            <td className="px-1 dark:border-gray-400 text-center  min-w-[90px] ">--</td>
            <td className="px-1 dark:border-gray-400 text-center min-w-[90px] ">--</td>
            <td className="px-1 dark:border-gray-400 text-center min-w-[90px] ">--</td>
          </>
        ) : (
          <>
            <td className='bg-[#fef9c3] mt-2'>
            <div className="text-center min-w-[90px]">{compList?.SalePrice[0].toLocaleString()}</div>
            <div className="text-center text-xs min-w-[90px] ">{new Date(compList?.SaleDate[0]).toLocaleDateString('en-US')}</div>
            </td>
            
            {/* Time adjustment */}
            <td className='max-w-[45px] text-center bg-[#fef9c3]'>
              <span className=''>N/A</span>
                  <div className='text-xs font-normal'>{compList?.MLSNumber[0] === 'None' ? 'None' : `MLS#${compList?.MLSNumber[0]}`}</div>
            </td>
              <td className='text-center'>{Math.round(compList?.SalePrice[0]+subjTimeAdj).toLocaleString()}
              <div className={`text-xs ${subjTimeAdj > 0 ? 'text-danger' : 'text-success'}`}>{subjTimeAdj > 0 ?`+`+Math.round(subjTimeAdj).toLocaleString():Math.round(subjTimeAdj).toLocaleString()}</div>
            </td>
          </>
        )}

      {inventoryUpdates && Object.keys(inventoryUpdates).some(key => key !== 'Index') && inventoryUpdates.Index === 0 ? 
        <td onClick={()=>setConfirmModal(true)} className='cursor-pointer px-1 dark:border-gray-400 text-center'>
          <button className='rounded-sm border items-center hover:bg-meta-5 border-stroke bg-primary text-white p-1 max-w-[200px] dark:text-white m-auto shadow-default dark:border-strokedark'>
          UPDATE
          </button>
        </td>
        :
        <td className="px-1 dark:border-gray-400 text-center min-w-[90px]">N/A</td>
        }
        </tr>
    )
}export default SubjectTableRow