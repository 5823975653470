import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import _ from 'lodash';
import { useMemo } from 'react';

// Zustand global state management store.

// For specific / niche things, we should remove them from the global store and put them into specific context.
function getDefaultYear() {
  const today = new Date();
  return today.getMonth() >= 3
    ? today.getFullYear()
    : today.getFullYear() - 1;
}

export const useCompStore = create(
  (set, get) => ({
    subjLat: '', // Initial value for subjLat
    subjLong: '', // Initial value for subjLong
    AvgPrice: 0,
    setAvgPrice: (value) => set((state) => {
      if (!_.isEqual(state.AvgPrice, value)) {
        return { AvgPrice: value };
      }
      return state;
    }),
    setAllData: (value) => set((state) => {
      if (!_.isEqual(state.allData, value)) {
        return { allData: value };
      }
      return state;
    }),
    setSubjLat: (value) => set((state) => {
      if (!_.isEqual(state.subjLat, value)) {
        return { subjLat: value };
      }
      return state;
    }), // Setter for subjLat
    setSubjLong: (value) => set((state) => {
      if (!_.isEqual(state.subjLong, value)) {
        return { subjLong: value };
      }
      return state;
    }), // Setter for subjLong
    adjustments: [],
    setAdjustments: (value) => set((state) => {
      if (!_.isEqual(state.adjustments, value)) {
        return { adjustments: value };
      }
      return state;
    }),
    sortOrder: 'asc',
    setSortOrder: (value) => set((state) => {
      if (!_.isEqual(state.sortOrder, value)) {
        return { sortOrder: value };
      }
      return state;
    }),
    originalOptimComps: [],
    setOriginalOptimComps: (value) => set((state) => {
      if (!_.isEqual(state.originalOptimComps, value)) {
        return { originalOptimComps: value };
      }
      return state;
    }),
    originalCompList: [],
    setOriginalCompList: (value) => set((state) => {
      if (!_.isEqual(state.originalCompList, value)) {
        return { originalCompList: value };
      }
      return state;
    })
  }),
  {
    name: 'Compsheet', // Name for the persisted state
  }
);

export const usePersistedDataStore = create(
  (set, get) => ({
    // const defaultYear = useMemo(() => {
    //   const today = new Date();
    //   return today.getMonth() >= 3
    //     ? today.getFullYear()
    //     : today.getFullYear() - 1;
    // }, []);
    
    // return{
    negotiationObj: { cases: [], county: 'All', countyString: '', municipality: 'All', village: 'All', TaxYear: getDefaultYear(), courtDate: 'All', MuniCode: null, courtDateSelectedMuni: null },
    setNegotiationObj: (value) => set((state) => {
      if (!_.isEqual(state.negotiationObj, value)) {
        return { negotiationObj: value };
      }
      return state;
    }),
    caseNotes: [],
    setCaseNotes: (value) => set((state) => {
      if (!_.isEqual(state.caseNotes, value)) {
        return { caseNotes: value };
      }
      return state;
    }),
    compSheet: '', // Initial value for compSheet
    setCompSheet: (newSheet) => set(() => {
      // Use deep comparison to avoid unnecessary updates
        return { compSheet: newSheet };
    }),
    optimizedComps: [], // Initial value for compSheet
    setOptimizedComps: (value) => set(() => {
        return { optimizedComps: value };
      }), // Setter for compSheet
    courtComps: [],
    setCourtComps: (value) => set(() => {
        return { courtComps: value };
    }),
    editedValues: {},
    setEditedValues: (value) => set(()=>{
      return { editedValues: value };
    }),
    optimizedCompsB: [],
    setOptimizedCompsB: (value) => set(() => {
        return { optimizedCompsB: value };
    }),
    compSheetB: [],
    setCompSheetB: (value) => set(() => {
        return { compSheetB: value };
    }),
    upcomingCourtDates: [],
    setUpcomingCourtDates: (value) => set((state) => {
      if (!_.isEqual(state.upcomingCourtDates, value)) {
        return { upcomingCourtDates: value };
      }
      return state;
    }),
    fetchLoad: false,
    setFetchLoad: (value) => set((state) => {
      if (!_.isEqual(state.fetchLoad, value)) {
        return { fetchLoad: value };
      }
      return state;
    }),      
    caseNumber: 0,
    setCaseNumber: (caseNum) => set((state) => {
      if (!_.isEqual(state.caseNumber, caseNum)) {
        return { caseNumber: caseNum };
      }
      return state;
    }),
    evalDate: '',
    setEvalDate: (evalDate) => set((state) => {
      if (!_.isEqual(state.evalDate, evalDate)) {
        return { evalDate: evalDate };
      }
      return state;
    }),
    courtMunis: [],
    setCourtMunis: (courtMunis) => set((state) => {
      if (!_.isEqual(state.courtMunis, courtMunis)) {
        return { courtMunis: courtMunis };
      }
      return state;
    }),
    yearDropdownOptions: [],
    setYearDropdownOptions: (value) => set((state) => {
      if (!_.isEqual(state.yearDropdownOptions, value)) {
        return { yearDropdownOptions: value };
      }
      return state;
    }),
    styleOptions: ['Colonial', 'Ranch', 'Contemporary','Cape Cod', 'Old Style', 'Tudor', 'Raised Ranch', 'Split Level', 'Town House', 'Unknown', 'Other', 'Co-Op', 'HOA', 'Condo', 'Garden', 'Carriage Hse', 'Salt Box', 'Farm House', 'Antique/Historic']
}),
  {
    name: 'Persisted', // Name for the persisted state
  }
);

export const useLocalDataStore = create(
  (set) => ({
    sortedFactors: [],
    setSortedFactors: (factor) => set((state) => {
      if (!_.isEqual(state.sortedFactors, factor)) {
        return { sortedFactors: factor };
      }
      return state;
    }),
    optimizationWeights: {},
    setOptimizationWeights: (weightObj) => set((state) => {
      if (!_.isEqual(state.optimizationWeights, weightObj)) {
        return { optimizationWeights: { ...weightObj } };
      }
      return state;
    }),
    reviewPage: false,
    setReviewPage: (value) => set((state) => {
      if (!_.isEqual(state.reviewPage, value)) {
        return { reviewPage: value };
      }
      return state;
    }),
    view: 'regular',
    setView: (value) => set((state) => {
      if (!_.isEqual(state.view, value)) {
        return { view: value };
      }
      return state;
    }),
    isCountyOpen: false,
    setIsCountyOpen: (value) => set((state) => {
      if (!_.isEqual(state.isCountyOpen, value)) {
        return { isCountyOpen: value };
      }
      return state;
    }),
    isMuniOpen: false,
    setIsMuniOpen: (value) => set((state) => {
      if (!_.isEqual(state.isMuniOpen, value)) {
        return { isMuniOpen: value };
      }
      return state;
    }),
    isTaxYearOpen: false,
    setIsTaxYearOpen: (value) => set((state) => {
      if (!_.isEqual(state.isTaxYearOpen, value)) {
        return { isTaxYearOpen: value };
      }
      return state;
    }),
    isVillageOpen: false,
    setIsVillageOpen: (value) => set((state) => {
      if (!_.isEqual(state.isVillageOpen, value)) {
        return { isVillageOpen: value };
      }
      return state;
    }),
    isCourtDateOpen: false,
    setIsCourtDateOpen: (value) => set((state) => {
      if (!_.isEqual(state.isCourtDateOpen, value)) {
        return { isCourtDateOpen: value };
      }
      return state;
    }),
    isSettlementRunOpen: false,
    setisSettlementRunOpen: (value) => set((state) => {
      if (!_.isEqual(state.isSettlementRunOpen, value)) {
        return { isSettlementRunOpen: value };
      }
      return state;
    }),
    sidebarOpen: false,
    setSidebarOpen: (open) => set((state) => {
      if (!_.isEqual(state.sidebarOpen, open)) {
        return { sidebarOpen: open };
      }
      return state;
    }),
    rfResults: {},
    setRfResults: (result) => set((state) => {
      if (!_.isEqual(state.rfResults, result)) {
        return { rfResults: result };
      }
      return state;
    })
  }),
  {
    name: 'localDataStore', // Optional name for the persisted store
    version: 1, // Optional version number for the persisted data
  }
);

export const useAdminSettingsStore = create(
  (set) => ({
    selectedFilter: 'Sales Only',
    setSelectedFilter: (filter) => set((state) => {
      if (!_.isEqual(state.selectedFilter, filter)) {
        return { selectedFilter: filter };
      }
      return state;
    }),
    salesCompReport: false,
    setSalesCompReport: (filter) => set((state) => {
      if (!_.isEqual(state.salesCompReport, filter)) {
        return { salesCompReport: filter };
      }
      return state;
    }),
    state: 'NY',
    setState: (state) => set((state) => {
      if (!_.isEqual(state.state, state)) {
        return { state: state };
      }
      return state;
    }),
    map: true,
    setMap: (map) => set((state) => {
      if (!_.isEqual(state.map, map)) {
        return { map: map };
      }
      return state;
    }),
    user: null,
    setUser: (user) => set((state) => {
      if (!_.isEqual(state.user, user)) {
        return { user: user };
      }
      return state;
    }),
    errorState: '',
    setErrorState: (errorState) => set((state) => {
      if (!_.isEqual(state.errorState, errorState)) {
        return { errorState: errorState };
      }
      return state;
    }),
    popup: false,
    setPopup: (popup) => set((state) => {
      if (!_.isEqual(state.popup, popup)) {
        return { popup: popup };
      }
      return state;
    }),
    defaultComps: 5,
    setDefaultComps: (defaultComps) => set((state) => {
      if (!_.isEqual(state.defaultComps, defaultComps)) {
        return { defaultComps: defaultComps };
      }
      return state;
    }),
    showSettledCases: false,
    setShowSettledCases: (showSettledCases) => set((state) => {
      if (!_.isEqual(state.showSettledCases, showSettledCases)) {
        return { showSettledCases: showSettledCases };
      }
      return state;
    }),
    defaultNumCases: null,
    setDefaultNumCases: (defaultNumCases) => set((state) => {
      if (!_.isEqual(state.defaultNumCases, defaultNumCases)) {
        return { defaultNumCases: defaultNumCases };
      }
      return state;
    }),
    manualReview: true,
    setManualReview: (manualReview) => set((state) => {
      if (!_.isEqual(state.manualReview, manualReview)) {
        return { manualReview: manualReview };
      }
      return state;
    })
  }),
  {
    name: 'adminSettingsStore', // Optional name for the persisted store
    version: 1, // Optional version number for the persisted data
  }
);
